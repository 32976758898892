import axios from "axios";

export default {
    
    getByUser(id) {
        const url = `${process.env.VUE_APP_API_URL}/gealllistasusuario`;
        let data = {'id': id}
        return axios.post(url, data)
    },

    NuevaPlayList(nombre, id) {
        const url = `${process.env.VUE_APP_API_URL}/addnuevalista`;
        let data = {'nombre': nombre, 'id': id}
        return axios.post(url, data)
    },

    guardarCancion(idCancion, idLista) {
        const url = `${process.env.VUE_APP_API_URL}/guardarnuevacancionlista`;
        let data = {'idCancion': idCancion, 'idLista': idLista}
        return axios.post(url, data)
    },

    eliminarCancion(idCancion, idLista) {
        const url = `${process.env.VUE_APP_API_URL}/eliminarcancionlista`;
        let data = {'idCancion': idCancion, 'idLista': idLista}
        return axios.post(url, data)
    },


}
