<template>
  <div>
    <div class=" text-white mb-3">
      <p>MÚSICA</p>
    </div>
    <div class="grid ml-2 mr-2">
      <div class="col-4 md:col-2 lg:col-1" @click="goTo('djs')" style="padding: 0.25 rem !important;">
        <img src="@/assets/rojo/bt_djs.jpg" class="w-full shadow-2" alt="Djs">
        <p class="text-white text-xs">Los mejores Djs</p>
      </div>
      <div class="col-4 md:col-2 lg:col-1" @click="goTo('select-genero')" style="padding: 0.25 rem !important;">
        <img src="@/assets/rojo/bt_generos.jpg" class="w-full shadow-2" alt="Generos Musicales">
        <p class="text-white text-xs">Canciones mixeadas en los géneros de tu gusto</p>
      </div>
      <div class="col-4 md:col-2 lg:col-1" @click="goToMoment('redirect-select-moment')" style="padding: 0.25 rem !important;">
        <img src="@/assets/rojo/bt_momentos.jpg" class="w-full shadow-2" alt="Momentos Musicales">
        <p class="text-white text-xs">Playlist hechas para momentos específicos del día</p>
      </div>
      <div class="col-4 md:col-2 lg:col-1" @click="goTo('all-time-artist')" style="padding: 0.25 rem !important;">
        <img src="@/assets/rojo/bt_artistas.jpg" class="w-full shadow-2" alt="Artistas de todos los tiempos">
        <p class="text-white text-xs">Artistas de todos los tiempos</p>
      </div>
      <div class="col-4 md:col-2 lg:col-1" @click="goTo('sesiones')" style="padding: 0.25 rem !important;">
        <img src="@/assets/rojo/bt_sesiones.jpg" class="w-full shadow-2" alt="Sesiones en Vivo">
        <p class="text-white text-xs">Mira acá los registros en vivo de tus DJs favoritos</p>
      </div>
      <div class="col-4 md:col-2 lg:col-1" @click="goTo('podcast')" style="padding: 0.25 rem !important;">
        <img src="@/assets/rojo/bt_podast.jpg" class="w-full shadow-2" alt="Podcasts">
        <p class="text-white text-xs">Exclusivos de radio San Nicolás y Aconcagua</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MusicNavigation',
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    goToMoment(route){
        this.$router.push({name: route, params: {moment: true, daytimeid: -1, daytime_nombre: null}});
    },
  }
}
</script>

<style scoped>
.shadow-2 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.text-sm {
  font-size: 0.875rem; /* Adjust size as necessary */
}

.mini-padding-margin {
  padding: 5px; /* Reduces padding, adjust as needed */
  width: 100%; /* Ensures the div takes full width of its container */
}

.text-xs {
  font-size: 0.75rem; /* Example size, adjust as needed */
  text-align: center; /* Centers text horizontally */
  width: 100%; /* Ensures text stretches from edge to edge */
  margin: 0 auto; /* Removes any default margin and centers text */
  display: block; /* Makes the paragraph a block element to fill width */
}

</style>
