import axios from 'axios';
import QueryString from 'querystring';

let AuthService = {};

(function () {
    const endpoint = 'auth';

  this.log_in = function (username, password) {
  const url = `${process.env.VUE_APP_URL}/oauth2/token/`;
  const data = new FormData();
  data.append('client_id', `${process.env.VUE_APP_CLIENT_ID}`);
  data.append('client_secret', `${process.env.VUE_APP_CLIENT_SECRET}`);
  data.append('username', username);
  data.append('password', password);
  data.append('grant_type', 'password');

  return axios.post(url, data)
    .then(response => {
      return response;
    });
};

  this.log_in_en_pelota = function (username, password, token) {
  const url = `${process.env.VUE_APP_URL}/oauth2/token_mail/`;
  const data = new FormData();
  data.append('client_id', `${process.env.VUE_APP_CLIENT_ID}`);
  data.append('client_secret', `${process.env.VUE_APP_CLIENT_SECRET}`);
  data.append('username', username);
  data.append('password', password);
   data.append('token', token);
  data.append('grant_type', 'password');

  return axios.post(url, data)
    .then(response => {
      return response;
    });
};


this.sendTokenMail = function (username) {
  const url = `${process.env.VUE_APP_URL}/oauth2/token-send-mail/`;
  const data = new FormData();
  data.append('client_id', `${process.env.VUE_APP_CLIENT_ID}`);
  data.append('client_secret', `${process.env.VUE_APP_CLIENT_SECRET}`);
  data.append('username', username);

  return axios.post(url, data)
    .then(response => {
      return response;
    });
};


    this.refresh_access = function (token) {
        const url = `${process.env.VUE_APP_URL}/oauth2/token/`;
        const data = new FormData();
        data.append('client_id', `${process.env.VUE_APP_CLIENT_ID}`);
        data.append('client_secret', `${process.env.VUE_APP_CLIENT_SECRET}`);
        data.append('refresh_token', token);
        data.append('grant_type', 'refresh_token');
        return axios.post(url, data)
    };

    this.recoveryPass = function (email) {
        const url = `${process.env.VUE_APP_API_URL}/userrecoverypass`;
        let data = {"email":email}
        return axios.post(url, data)
    };

    this.registerUser = function (username, name, email, password) {
        const url = `${process.env.VUE_APP_API_URL}/usercreate`;
        let data = {"username": username, "name": name, "email": email, "password": password};
        return axios.post(url, data)
    };

    this.getUser = function (username) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/getuser`;
        let data = {"username": username};
        return axios.post(url, data)
    };

    this.getUserRadio = function (username) {
        const url = `${process.env.VUE_APP_API_URL}/getuserradio`;
        let data = {"username": username};
        return axios.post(url, data)
    };

    this.editUserRadio = function (username, fecha_nacimiento, estacion_favorita, genero_persona) {
        const url = `${process.env.VUE_APP_API_URL}/edituserradio`;
        let data = {"username": username, 'genero_persona': genero_persona,'fecha_nacimiento':fecha_nacimiento,'estacion_favorita':estacion_favorita};
        return axios.post(url, data)
    };
    

    this.getGenerosPersona = function () {
        const url = `${process.env.VUE_APP_API_URL}/getgenerospersona`;
        let data = {"Id": null};
        return axios.post(url, data)
    };

    this.cangePass = function (username, password) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/changepass`;
        let data = {"username": username, "password": password};
        return axios.post(url, data)
    }

    this.deleteuser = function (username) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/deleteuser`;
        let data = {"username": username};
        return axios.post(url, data)
    }

   this.getUserDetail = function (username, password) {
        return new Promise((resolve, reject) => {
            const LOGIN_URL = `${process.env.VUE_APP_API_CORE_URL}/${endpoint}`;
            const params = QueryString.stringify({
                username: username,
                password: password,
            });

            axios.post(LOGIN_URL, params).then(response => {
                resolve(response.data);
            }).catch((error) => {
                reject(error)
            });
        });
    };

    this.logOut = function () {
        return new Promise((resolve, reject) => {
            const LOGIN_URL = `${process.env.VUE_APP_API_CORE_URL}/${endpoint}`;
            axios.get(LOGIN_URL).then(response => {
                resolve(response.data);
            }).catch((error) => {
                reject(error)
            });
        });
    };
}).apply(AuthService);

export default AuthService;
