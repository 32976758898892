<template>
  <div class="mt-0">
  
<span class="ml-1"><svg v-if="!isDeleting"  @click="abrirModal" width="15" height="15" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.1143 0H0V31H30.1143V0ZM24.8 13.2857H6.20002V16.8286H24.8V13.2857Z" fill="#EBEBEB"/>
</svg></span>

    


   <ProgressSpinner 
    v-if="isDeleting" 
    :style="{ width: isSmall ? '10px' : '27px', height: isSmall ? '10px' : '27px', marginBottom: '20px !important' }" 
    strokeWidth="8" 
    fill="var(--surface-ground)" 
    animationDuration=".5s" 
    aria-label="Custom ProgressSpinner" 
  />

    <!-- Modal -->
    <DialogPrime :visible="mostrarModal" :modal="true" :closable="false" header="Eliminar PlayList" >
        <h2>¿Esta Seguro?</h2>
        <!-- Aquí se listan las listas del usuario -->
        <!--p class="text-xl font-bold text-left">Se Eliminar la cancion de la lista.</p-->

        <div class="confirmation-buttons">
          <ButtonPrime @click="eliminarDeLista">Confirmar</ButtonPrime>
          <ButtonPrime class="ml-2" @click="cancelarEliminacion">Cancelar</ButtonPrime>
        </div>
      </DialogPrime>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlaylistService from '@/service/PlaylistService';

export default {
  props: {
    idCancion: {
      type: Number,
      required: true
    },
    idLista: {
      type: Number,
      required: true
    },
    isSmall: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      mostrarModal: false,
      isDeleting: false,
    };
  },
  computed: {
    ...mapGetters({
      user_id: 'auth/user_id',
    })
  },
  methods: {
    cancelarEliminacion(){
      this.mostrarModal = false;
    },
    abrirModal() {
      this.mostrarModal = true;
      //this.getListas();
    },
    eliminarDeLista(lista) {
      this.mostrarModal = false;
      this.isDeleting = true;
      this.$store.dispatch('form/submit', true);
      PlaylistService.eliminarCancion(this.idCancion, Number(this.idLista))
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.$emit('cancionEliminada');
            this.$store.dispatch('form/result', {isError: false, message: response.data.message});
          } else if (response.data.status === 'ERROR') {
            this.$store.dispatch('form/result', {isError: true, message: response.data.message});
          }
          this.isDeleting = false;
          this.$store.dispatch('form/submit', false);
        })
        .catch(error => {
          console.error(error);
          this.isDeleting = false;
          this.$store.dispatch('form/submit', false);
        });
    },
  }
};
</script>

<style scoped>
.pi-minus-circle {
  cursor: pointer;
}
.icon-red {
  color: red;
}

.icon-3x {
  font-size: 2em;
}
</style>
