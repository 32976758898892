<template>
    <nav class="navbar" :style="navVarPlayer" role="navigation" aria-label="main navigation" style="position:fixed;background-color: rgb(21 21 21);; opacity: 0.9; width: 100%; height: 70px;" >
        <div class="has-text-centered h-5rem">

            <div class="grid" v-if="seft_song !== null && seft_song !== -1">
                <div class="col-3 mt-2 ml-2">

                    <span v-if="seft_song !== null && seft_song !== -1 && seft_song !== undefined">
                        <img v-if="seft_song.portada !== null" class="z-index-0 mt-1" alt="no icon" :src="seft_song.portada" style="max-width:50px !important; max-height:50px !important;box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;">
                        <img v-else style="max-height:50px !important; max-height:50px !important;box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;" alt="icono corporativo" src="@/assets/logo.svg">
                    </span>
                </div>

                <div class="col-5 mt-2" :class="{'mt-2': device.isMobile,'mt-2': device.isDesktop}">
                    <span v-if="seft_song !== null && seft_song !== -1 && seft_song !== undefined">

                        <p class="text-white font-bold text-xs" @click.prevent="openArtistSongs()">
                            <span v-if="seft_song.artista !== null">

                                <span v-if="is_radioo">
                                    <i v-if="getIndicadorBuffer==false" class="pi pi-circle-fill" style="font-size: 7px; color: #3bf53b;"> </i>
                                <i v-else class="pi pi-exclamation-circle" style="font-size: 7px; color: red;"> </i>
                                </span>
                                
                                {{seft_song.artista.nombre}}

                            <span v-if="is_radioo"><br /></span> {{seft_song.artista.apellido}} - </span>
                            {{seft_song.nombre}} 
                                
                        </p>
                    </span>
                </div>

                 <div class="col-1 mr-3" v-if="seft_song && seft_song !== undefined && is_radioo == false" style="margin-top: 1.3rem !important;">
                    <favorite :cancion_id="seft_song.cancion_id"></favorite>
                </div>
                
                <div class="col-2 mt-3" >
                    <img v-if="getseft_playing == false && seft_song != null && seft_song !== -1 && seft_song !== undefined" @click.prevent="play()" class="z-index-0 custom-background-class mt-1" alt="no icon" src="@/assets/rojo/play.svg" style="height: 32px !important;">
                    <img v-if="getseft_playing == true && seft_song != null && seft_song !== -1 && is_radioo == false" @click.prevent="pausaClick()" class="z-index-0 custom-background-class mt-1" alt="no icon" src="@/assets/rojo/pausa.svg" style="height: 30px !important;">
                    <img v-if="getseft_playing == true && seft_song != null && seft_song !== -1 && is_radioo == true" @click.prevent="pausaClick()" class="z-index-0 custom-background-class mt-1" alt="no icon" src="@/assets/boton-stop-1.svg" style="height: 30px !important;">
                </div>

            </div>

            <div class="grid">
                <div class="col-12 mt-2">
                        <audio ref="player" controls controlsList="noplaybackrate nodownload nofullscreen" preload="auto" hidden>
                            <span v-if="seft_song !== null && seft_song !== -1 && seft_song !== undefined">
                            
                                <span v-if="seft_song.cancion !== null">
                                  <source :src="st+seft_song.cancion" type="audio/mpeg">
                                </span>
                            </span>
                            <span v-if="seft_song !== null && seft_song !== -1 && seft_song !== undefined">
                                <span v-if="seft_song.url_cancion !== null">
                                  <source :src="seft_song.ruta_cancion" type="audio/mpeg">  
                                </span>
                            </span>
                            Your browser does not support the audio tag.

                        </audio>
               
                </div>
            </div>

        </div>

         <loadermessage v-if="false" :device="device" :message="'Intentando Conectar'"></loadermessage>
    </nav>
</template>

<script>
    
    import {mapGetters} from 'vuex';
    import PlayerService from '@/service/PlayerService';
    import Favorite from '@/components/Favorite';
    import Loadermessage from '@/components/LoaderMessage';
    import { ForegroundService } from '@capawesome-team/capacitor-android-foreground-service';

       // import { MediaSessionPlugin } from '@jofr/capacitor-media-session';
    import { Capacitor } from '@capacitor/core';

    var intervalos = [];
    var timer;
    const SENSIBILIDAD_BUFER= 1.5;
    const SENSIBILIDAD_BUFER_ALERTA = 1.8;
    const SMALL_EXTRA_BUFFER = 5;
    const EXTRA_BUFFER = 10;
    let Player = {};
    (function () {

        this.name = 'player-component';

        this.created = function () {
            this.seft_playing = this.playing;
            this.seft_song = this.currentSong;
        };
        this.data = function () {
            return {
                 auidio_radio: null,
                 st: process.env.VUE_APP_STATIC,
                 seft_playing: false,
                 seft_song: null,
                 listenerActive: null,
                 duration: 0,
                 playbackTime: 0,
                 audioDuration: 100,
                 audioLoaded: false,
                 isPlaying: false,
                 position: 80,
                 audio: null,
                 curretn_time_past: 0,
                 coneccion_lenta: false,
                 indicador_buffer: false,
                 traying_conect: false,
            }
        };
        this.methods = {
            startForegroundService(nombreCancion, portadaCancion) {
                  ForegroundService.startForegroundService({
                    body: 'Estas escuchando: ' + nombreCancion,
                    title: nombreCancion,
                    id: 9999999,
                    smallIcon: './favicon.ico',
                    largeIcon: portadaCancion,
                  }).then(() => {
                    if (Capacitor.getPlatform() !== 'android') {
                      return;
                    }
                  }).catch((error) => {
                    console.log(error);
                  });
                },
            stopForegroundService() {
              ForegroundService.stopForegroundService().catch((error) => {
                console.log(error);
              });
            },
            openArtistSongs(){
                var at_name = ''
                var port = 0
                var rr = ''


                if(this.seft_song.offline == 1){
                    this.$router.push({name: 'offline-music'});
                    return;
                }

                if(this.seft_song.artista_id == -77){
                    this.$router.push({name: 'podcast'});
                    return;
                }

                if(this.is_radio == true && this.$route.name != 'home'){
                     this.$router.push({name: 'home'});
                    return;
                }else{
                    if(this.is_radio == true ){
                        return
                    }
                }


                if(this.seft_song.artista != null && this.seft_song.artista.artista_id !== null){
                        at_name = this.seft_song.artista.nombre+ ' ' + this.seft_song.artista.apellido;

                        if(this.seft_song.artista.tipo_arista !== undefined && this.seft_song.artista.tipo_arista.portada !== undefined && this.seft_song.artista.tipo_arista.portada !== "" && this.seft_song.artista.tipo_arista.portada !== null)
                            port = this.seft_song.artista.tipo_arista.portada;

                        rr = 'artist-songs'
                        if(this.$route.name== 'artist-songs')
                            rr = 'redirct-artist-sogs'

                        var tipo_art = '';

                        if(this.seft_song.artista.tipo_arista == null || this.seft_song.artista.tipo_arista == undefined){
                            tipo_art = this.seft_song.genero.nombre
                        }else{
                            tipo_art = this.seft_song.artista.tipo_arista.nombre;
                        }

                        var art_iddd = null

                        if(this.seft_song.artista.artista_id == null || this.seft_song.artista.artista_id == undefined){
                            art_iddd = this.seft_song.artista_id
                        }else{
                            art_iddd = this.seft_song.artista.artista_id
                        }

                        this.$router.push({name: rr,
                           params: {
                                artist_id: art_iddd,
                                artist_type_name: tipo_art,
                                img: port,
                                artist_name: at_name,
                                open_from_player: true,
                                daytimeid: -1,
                                daytime_nombre: -1,
                            }
                       });
                        return;
                }else{
                    if(this.seft_song.genero != null){
                        at_name = this.seft_song.genero.nombre
                        if(this.seft_song.genero.portada !== "" && this.seft_song.genero.portada !== null)
                            port = this.seft_song.genero.portada;

                        rr = 'playlist'
                        if(this.$route.name == 'playlist')
                            rr = 'redirect-playlist-genero'
                        
                        this.$router.push({name: rr,
                           params: {
                                id: this.seft_song.genero.genero_id,
                                name: this.seft_song.genero.nombre,
                                img: port,
                                open_from_player:true,
                            }
                       });
                        return;
                    }
                }

            },
            /*  myOnLoadedData(){
                this.$store.dispatch('form/submit', false);
            },
          convertTime(seconds){
                const format = val => `0${Math.floor(val)}`.slice(-2);
                var hours = seconds / 3600;
                var minutes = (seconds % 3600) / 60;
                return [minutes, seconds % 60].map(format).join(":");
            },

              totalTime() {
                var audio = this.$refs.player;
                if (audio) {
                    var seconds = audio.duration;
                    return this.convertTime(seconds);
                } else {
                    return '00:00';
                }
            },

            elapsedTime() {
                var audio = this.$refs.player;
                if (audio) {
                    var seconds = audio.currentTime;
                    return this.convertTime(seconds);
                } else {
                    return '00:00';
                }
            },

          playbackListener(e) {
                var audio = this.$refs.player;
                this.playbackTime = audio.currentTime;
                audio.addEventListener("ended", this.endListener);
                audio.addEventListener("pause", this.pauseListener);
            },

            pauseListener() {
                this.isPlaying = false;
                this.listenerActive = false;
                this.cleanupListeners();
            },

            endListener() {
                this.isPlaying = false;
                this.listenerActive = false;
                this.cleanupListeners();
            },

            cleanupListeners() {
                var audio = this.$refs.player;
                audio.removeEventListener("timeupdate", this.playbackListener);
                audio.removeEventListener("ended", this.endListener);
                audio.removeEventListener("pause", this.pauseListener);
                console.log("All cleaned up!");
            },

          /*  initSlider() {
                var audio = document.querySelector('audio');
                if (audio) {
                    this.audioDuration = Math.round(audio.duration);
                }
            }, 
            stop(){
                this.audio = null;
                this.seft_playing = false;
                this.$store.dispatch('song/set-current', {  activo: null,
                            artista:null,
                            artista_id:null,
                            cancion:null,
                            cancion_id:null,
                            fecha_creacion_cancion:null,
                            fecha_ingreso:null,
                            genero:null,
                            genero_id:null,
                            nombre:null,
                            portada:null,
                            vimeo_id:null,
                            url_cancion: null,
                        });
            }, */
            pausaClick(){
                if(this.is_radio == true){
                    this.$router.push({name: 'home'});
                    this.$store.dispatch('song/set-current', -1);
                    this.$store.dispatch('song/set-current', {  activo: null,
                                artista:null,
                                artista_id:null,
                                cancion:null,
                                cancion_id:null,
                                fecha_creacion_cancion:null,
                                fecha_ingreso:null,
                                genero:null,
                                genero_id:null,
                                nombre:null,
                                portada:null,
                                vimeo_id:null,
                                url_cancion:null,
                            });
                    this.$store.dispatch('song/set-is-playing', false);
                    this.$store.dispatch('song/set-is-radio', false);
                }else{
                    this.audio.pause();
                    this.seft_playing = false;
                    this.$store.dispatch('song/set-pausa', true)
                }
               
            },
            cleaningAllIntervals___(){
              var interval_id = window.setInterval(()=>{}, 99999);
                for (var i = 0; i < interval_id; i++)
                  window.clearInterval(i);
            },
            cleaningAllIntervals(){
                for (var i = 0; i < intervalos.length; i++) {
                    clearInterval(intervalos[i]);
                  }
                  // Limpiar el array
                  intervalos = [];
            },
            goTo (route) {
                this.selectedRoute = route;
                this.$router.push({name: route});
            },
            play(){
                 if(this.songPlaying == null || this.songPlaying == undefined){
                        this.$store.dispatch('song/set-list-songs', this.list_songs);
                        this.$store.dispatch('song/set-played', []);
                        this.$store.dispatch('song/set-current', this.currentSong);
                        this.$store.dispatch('song/set-is-radio', false);
                        this.$store.dispatch('song/set-is-playing', true);
                }else{
                    if(this.songPlaying.cancion_id == this.currentSong.cancion_id && this.pausa == true){
                        this.$store.dispatch('song/set-pausa', 0);   
                    } else{
                            this.$store.dispatch('song/set-list-songs', this.list_songs);
                            this.$store.dispatch('song/set-current', this.currentSong);
                            this.$store.dispatch('song/set-is-radio', false);
                            this.$store.dispatch('song/set-is-playing', true);
                      }
                }
/**
                this.seft_playing = this.playing;
                var cancion_del_pasado = this.currentSong;
                if (this.audio.paused) {
                    if(this.pausa === false){
                        if(this.seft_song.cancion !== null){
                          this.audio.src = this.seft_song.cancion;  
                        }
                        if(this.seft_song.url_cancion !== null){
                          this.audio.src = this.seft_song.url_cancion;  
                        }
                    }

                    this.$store.dispatch('form/submit', true);
                    var d = this;
                    this.audio.onloadeddata = function() {
                       d.$store.dispatch('form/submit', false);
                    };

                    this.audio.onplay = function() {
                       d.$store.dispatch('form/submit', false);
                    };

                    this.audio.onerror = function (e) {
                        if(this.is_radioo == true){
                            d.audio = null;
                            d.$store.dispatch('song/set-current', -1);
                            d.$store.dispatch('form/result', {isError: true, message: 'Se perdio conexión, reintentando conectar'});
                            d.$store.dispatch('song/set-is-playing', false);

                            d.traying_conect = true;
                            setTimeout(() => {
                                d.$store.dispatch('radio/set-lostconect', true);
                                d.traying_conect = false;
                                d.goTo("home");
                            }, 5000);
                        }
                        
                        
                    };

                    if (Capacitor.platform === 'android')
                        this.stopForegroundService();
                    this.audio.play();
                    if (Capacitor.platform === 'android')
                        this.startForegroundService(this.seft_song.nombre, this.seft_song.portada);

                    this.$store.dispatch('song/set-pausa', false);
                    this.$store.dispatch('song/set-duration-time', 0)
                    this.cleaningAllIntervals();
                    var endRanges = [];
                    var blokeo_extrabuffer = false;
                    var intentando = false;
                    var solo_dos = 0;
                    timer =  setInterval(function () {
             
                                    if(d.audio){
                                        d.$store.dispatch('song/set-duration-time', d.audio.duration);
                                        d.$store.dispatch('song/set-current-time', d.audio.currentTime);
                                        d.curretn_time_past = d.audio.currentTime;

                                    if(d.audio != null && d.list_songs && d.list_songs.length > 0){

                                        if(d.audio.currentTime ==  d.audio.duration && d.is_radio == false){

                                            var index = d.list_songs.findIndex(object => {
                                                  return object.cancion_id === d.seft_song.cancion_id;
                                            });

                                            if(index == d.list_songs.length-1){
                                                index = 0;
                                            }
                                    
                                            d.seft_song = d.list_songs[index+1];
                                            d.$store.dispatch('song/set-current', -1);
                                            d.$store.dispatch('song/set-current',d.list_songs[index+1]);
                                            d.$store.dispatch('song/set-is-playing', true);
                                            d.seft_playing = true;
                                            clearInterval(timer);
                                        }
                                    }

                                    }
                                }, 1000);
                    intervalos.push(timer);

                   d.$store.dispatch('song/set-is-playing', true);
                   this.seft_playing = true;

                } **/
                
            },
        };

        this.mounted = function () {
        
        };
        this.components = {Favorite, Loadermessage};
        this.computed = {
            getTraying_conect(){
                return this.traying_conect;
            },
            getIndicadorBuffer(){
                return this.indicador_buffer;
            },
            ...mapGetters({
                songPlaying: 'song/current',
                user_id: 'auth/user_id',
                fav: 'song/favovites',
                playing: 'song/is_playing',
                is_radioo: 'song/is_radio',
                new_time: 'song/new_time',
                songs_list_played: 'song/played',
            }),
            getseft_playing(){
                return this.seft_playing;
            },
             navVarPlayer() {
                  return {
                    "bottom": `${this.position}px`
                  };
                }
        };
        this.watch = {
            new_time(newValue){
                var newwww = newValue - 20;
                if(this.audio != null){
                    var difference = newwww - this.audio.currentTime;
                    if(Math.abs(difference) > 20){
                        this.audio.currentTime = newValue;
                    }
                }
            },
            currentSong(newValue){
                if(this.seft_song == null)
                    this.seft_song = this.currentSong;
                var cancion_del_pasado = this.currentSong;
                //console.log('en currentttttttt songgggg');
                //console.log(this.seft_song);
                //console.log(cancion_del_pasado)
                if(newValue){
                    //clearInterval(timer);
                    //this.cleaningAllIntervals();
                    if(this.audio == null){
                        this.audio = document.querySelector('audio')
                        this.$store.dispatch('form/submit', true);

                                    let d = this;

                                    this.audio.onloadeddata = function() {
                                        d.$store.dispatch('form/connect', false);
                                        d.$store.dispatch('form/submit', false);
                                    };

                                    this.audio.onplay = function() {
                                       console.log('playyy');
                                       d.$store.dispatch('song/set-is-playing', true);
                                       d.$store.dispatch('form/connect', false);
                                        d.$store.dispatch('video_player/setCurrentVideoId', null);
                                        d.$store.dispatch('form/submit', false);
                                       
                                    };

                                    this.audio.onended = function() {
                                            var temp_played = d.songs_list_played;
                                            if(temp_played == undefined || temp_played == null ){
                                                temp_played = []
                                            }
                                            temp_played.push(d.seft_song.cancion_id);
                                            d.$store.dispatch('song/set-played',temp_played);
                                            
                                                
                                            if(d.audio != null  && d.list_songs && d.list_songs.length > 0){
                                                if(d.is_radio == false){
                           
                                                    if(d.seft_song.offline == 1){

                                                        var indices = [];
                                                        for(var i = 0; i < d.list_songs.length; i++) {
                                                            if(d.list_songs[i].cancion_id === d.seft_song.cancion_id) {
                                                                indices.push(i);
                                                            }
                                                        }

                                
                                                        var nueva_porsion_repro = parseInt(d.seft_song.parte) + 1;
                                                        var encuentro_parte = false;
                                                        var indice_parte_siguiente = null;
                                                        for (var j = 0; j < indices.length; j++) {

                                                            if(d.list_songs[indices[j]].parte == nueva_porsion_repro){
                                                                encuentro_parte = true;
                                                                indice_parte_siguiente = indices[j];
                                                                break;
                                                            }

                                                        }

                                                        
                                                        if(encuentro_parte){
                                                             d.seft_song = d.list_songs[indice_parte_siguiente];
                                                            d.$store.dispatch('song/set-current',d.list_songs[indice_parte_siguiente]);
                                                            d.$store.dispatch('song/set-is-playing', true);
                                                            d.seft_playing = true; 
                                                        }else{
                                                            

                                                            var nuevo_indice = -1;


                                                            for (let jj = 0; jj < d.list_songs.length; jj++) {
                                                                

                                                                if (
                                                                    d.list_songs[jj].cancion_id !== d.seft_song.cancion_id &&
                                                                    d.list_songs[jj].parte === '0' &&
                                                                    !d.songs_list_played.includes(d.list_songs[jj].cancion_id)
                                                                ) {
                                                                    nuevo_indice = jj;
                                                                    break;
                                                                }
                                                            }


                                                              
                                                                if(nuevo_indice > -1){
                                                                    d.seft_song = d.list_songs[nuevo_indice];
                                                                    d.$store.dispatch('song/set-current',d.list_songs[nuevo_indice]);
                                                                    d.$store.dispatch('song/set-is-playing', true);
                                                                    d.seft_playing = true;
                                                                } 

                                                        }


                                                    }else{
                                                        var index = d.list_songs.findIndex(object => {
                                                          return object.cancion_id === d.seft_song.cancion_id;
                                                        });

                                          
                                                        if(index >= 0){
                                                            if(index == d.list_songs.length-1){
                                                                index = 0;
                                                            }
                                                 
                                                            d.seft_song = d.list_songs[index+1];
                                                            //d.$store.dispatch('song/set-current', -1);
                                                   
                                                            d.$store.dispatch('song/set-current',d.list_songs[index+1]);
                                                       
                                                            d.$store.dispatch('song/set-is-playing', true);
                                                
                                                            d.seft_playing = true; 
                                                           
                                                        }
                                                    }

                                                   
                                                }
                                            }

                                        };

                                   this.audio.onerror = function (e) {
                                        if(this.is_radioo == true){
                                            d.audio = null;
                                            //d.$store.dispatch('song/set-current', -1);
                                            d.$store.dispatch('form/result', {isError: true, message: 'Se perdio conexión, reintentando conectar'});
                                            d.$store.dispatch('song/set-is-playing', false);

                                            d.traying_conect = true;
                                            setTimeout(() => {
                                                d.$store.dispatch('radio/set-lostconect', true);
                                                d.traying_conect = false;
                                                d.goTo("home");
                                            }, 5000);
                                        }else{
                                            console.log(JSON.stringify(e));
                                          console.log('error cancion revisar');
                                            d.pausa();
                                        }
                                        d.$store.dispatch('form/connect', false);
                                        d.$store.dispatch('form/submit', false);
                                    };
                    }

                    if(newValue != null && this.audio != null && newValue != -1){
                            var cancion1 = null;
                            var cancion2 = null;

                            if(newValue.cancion === null && newValue.url_cancion === null){
                   
                                    this.audio.pause();
                                    this.audio == null;
                                    return;
                            }

              
                            cancion2 = this.audio.src.substring(this.audio.src.length -50, this.audio.src.length);

                            if(newValue.cancion !== null && newValue.cancion !== '' && newValue.cancion !== this.audio.src){
                                 cancion1= newValue.cancion.substring(newValue.cancion.length -50, newValue.cancion.length);

                                    if(this.currentSong.offline == 1){
                                        this.audio.src = Capacitor.convertFileSrc(this.currentSong.cancion);
                                    }else{
                                        this.audio.src = this.currentSong.cancion;
                                    }


                            }
                       
                            if(newValue.url_cancion !== null && newValue.url_cancion !== '' && newValue.url_cancion !== this.audio.src){
                                 cancion1=  newValue.url_cancion.substring(newValue.url_cancion.length -50, newValue.url_cancion.length);
                                
                                if(cancion1 !== cancion2){
                                    
                                    if(this.currentSong.offline == 1){
                                        this.audio.src = Capacitor.convertFileSrc(this.currentSong.url_cancion);
                                    }
                                    else{
                                        this.audio.src = this.currentSong.url_cancion;
                                    }

                                }

                            }
  
                        this.stopForegroundService();
                        this.traying_conect = true;
                        this.$store.dispatch('form/submit', true);
                        this.$store.dispatch('form/connect', true);

                        this.audio.play();
           
                        this.startForegroundService(newValue.nombre, newValue.portada);
                       
                        let d = this;
                        this.$store.dispatch('song/set-pausa', false);
                        this.$store.dispatch('song/set-duration-time', 0)
                        
                        var endRanges = [];
                        //var blokeo_extrabuffer = false;
                        //var intentando = false;
                        //var solo_dos = 0;

                        if(this.currentSong.offline != 1){
                        timer =  setInterval(function () {
                        if(d.audio){
                            d.$store.dispatch('song/set-duration-time', d.audio.duration);
                            d.$store.dispatch('song/set-current-time', d.audio.currentTime);
                            //d.curretn_time_past = d.audio.currentTime;
                                if(d.audio != null  && d.list_songs && d.list_songs.length > 0){

                                    if(d.audio.currentTime ==  d.audio.duration && d.is_radio == false){
                                         clearInterval(timer);
                                    }
                                }
                                
                            }
                        }, 1000);
                        intervalos.push(timer);
                    }

                        this.$store.dispatch('song/set-is-playing', true);
                        this.seft_playing = true;
                        this.seft_song = this.currentSong;
                    }else{

                            this.seft_playing = this.playing;

                            this.seft_song = this.currentSong;
                            //if(this.audio == null){
                                //this.audio = document.querySelector('audio')
                                //this.play();
                            //}
                            
                    }
                }
            },
            pausa(newValue){
                if(newValue == true){
                   this.pausaClick() 
                }
                if(newValue == 0){
                    if(this.audio){
                        this.audio.play();
                        this.seft_playing = true;
                    }
                   
                }
            },
        };
        this.props = ['device', 'currentSong', 'current_route', 'pausa', 'is_radio', 'list_songs'];
    }).apply(Player);
    export default Player;
</script>

<style scoped>

</style>
