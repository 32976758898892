<template>
<span>

 <!--img @click.prevent="router.push('/home');" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/arrowBack.svg" style="z-index:1000;height: 2rem !important; position: fixed; top: 40px !important; left: 20px !important;"-->
        
  <div class="video-container">
       
    <video
      id="videoPlayer"
      autoplay
      muted
      class="video"
    >
      <source src="@/assets/rojo/intro_radio.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
  </span>
</template>

<script>

import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';  
import ArtistService from '@/service/ArtistService';

export default {
  setup() {
    const router = useRouter();
     const store = useStore(); 

    onMounted(() => {
 
      ArtistService.allArtistTypeAndArtist2().then((response) => {
            store.dispatch('djs/set-djs', response.data);
        }).catch(error => {
            console.error('Error fetching artist types:', error);
        });

      const videoPlayer = document.getElementById('videoPlayer');
      if (videoPlayer) {
        console.log('videoPlayer element found.');
        videoPlayer.addEventListener('loadedmetadata', () => {
          console.log('Video metadata loaded.');
          console.log(`Video duration: ${videoPlayer.duration} seconds`); 
        });
        videoPlayer.addEventListener('ended', () => {
          console.log('Video ended event triggered.');
          onVideoEnded();
        });
        videoPlayer.addEventListener('error', (e) => {
          console.log(`Error in video playback: ${e.message}`); 
        });
      } else {
        console.log('Failed to find videoPlayer element.'); 
      }
    });

    const onVideoEnded = () => {
      console.log('onVideoEnded method called.');
      router.push('/login-email');
    };


    return {
      onVideoEnded
    };
  }
}
</script>




<style>
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Esto asegura que el video cubra toda la pantalla sin deformarse */
}
</style>
