<template>
<span>
    <toolbar-comp v-if="showTopBar"></toolbar-comp>
      <result-message></result-message>
    <div class="pt-4 mt-6">
      <div class="z-2">
        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>
        <router-view :device="device"></router-view>
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      </div>
      <loader v-if="isLoading" :device="device"></loader>
       <!--loadermessage v-if="isConnecting" :device="device" :message="'CONECTANDO'"></loadermessage-->
    </div>

    <VimeoPlayer
      :videoId="currentVideoId"
      :isActive="isPlayerActive"
      :isMinimized="isPlayerMinimized"
      :videoCurrentTime="videoCurrentTime"
      :device="device"
    />

     <span v-show="songPlaying && currentVideoId == null"> 
      
      <player :class="{
    'z-menus-2': !songPlaying || !playing || isCancionIdDefined(songPlaying) && songPlaying.cancion_id == songOnScreen.cancion_id || isCancionIdDefined(songOnScreen) && songOnScreen.cancion_id == -2, 
    'z-5': isCancionIdDefined(songPlaying) && isCancionIdDefined(songOnScreen) && playing && songPlaying.cancion_id != songOnScreen.cancion_id && songOnScreen.cancion_id != -2
}" :current_route="$route.name" :currentSong="songPlaying" :pausa="pausa" :is_radio="is_radio" :list_songs="list_songs" :device="device"></player>


    </span>

            <BottomBar v-if="showBottomBar"></BottomBar>
            </span>
</template>

<style src="animate.css/animate.min.css"></style>

<script>
    import {mapGetters} from 'vuex'
    import ToolbarComp from '@/components/ToolbarComp'
    import Loader from '@/components/Loader'
    import ResultMessage from '@/components/ResultMessage'
    import BottomBar from '@/components/BottomBar'
    import Player from '@/components/Player'
    //import Loadermessage from '@/components/LoaderMessage';

    import { Capacitor } from '@capacitor/core';
    import { mapActions } from 'vuex';
    import { Filesystem, Directory } from '@capacitor/filesystem';

    import VimeoPlayer from '@/components/VimeoPlayer';


    //import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';

    //import { BackGroundMode } from '@cordova-plugin-background-mode';
    //BackGroundMode.enable()
    const breakpoints = {
            mobile: {until: 760},
            tablet: {from: 720, until: 1023},
            desktop: {from: 1024, until: 2408},
            widescreen: {from: 1216, until: 1407},
            fullhd: {from: 1408}
        };

export default {
  name: 'app',
  components: {
    ToolbarComp, Loader, ResultMessage, BottomBar, Player, VimeoPlayer
  },
  async mounted(){
      const directoryPath = 'canciones';
      await this.getDirectory(directoryPath, { recursive: false });
  },
   created() {
      this.onResize();
      window.addEventListener('resize', this.onResize);
      //this.fillDownloadedSongs();
  },
  data() {
          return {
              device: {
                  isMobile: false,
                  isTablet: false,
                  isTabletOnly: false,
                  isDesktop: false,
                  isDesktopOnly: false,
                  isWidescreen: false,
                  isWidescreenOnly: false,
                  isFullhd: false
              }
          }
      },
  computed: {
             showBottomBar: function () {
      const dontShowIn = ['intro', 'login', 'login-email','register-user', 'recovery-pass'];
                return dontShowIn.indexOf(this.$route.name) === -1;
            },
             showTopBar: function () {
                const dontShowIn = ['intro', 'login', 'register-user', 'recovery-pass', 'login-email'];
                return dontShowIn.indexOf(this.$route.name) === -1;
            },
             ...mapGetters('video_player', [
              'currentVideoId',
              'isPlayerActive',
              'isPlayerMinimized',
              'videoCurrentTime'
            ]),
             ...mapGetters({
                isLoading: 'form/is-submiting',
                songPlaying: 'song/current',
                playing: 'song/is_playing',
                pausa: 'song/pausa',
                new_time: 'song/new_time',
                list_songs: 'song/list_songs',
                is_radio: 'song/is_radio',
                songOnScreen: 'song/current_screen',
                isConnecting: 'form/is-connecting',
            })
  },
  methods: {

     isCancionIdDefined(song) {
        return song && song.cancion_id !== undefined;
    },

    async getDirectory(directoryName, options) {
      try {
        // Check if directory already exists
        const statResult = await Filesystem.stat({
          path: directoryName,
          directory: Directory.Documents
        });
        // If directory exists, return the stat result
        if (statResult.type === 'directory') {
          return statResult;
        }
      } catch (error) {
        // Handle error (directory not found)
        console.log(`Directory ${directoryName} not found, creating it...`);
      }

      try {
        // Create the directory
        const result = await Filesystem.mkdir({
          path: directoryName,
          directory: Directory.Documents,
          recursive: options.recursive || false
        });
        return result;
      } catch (error) {
        console.log(`Error creating directory ${directoryName}: ${error.message}`);
        throw error;
      }
    },
    ...mapActions('songs_downloading', ['addDownloadedSong']),
   /* async fillDownloadedSongs() {
      try {
        const directory = await Filesystem.getDirectory({
          directory: 'CACHE',
        });
        const files = await Filesystem.readdir({
          path: directory.uri,
        });
        const audioFiles = files.files.filter(file => file.name.endsWith('.mp3'));

        audioFiles.forEach(async file => {
          const song = {
            id: file.name,
            fileName: file.name,
          };
          await this.addDownloadedSong(song);
        });
      } catch (error) {
        console.error(error);
      }

      // Si se está ejecutando en iOS, se puede agregar código adicional aquí
      if (Capacitor.isNativePlatformIOS) {
        // Código específico para iOS
      }

      // Si se está ejecutando en Android, se puede agregar código adicional aquí
      if (Capacitor.isNativePlatformAndroid) {
        // Código específico para Android
      }
    }, */
    onResize(){
      this.device.isMobile = window.innerWidth <= breakpoints.mobile.until;
      this.device.isTablet = window.innerWidth >= breakpoints.tablet.from;
      this.device.isTabletOnly = window.innerWidth >= breakpoints.tablet.from && window.innerWidth <= breakpoints.tablet.until;
      this.device.isDesktop = window.innerWidth >= breakpoints.desktop.from;
      this.device.isDesktopOnly = window.innerWidth >= breakpoints.desktop.from && window.innerWidth <= breakpoints.desktop.until;
      this.device.isWidescreen = window.innerWidth >= breakpoints.widescreen.from;
      this.device.isWidescreenOnly = window.innerWidth >= breakpoints.widescreen.from && window.innerWidth <= breakpoints.widescreen.until;
      this.device.isFullhd = window.innerWidth >= breakpoints.fullhd.from;
    },
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* Para Chrome y Safari */
::-webkit-scrollbar {
    display: none;
}

/* Para Firefox */
html {
    scrollbar-width: none; /* Firefox 64+ */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

/* Para Edge */
body {
    -ms-overflow-style: none;
}


.p-button-secondary {
    color: white !important;
    background: #0b0b0c !important;
    border: 1px solid #8f98a1 !important;
}

.p-button-info {
    color:  #1f1f1f !important;
    background: #FAFBE4 !important;
    border: 1px solid #FAFBE4 !important;
}

body{
  background-color: #dd0338;
  margin-left: 0;
  margin-right: 0;
  color: white;
}

.card-prime {
  background-color: transparent !important;
}

.portada-mobile{
  height: auto !important; 
  width: 100% !important
}

.portada-desktop{
  height: 15rem !important; 
  width: auto !important
}

.p-menu .p-submenu-header{
    background-color: transparent !important;
}

.p-submenu-header{
    background-color: transparent !important;
    color: white !important;
}

.bg-primary-cus{
    background-color: #D70337 !important;
}

.p-menu .p-menuitem-link .p-menuitem-text {
     color: white !important;
}

.p-menu .p-menuitem-link .p-menuitem-text {
     border: none;
      border: 1px solid transparent;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    color: white !important;
     border: 1px solid transparent;
}

.p-menu {
    border: 1px solid #FAFBE4 !important;
}

.z-menus-2{
  z-index: -2 !important;
}


.p-tabview .p-tabview-nav li .p-tabview-nav-link{
    background: transparent !important;
    color: white !important;
}

.p-tabview-panels{
    background: transparent !important;
    color: white !important;
}

.p-tabview.p-tabview-panels {
    background: transparent !important;
    color: white !important;

}

.p-tabview .p-tabview-nav li .p-tabview-naxxv-link {

    background: transparent !important;
    color: white !important;

   }

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.p-sidebar-full .p-sidebar {
    transition: none;
    transform: none;
    width: 100vw !important;
    height: 100vh !important;
    max-height: 100%;
    top: -9px !important;
    left: -1px !important;
}

  .p-progressbar .p-progressbar-value {
            background: #F90101 !important;
    }


   .p-button-label .p-button-text{
        color: white;
    }

  .p-button-success{
    color: white !important;
  }

  .p-sidebar {
      background: #D70337 !important;
  }

  .p-sidebar-close .p-sidebar-icon .p-link{
    color: white !important;

  }

  .p-dialog{
    background-color: #D70337 !important;

  }
  
</style>
