<template id="home">
    <span>
        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

   <TituloPage :nombre="nombre" @back="back()">
            <template v-slot:share>
            <ShareComponent />
            </template>
        </TituloPage>


   <div class="grid ml-4 mr-4">
            <div class="col-12 md:col-12 lg:col-12">
     <p class="text-white text-xs font-bold">Escucha los generos musicales de la radio San nicolas y Aconcagua</p>
                </div>  
 </div>  

   <div class="grid mr-2 ml-2">
    <div class="col-12 sm:col-6 md:col-4 lg:col-3 p-2" v-for="(d, idxx) in artistType" :key="idxx" @click.prevent="openArtistSongs(d)">

        <div class="card2 shadow-3" style="background-color: #1f1f1f; display: flex; align-items: center; padding: 0;">

            <div style="flex: 0 0 90px; height: 90px; position: relative; background-color: #333; display: flex; align-items: center; justify-content: center;">
                <ImageFallback v-if="d.foto_artista != null"
                    :imageSource="st + d.foto_artista"
                    :fallbackSrc="'@/assets/logo-radio.png'"
                    :alt="'Imagen de Artista'"
                    class="z-index-0"
                    style="max-width: 100%; max-height: 100%;"
                />
                <ImageFallback v-else
                    :imageSource="'@/assets/logo-radio.png'"
                    :fallbackSrc="'@/assets/logo-radio.png'"
                    :alt="'noico'"
                    class="z-index-0"
                    style="max-width: 100%; max-height: 100%;"
                />

                <div class="gradient-overlay"></div>
            </div>

            <div style="flex-grow: 1; padding-left: 10px;">
                <p class="text-white font-bold text-xs">{{ d.nombre }} {{ d.apellido }}</p>
            </div>
        </div>
    </div>
</div>



        <div style="width: 100%"></div>

        <side-bar v-model:visible="visibleFULL" :baseZIndex="10000" position="full" class="bg-primary-cus" :showCloseIcon="true">
            <selecteddaytime :device="device" @horario="seleccionarHorario"></selecteddaytime>
        </side-bar>

         <side-bar v-model:visible="visibleRight" :baseZIndex="10000" position="right" class="bg-primary-cus" :showCloseIcon="false">
            <h3 style="color: white !important;">{{this.artistTypeSelected.nombre}}</h3>
             <div class="grid">
                <div class="col-12 md:col-12 lg:col-12"  v-for="(c, idx) in artists" :key="idx" >
                    <span @click.prevent="openArtistSongs(c)" class="text-white font-bold" style="color: white !important;">
                         {{c.nombre}} {{c.apellido}}</span>
                    <!--span class="text-white font-bold" style="color: #0a0850 !important;">-{{c.name}}</span-->
                </div>
            </div>
 </side-bar>
<br /><br /><br /><br /><br /><br /><br /><br /><br />
    </span>     
</template>

<style>
        .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
     color: black !important;
}

.neon-border-bottom {
    position: relative;
    border-bottom: solid white !important;
}

.neon-border-bottom::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: white;
    box-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 40px white;
}

.gradient-overlay {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 30px; /* Ajusta según necesidad para el tamaño del degradado */
    background: linear-gradient(to left, #1f1f1f, transparent);
    z-index: 1;
}

.card2 {
    background-color: #1f1f1f;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.shadow-3 {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
}
</style>

<script>

    import Backgroundcustom from '@/components/Backgroundcustom';
    import GenerosService from '@/service/GenerosService';
    import ArtistService from '@/service/ArtistService';
    import Selecteddaytime from '@/components/SelectedDayTime';
    import {mapGetters} from 'vuex';
    import ImageFallback from '@/components/ImageFallback.vue';
    import ShareComponent from '@/components/ShareComponent'
      import TituloPage from '@/components/TituloPage'

    let SelectGenero = {};
    (function () {
        this.name = 'home-page';
        // let _self = null;

        this.created = function () {
                this.nombre = this.$route.params.nombre;
                this.genero_id = this.$route.params.id;
                this.getAlltipoArtista();
            
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
                visibleFULL: false,
                selectedDayTime: {'tiempo_dia_id': -1, 'nombre': null},
                artistType: [],
                artistTypeSelected: null,
                artists: [],
                st: process.env.VUE_APP_STATIC,
                visibleRight: false,
            }
        };
        this.methods = {
            back(){
                this.$router.push({name: 'select-genero'});
            },
            seleccionarHorario(s){
                this.selectedDayTime = s;
                this.visibleFULL = false;
            },
            openModal(){
                this.visibleFULL = true;
            },
            openArtistSongs(a){
                this.visibleRight = false;
                var at_name = a.nombre + ' ' + a.apellido
                var port = 0
      
                var selec_id = -1
                var nom_day_time = -1;

                if(this.selectedDayTime != null){
                    selec_id = this.selectedDayTime.tiempo_dia_id
                     nom_day_time = this.selectedDayTime.nombre;
                }

                this.$router.push({name: 'artist-prev-songs',
                   params: {
                        artist_id: a.artista_id,
                        artist_type_name: '',
                        img: port,
                        artist_name: at_name,
                        open_from_player: false,
                        daytimeid: selec_id,
                        daytime_nombre: nom_day_time,
                    }
               });

            },
            setVisibleModal(){
                console.log('visible');
                this.visibleRight = true;
            },
            getArtistaPorTipo(tipo){
                this.artistTypeSelected = tipo;
                this.$store.dispatch('form/submit', true);
               ArtistService.artistByTipo(this.artistTypeSelected.tipo_artista_id).then((response) => {
                    this.artists = response.data;
                    if(this.artists.length == 0){
                         this.$store.dispatch('form/result', {isError: true, message: 'Sin artistas'});
                    }else{
                        this.visibleRight = true;
                    }
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAlltipoArtista(){
                this.$store.dispatch('form/submit', true);
               ArtistService.allArtistbyGenero(this.genero_id).then((response) => {
                    this.artistType = response.data;
                    this.$store.dispatch('radio/set-djs', response.data);
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            allArtistTypeAndArtistDayTime(id_day_time){
                this.$store.dispatch('form/submit', true);
               ArtistService.allArtistTypeAndArtistDayTime(id_day_time).then((response) => {
                    this.artistType = response.data;

                    if(response.data.length == 0 ){
                        this.$store.dispatch('form/result', {isError: true, message: 'Artistas sin momentos'});
                    }else{
                        this.$store.dispatch('radio/set-djs', response.data);
                        this.$store.dispatch('form/submit', false);  
                    }
                    
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            goTo: function (route, id, name, url) {
                this.$router.push({name: route,
                   params: {
                        id: id,
                        name: name,
                        img: url,
                    }
               });
            }
        };
        this.components = {Backgroundcustom, Selecteddaytime, ImageFallback, ShareComponent, TituloPage};
        this.computed = {
             ...mapGetters({
                djs: 'radio/djs'
            })
        };
        this.watch = {
             selectedDayTime(newValue){
                if (newValue && newValue.tiempo_dia_id) {
                    this.artistType = [];
                    console.log(newValue.tiempo_dia_id);
                    this.allArtistTypeAndArtistDayTime(newValue.tiempo_dia_id);
                } else {
                    console.log('newValue is null or does not have tiempo_dia_id');
                    if(this.djs == null){
                            this.getAlltipoArtista();
                        }else{
                            this.artistType = this.djs;
                        }
                }
            }
        };
        

        this.props = ['device'];
    }).apply(SelectGenero);

    export default SelectGenero
</script>
