import axios from "axios";

export default {
    
    allArtistType() {
        const url = `${process.env.VUE_APP_API_URL}/allartistype`;
        let data = {'id': null}
        return axios.post(url, data)
    },

    allArtistTypeAndArtist() {
        const url = `${process.env.VUE_APP_API_URL}/allartistypeandartist`;
        let data = {'id': null}
        return axios.post(url, data)
    },


    allArtistTypeAndArtist2() {
        const url = `${process.env.VUE_APP_API_URL}/allartistypeandartist2`;
        let data = {'id': null}
        return axios.post(url, data)
    },

     allArtistTypeAndArtistDayTime(id_day_time) {
        const url = `${process.env.VUE_APP_API_URL}/allartistypeandartistdaytime`;
        let data = {'day_time_id': id_day_time}
        return axios.post(url, data)
    },

    allArtistbyGenero(id){
        const url = `${process.env.VUE_APP_API_URL}/allartistypeandartistgenero`;
        let data = {'id_genero': id}
        return axios.post(url, data)
    },


	artistByTipo(id) {
        const url = `${process.env.VUE_APP_API_URL}/artistbytype`;
        let data = {'id': id}
        return axios.post(url, data)
    },

}
