<template id="home">
    <span>
        <backgroundcustom :fondo="0" :device="device"></backgroundcustom>

        <p class="text-3l font-bold text-white">RECOMENDACIÓN MUSICALES</p>

        <div class="grid mt-4 mr-3 ml-2">
            <div class="col-4 md:col-1 lg:col-1" v-for="(g, idx) in daytime" :key="idx" >
                
                <img v-if="g.portada == null || g.portada == ''" class="z-index-0 ml-2" alt="no icon"  src="@/assets/logo-radio.png" style="width: 100%;box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;" @click.prevent="goTo('playlist-daytime', g.tiempo_dia_id, g.nombre, -1)">

                <img v-else class="z-index-0 ml-2" alt="no icon" :src="st+g.portada" style="width: 100%;box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;" @click.prevent="goTo('playlist-daytime', g.tiempo_dia_id, g.nombre, g.portada)">
                <p class="text-white text-7l font-bold" style="text-align: center;">{{g.nombre}}</p>
            </div>
        </div>
    </span>     
</template>


<script>

    import Backgroundcustom from '@/components/Backgroundcustom';
    import DayTimeService from '@/service/DayTimeService';
    import {mapGetters} from 'vuex';
    
    let SelectDayTime = {};
    (function () {
        this.name = 'day-time-page';
        // let _self = null;

        this.created = function () {
            if(this.daytime == null){
                this.getAllDayTimes();
            }
            else{
                this.current_daytime = this.daytime;
            }
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
               current_daytime: [],
               st: process.env.VUE_APP_STATIC

            }
        };
        this.methods = {
            getAllDayTimes(){
                this.$store.dispatch('form/submit', true);
               DayTimeService.all().then((response) => {
                    //this.generos = response.data;
                    this.$store.dispatch('radio/set-daytime', response.data);
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            goTo: function (route, id, name, url) {

                this.$router.push({name: route,
                   params: {
                        id: id,
                        name: name,
                        img: url,
                    }
               });
            }
        }; 
        this.components = {Backgroundcustom};
        this.computed = {
            getCurrentDayTime(){
                return this.current_daytime; 
            },
            ...mapGetters({
               daytime: 'radio/daytime'
            })
        };
        this.watch = {

        };
        

        this.props = ['device'];
    }).apply(SelectDayTime);

    export default SelectDayTime
</script>
