<template>

<span>
    <div class="card" v-if="info">
        <div class="grid mt-4 mr-3 ml-2" style="text-align: center !important;">
            <div class="col-4 md:col-2 lg:col-1">
                <img class="z-index-0" alt="Playlist icon" src="@/assets/rojo/btn_playlist.svg" style="width:100%;" @click.prevent="goTo('listas')">
            </div>
            <div v-if="info.platform != 'web'" class="col-4 md:col-2 lg:col-1">
                <img class="z-index-0" alt="Offline icon" src="@/assets/rojo/btn_offline.svg" style="width:100%;" @click.prevent="goTo('offline-music')">
            </div>
            <div class="col-4 md:col-2 lg:col-1">
                <img class="z-index-0" alt="Favoritos icon" src="@/assets/rojo/btn_favoritos.svg" style="width:100%;" @click.prevent="goTo('favorite')">
            </div>
        </div>
    </div>

           <dialog-prime header="Registrate" v-model:visible="showfavDialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :position="'Bottom'" :modal="true">
            <p class="m-0">Usted debe estar Registrado.</p>
                
            <template #footer>
                <button-prime class="w-7rem p-button-link" label="Ingresar" @click.prevent="goTo('login-email')" style="font-weight: 800; color: #aa00ff;"/>
                <button-prime class="w-7rem p-button-link" label="Registrarse" @click.prevent="goTo('register-user')" style="font-weight: 800; color: #aa00ff;"/>
            </template>
        </dialog-prime>
</span>
</template>

<script>

  import {mapGetters} from 'vuex';

export default {

     data () {
                return {

                    showfavDialog: null, 
                }
     },
    props: ['info'],
            computed:{

                ...mapGetters({
                 authenticated: 'auth/check'
                }),
            },
    methods: {
        goToListas(route) {
            this.$router.push({ name: route });
        },
         goTo (route) {
                if((route == 'offline-music' && this.authenticated == false) || (route == 'favorite' && this.authenticated == false) || (route == 'listas' && this.authenticated == false)){
                    this.showfavDialog = true;
                }else{
                    this.selectedRoute = route;
                    this.$router.push({name: route});
                }
            },
    }
}
</script>
