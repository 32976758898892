<template>
  <span>
    <backgroundcustom :fondo="1" :device="device" />
    <div class="p-3">
      <div class="flex align-items-center justify-content-center">
        <InputText v-model="searchTerm" placeholder="Buscar canciones..." class="p-inputtext-sm" />
        <ButtonPrime label="Buscar" @click="search" icon="pi pi-search" class="p-button-rounded p-button-info ml-2" />
      </div>

      <!-- Displaying No Results Found -->
      <div v-if="canciones.length === 0 && searchTerm">
        <p>No se encontraron resultados.</p>
      </div>

      <!-- Song List -->
      <div v-else>
            <div class="song-list">
            <div v-for="song in canciones" :key="song.cancion_id" class="song-item">
            <div class="song-details">
                <p><strong>{{ song.artista.nombre }} {{ song.artista.apellido }}</strong></p>
                <p>{{ song.nombre }}</p>
            </div>
            <div class="play-button">
                <img   @click="playSong(song)" class="z-index-0 custom-background-class ml-3" alt="no icon" src="@/assets/rojo/play.svg" style="height: 50px !important;">
            </div>
            </div>
        </div>

        <ButtonPrime label="Cargar más..." @click="loadMore" class="p-button-outlined p-button-primary mt-5" style="color:white;" />
      </div>
    </div>
  </span>
</template>

<script>
import axios from 'axios';
  import Backgroundcustom from '@/components/Backgroundcustom';

export default {
  name: 'BuscarCanciones',
  components: {
    Backgroundcustom
  },
  props: {
    device: Object
  },
  data() {
    return {
      canciones: [],
      searchTerm: "",
      pageIndex: 0,
      pageSize: 10
    }
  },
  methods: {
    async search() {
      this.pageIndex = 0;
      this.canciones = [];
      await this.loadMore();
    },
    async loadMore() {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/search-by-nombre/`, {
        search: this.searchTerm,
        pag_start: this.pageIndex * this.pageSize,
        pag_finish: this.pageSize
      });

      if (response.data && response.data.canciones.length > 0) {
        this.canciones.push(...response.data.canciones);
        this.pageIndex++;
      }
    },
    playSong(cancion) {
      

                        this.$store.dispatch('song/set-list-songs', this.canciones);
                        this.$store.dispatch('song/set-current', cancion);
                        this.$store.dispatch('song/set-is-radio', false);
                        this.$store.dispatch('song/set-is-playing', true);
                
    }
  }
}
</script>

<style scoped>
.shadow-2 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.song-list {
    color: white;
  background-color: transparent;
  padding: 20px;
}
.song-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.song-details p {
  margin: 0;
}
.play-button button {
  cursor: pointer;
}
</style>
