<template id="login">
    <div class="md-min-width card mb-5">
        <backgroundcustom :fondo="0" :device="device"></backgroundcustom>
        <div>
            <img style="width: 80px !important;" alt="icono corporativo" src="@/assets/logo.svg">
        </div>
            <h3 class="text-white">Ingrese sus datos</h3>

            <div class="field">
                <h5 for="username2" class="text-white">Correo Electronico *</h5>
                <InputText style="width: 230px !important;" id="username2" type="username" aria-describedby="username2-help"  :class="{'p-invalid': error.credentials}" v-model="username"/>
                
            </div>

            <div class="field">
                <h5 for="username2" class="text-white">Repita Correo Electronico *</h5>
                <InputText style="width: 230px !important;" id="username2" type="email" aria-describedby="username2-help"  :class="{'p-invalid': error.credentials}" v-model="email"/>
            </div>

            <button-prime class="ml-1 w-8rem mt-5 p-button-info" label="Registrarse" @click.prevent="registrate(false)" style="font-weight: 800; color: #aa00ff;"/>

            <img @click.prevent="back()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/arrowBack.svg" style="height: 2rem !important; position: fixed; top: 40px !important; left: 20px !important;">
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </div>
</template>



<script>
    import {mapGetters} from 'vuex';
    import AuthService from '@/service/AuthService';
     import Backgroundcustom from '@/components/Backgroundcustom'

    let RegisterUser = {};
    (function () {
        this.name = 'login-page';
        // let _self = null;

        this.created = function () {
            this.$store.dispatch('form/error', {credentials: false});
        };

        this.mounted  = function () {
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -2});
        };

        this.data = function () {
            return {
                radio: process.env.VUE_APP_RADIO,
                username: null,
                name: null,
                password: null,
                repassword: null,
                email: null,
            }
        };
        this.methods = {
            back(){
                this.$router.push({name: 'home'});
            },
            registrate(con_login){
                let result = {isError: false, message: null}
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)

                this.$store.dispatch('form/error', {
                    credentials: false,
                });

                if (this.username === null || this.username.trim() === '') {
                    this.$store.dispatch('form/result', {isError: true, message: 'Email obligatorio'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

               /** if (this.name === null || this.name.trim() === '') {
                    this.$store.dispatch('form/result', {isError: true, message: 'Nombre obligatorio'});
                    this.$store.dispatch('form/submit', false)
                    return;
                } **/

                if (this.email === null || this.email.trim() === '') {
                    this.$store.dispatch('form/result', {isError: true, message: 'Email obligatorio'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                if (this.email !== null && this.email) {
                    var reEmail = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
                    if (reEmail.test(this.email) === false) {
                        this.$store.dispatch('form/result', {isError: true, message: 'Email mal formateado'});
                        this.$store.dispatch('form/submit', false)
                        return;
                    }
                }

              /*  if (this.password === null || this.password.trim() === '') {
                    this.$store.dispatch('form/result', {isError: true, message: 'Contraseña obligatorio'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                if (this.repassword === null || this.repassword.trim() === '') {
                    this.$store.dispatch('form/result', {isError: true, message: 'Repita Contraseña obligatorio'});
                    this.$store.dispatch('form/submit', false)
                    return;
                } */


                 if(this.username !== null && this.email !== null){
                   if (this.username !== this.email.trim()) {
                    this.$store.dispatch('form/result', {isError: true, message: 'Email no son iguales'});
                    this.$store.dispatch('form/submit', false)
                    return;
                    } 
                }


              /*  if(this.repassword !== null && this.password !== null){
                   if (this.repassword !== this.password.trim()) {
                    this.$store.dispatch('form/result', {isError: true, message: 'Contraseña no son iguales'});
                    this.$store.dispatch('form/submit', false)
                    return;
                    } 
                }

                 if(this.repassword !== null && this.password !== null){
                    console.log(this.repassword.length);
                   if (this.repassword.length < 7) {
                    this.$store.dispatch('form/result', {isError: true, message: 'Contraseña debe ser de largo mayor o igual a 8'});
                    this.$store.dispatch('form/submit', false)
                    return;
                    } 
                }
                */
                
                AuthService.registerUser(this.username, this.name, this.email, this.password)
                    .then(response => {
                        result.message = response.data.message;
                        if(response.data.status === 'ERROR'){
                            result.isError = true;
                            this.$store.dispatch('form/submit', false); 
                        }else{
                            if(con_login){
                                this.logIn();
                            }else{
                                this.$router.replace({name: 'home'});
                                this.$store.dispatch('form/submit', false); 
                            }
                            this.$store.dispatch('auth/set-access-token', response.data.access_token);
                            this.$store.dispatch('auth/set-refresh-token', response.data.refresh_token);
                        }
                        this.$store.dispatch('form/result', result);
                    })
                    .catch(err => {
                        console.log(err)
                        this.$store.dispatch('form/submit', false);
                      
                    });
            },
            logIn() {
                this.$store.dispatch('form/submit', true);
                this.$store.dispatch('form/error', {
                    username: false,
                    password: false,
                    credentials: false,
                });
                
                if (this.username === null || this.username.trim() === '') {
                    this.$store.dispatch('form/error', {username: true});
                }
                
                if (this.password === null || this.password.trim() === '') {
                    this.$store.dispatch('form/error', {password: true});
                }
            
                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }
               
                //this.$store.dispatch('auth/logout');
               
                AuthService.log_in(this.username, this.password)
                    .then(response => {
                      this.$store.dispatch('auth/set-access-token', response.data.access_token);
                      this.$store.dispatch('auth/set-refresh-token', response.data.refresh_token);
                       AuthService.getUserDetail(this.username, this.password)
                            .then(response2 => {
                                this.$store.dispatch('auth/set-user', this.username);
                                this.$store.dispatch('auth/set-groups', response2[0]);
                                this.$router.replace({name: 'home'}); 
                                this.$store.dispatch('form/submit', false);
                            }).catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch(err => {
                      if (err.response.status === 400) {
                        this.$store.dispatch('form/error', {credentials: true});
                        this.$store.dispatch('form/submit', false);
                      }
                    });
                    
            }
        };
        this.computed = {
            ...mapGetters({
                error: 'form/error-states'
            }),
        };
        this.components = {Backgroundcustom};
        this.watch = {
            username(newValue){
                this.username = newValue.toLowerCase();
            }
        };
        this.props = ['device'];
    }).apply(RegisterUser);

    export default RegisterUser
</script>
