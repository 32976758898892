<template>
  <div class="mt-1">


    <img v-if="!isSaving" class="z-index-0" alt="no icon" src="@/assets/rojo/playlistlista.svg" :style="{ 'icon-6x': isSmall === false, 'icon-3x': isSmall === true}"  @click="abrirModal">

    <ProgressSpinner v-if="isSaving" :style="spinnerStyle" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
 
    
    <DialogPrime :visible="mostrarModal" :modal="true" :closable="false">
      <h2>Agregar a un lista </h2>
 
      <div class="grid mt-4 mr-3 ml-2">

 
        <div class="col-12 md:col-12 lg:col-12" v-if="authenticated == false">
          <h3>No se pueden tener playlists personalizadas a menos que estés registrado.</h3>
          </div>

        <div class="col-12 md:col-12 lg:col-12" v-for="(lista, idx) in listas" :key="idx">
          <div class="grid mt-4 mr-3 ml-2" @click.prevent="seleccionarLista(lista)">
            <div class="col-2 md:col-4 lg:col-4">
              <img class="mt-2" style="width: 50px !important;" alt="icono corporativo" src="@/assets/logo.svg">
            </div>
            <div class="col-9 md:col-8 lg:col-8 ml-3">
              <p class="text-xl font-bold text-left">{{ lista.nombre }}</p>
            </div>
         </div>
        </div>
      </div>

       <div class="confirmation-buttons">
          <ButtonPrime @click="cerrar">Cerrar</ButtonPrime>
        </div>

    </DialogPrime>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import PlaylistService from '@/service/PlaylistService';

export default {
  components: {
    
  },
  props: {
    idCancion: {
      type: Number,
      required: true
    },
    isSmall: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      mostrarModal: false,
      listas: [],
      isSaving: false,
  
    };
  },
   computed: {
    spinnerStyle() {
      const size = this.isSmall ? '10px' : '50px';
      return `width: ${size} !important; height: ${size} !important;`;
    },


      ...mapGetters({
      user_id: 'auth/user_id',
      authenticated: 'auth/check',
    })
  },
  methods: {
    cerrar() {
      this.mostrarModal = false;
    },
    abrirModal() {
      this.mostrarModal = true;
      this.getListas();
    },
     seleccionarLista(lista) {
      this.mostrarModal = false;
      this.isSaving = true;
        this.$store.dispatch('form/submit', true);
      PlaylistService.guardarCancion(this.idCancion, lista.playlist_usuario_id)
          .then(response => {
              if (response.data.status === 'SUCCESS') {
                  this.$store.dispatch('form/result', {isError: false, message: response.data.message});
              } else if (response.data.status === 'ERROR') {
                  this.$store.dispatch('form/result', {isError: true, message: response.data.message});
              }
              this.isSaving = false;
              this.$store.dispatch('form/submit', false);
          })
          .catch(error => {
              console.error(error);
              this.isSaving = false;
              this.$store.dispatch('form/submit', false);
          });

    },
    getListas() {

      if(!this.authenticated)
        return
      if (this.$store.getters['song/listas'].length === 0) {
        // Si las listas no están en la caché, llama a la acción getListas
        PlaylistService.getByUser(this.user_id).then((response) => {
          this.$store.dispatch('song/set-listas', response.data); // Actualiza las listas de reproducción en el estado de Vuex
          this.listas = response.data.filter(lista => lista.nombre !== 'Favoritos');
        }).catch((error) => {
          console.log(error);
        });
      } else {
        this.listas = this.$store.getters['song/listas'];
      }
    }
  }
};
</script>

<style scoped>
.pi-plus-circle {
  cursor: pointer;
}
.icon-white {
  color: white;
}

.icon-3x {
  font-size: 3em;
}

.icon-1x {
  font-size: 1em;
}

</style>
