import axios from "axios";

export default {
    
    getMusic(url) {
        return axios.get(url)
    }, 

     addFavorite(user_id, cancion_id) {
        const url = `${process.env.VUE_APP_API_URL}/addcancionfavorite`;
        let data = {'id': user_id, "cancion_id": cancion_id }
        return axios.post(url, data)
    },

    removeFavorite(user_id, cancion_id) {
        const url = `${process.env.VUE_APP_API_URL}/removecancionfavorite`;
        let data = {'id': user_id, "cancion_id": cancion_id }
        return axios.post(url, data)
    },


}
