<template id="redirect-playlist-genero-page">
    <div class="mt-6">
        <p class="subtitle is-size-5"><strong><i class="mdi mdi-timer-sand-full has-text-white mdi-spin"></i>Cargando Playlist Genero...</strong></p>              
    </div>
</template>

<style lang="less" scoped>
    
</style>


<script>
   // import {mapGetters} from 'vuex';

    let RedirectPlaylistGenero = {};
    (function () {
        this.name = 'redirect-playlist-genero';
        this.created = function () {
                
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$router.push({
                    name: 'playlist',
                    params: {
                         id :this.$route.params.id,
                         name : this.$route.params.name,
                         img : this.$route.params.img,
                         open_from_player: this.$route.params.open_from_player,
                         daytimeid: this.$route.params.daytimeid,
                         daytime_nombre: null,
                    }
                });
           
        };

        this.data = function () {
            return {
              
            }
        };
        this.methods = {
           
        };
        this.computed = {
           
             //...mapGetters({
                //current_cap: 'auth/current-capitulo'
            //})
        };
        this.watch = {

        };
        this.components = {};
        
        this.props = ['device'];
    }).apply(RedirectPlaylistGenero);

    export default RedirectPlaylistGenero
</script>
