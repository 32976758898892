<template>
    <div v-if="showMessage" style="z-index: 19999 !important; margin-top: 40px; position:fixed; right: 5px;">
        <br /><br /><br />
        <message-prime :key="messageKey" v-if="!result.isError" severity="success">{{localMessage}}</message-prime> 
        <message-prime :key="messageKey" v-else severity="error">{{localMessage}}</message-prime> 
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    let ResultMessage = {};
    (function () {
        this.name = 'resultmessage-component';
        this.created = function () {};
this.data = function () {
    return {
        showMessage: false,
        localMessage: null,
        messageKey: 0,
        timer: null,
    }
};
        this.methods = {
        };
        this.mounted = function () {};
        this.components = {}
        this.computed = {
            ...mapGetters({
                result: 'form/result',
            })
        };
        this.watch = {
    'result.message': function (newValue) {
        if (newValue) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.showMessage = false;
            this.localMessage = null;
            this.localMessage = newValue;
            this.showMessage = true;
            this.messageKey++;
            let _self = this;
            this.timer = setTimeout(function () {
                _self.showMessage = false;
                _self.localMessage = null;
            }, 7000);
        }
    }
};

        this.props = [];
    }).apply(ResultMessage);
    export default ResultMessage;
</script>
