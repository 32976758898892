import axios from "axios";

export default {
    
    all() {
        const url = `${process.env.VUE_APP_API_URL}/alldaytimes`;
        let data = {'id': null}
        return axios.post(url, data)
    },


}
