<template>
    <span>
    <ProgressBar  class="z-index-5" :showValue="false" style="height: 0.5px  !important; background-color: #FAFBE4; z-index: 99999; top: 80px; left: 0; position: fixed; right: 0; " mode="indeterminate"/>
     <backgroundcustom :fondo="0" :device="device"></backgroundcustom>

    </span>
</template>

<script>
     import Backgroundcustom from '@/components/Backgroundcustom'

    let EmptyComponent = { };
    (function () {

        this.name = 'loader-component';

        this.created = function () {
            
        };
        this.data = function () {
            return {
                 
            }
        };
        this.methods = {
            
        };
        this.mounted = function () {};
        this.components = {Backgroundcustom}
        this.computed = {};
        this.watch = {};
        this.props = ['device'];
    }).apply(EmptyComponent);
    export default EmptyComponent;
</script>


<style scoped>
  
    .loader-container {
        position: fixed;
        z-index: 1000;
        background-color: transparent;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        flex-direction: column;
        div {
            text-align: center;
        }
    }

    @keyframes p-progress-spinner-color {
        100%,
        0% {
            stroke: white;
        }
        40% {
            stroke: yellow;
        }
        66% {
            stroke: yellow;
        }
        80%,
        90% {
            stroke: red;
        }
}
</style>

