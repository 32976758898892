<template>
  <span>
    <img
      class="share-icon"
      alt="Compartir"
      src="@/assets/rojo/compartir.svg"
      @click.prevent="share"
    />
  </span>
</template>

<script>
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
  import {mapGetters} from 'vuex';

export default {
  name: 'ShareComponent',
  data() {
    return {
      info: null,
       radio:{'name': null,
                            'url_instagram': null,
                            'url_web': null,
                            'url_compartir_ios': null,
                            'url_compartir_android': null,
                },
    };
  },
  methods: {
    async getDeviceInfo() {
      try {
        const info = await Device.getInfo();
        this.info = info;
      } catch (error) {
        console.error(error);
        this.$store.dispatch('form/submit', false);
      }
    },
    async copyURL(mytext) {
                try {
                  await navigator.clipboard.writeText(mytext);
                  this.$store.dispatch('form/result', {isError: false, message: 'Comparte el mensaje copiado'});
                } catch($e) {
                  this.$store.dispatch('form/result', {isError: true, message: 'No pude copiar'});
                }
            },
             share(){
                var url_t = process.env.VUE_APP_SHARE_BASE;
                if(this.info.platform == 'web'){
                    this.copyURL('Te invito a escuchar la mejor programación en RADIO ACONCAGUA. Realiza la descarga en GOOGLE PLAY y AppStore. Android:' + this.radio.url_compartir_android + '. IOS:' + this.radio.url_compartir_ios);
                }else{
                    Share.share({
                      title: 'Radio ' + process.env.VUE_APP_RADIO,
                      text: 'Te invito a escuchar la mejor programación en RADIO ACONCAGUA. Realiza la descarga en GOOGLE PLAY y AppStore. Android:' + this.radio.url_compartir_android + '. IOS:' + this.radio.url_compartir_ios,
                      url: url_t,
                      dialogTitle: 'Invitacion',
                    }).then(response => {
                            console.log(response)
                        })
                        .catch(err => {
                           console.log(err)
                            this.$store.dispatch('form/submit', false);
                    });
                }
            },
  },

  mounted() {
    this.radio = this.radio_info;
    this.getDeviceInfo();
  },

  computed:{
    ...mapGetters({
      radio_info: 'radio/radio',
    })
  }

};
</script>

<style>
.share-icon {
  width: 25px;
  height: 25px;
}
</style>
