<template id="home">
    <span v-if="getEstaciones != null">

        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

         <SubMenu :info="info"></SubMenu>

       <span>
        <p class="text-white text-6l font-bold">ESTACIONES DE RADIO  
            <span v-if="radio !== null">
                <img class="z-index-0" alt="Share icon" src="@/assets/rojo/compartir.svg" style="width: 30px; height: 30px;" @click.prevent="share()">
            </span>
        </p>
        <div class="card d-flex flex-column align-items-center justify-content-center">
            <div class="grid mt-0 mr-2 ml-2 text-center g-0">

                <div class="col-6 md:col-3 lg:col-2" v-for="(station, idx) in current_estaciones" :key="idx" style="padding: 4px !important;">
                    <img class="rounded-img" :src="st+station.nuevo_poster" alt="Station poster" style="width:100%;" @click.prevent="goToRadio(station.estacion_id)">
                </div>

            </div>
        </div>
    </span>

    <OpcionesMusica></OpcionesMusica>


         <dialog-prime header="Registrate" v-model:visible="showListasDialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :position="'Bottom'" :modal="true">
            <p class="m-0">No se pueden tener playlists personalizadas a menos que estés registrado.</p>
                
            <template #footer>
                <button-prime class="w-7rem p-button-link" label="Ingresar" @click.prevent="goTo('login')" style="font-weight: 800; color: #aa00ff;"/>
                <button-prime class="w-7rem p-button-link" label="Registrarse" @click.prevent="goTo('register-user')" style="font-weight: 800; color: #aa00ff;"/>
            </template>
        </dialog-prime>


            <dialog-prime header="Recibiste una Invitación" v-model:visible="getMostrarModalShare" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :position="'Bottom'" :modal="true">
            <p class="m-0">Te invitaron a escuchar una playlist. Se comenzara a reproducir automaticamente.</p>
            <template #footer>
                <button-prime label="No" icon="pi pi-times" @click="noReproducirPlaylist()"   class="p-button-text"/> 
                <button-prime label="Si" icon="pi pi-check" @click="reproducirPlaylist()"  autofocus/>
            </template>
        </dialog-prime>


        <dialog-prime header="Recibiste una Invitación" v-model:visible="getMostrarModalShareEstacion" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :position="'Bottom'" :modal="true">
            <p class="m-0">Te invitaron a escuchar nuestra Estacion Radial.</p>
            <template #footer>
                <button-prime label="No" icon="pi pi-times" @click="noReproducirEstacion()"  class="p-button-text"/> 
                <button-prime label="Si" icon="pi pi-check" @click="reproducirEstacion()"  autofocus/>
            </template>
        </dialog-prime>

        
    </span>
</template>

<style>


.rounded-img {
    border-radius: 5px; /* Rounds the corners of the images */
}

.text-white-entrance {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 2s forwards 0.5s, shimmer 1.5s 1 3s, setColor 0.1s forwards 4.5s;
    color: #ff0e0e;
    font-family: Arial, sans-serif;
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

@keyframes setColor {
    to {
        color: white;
    }
}

.shimmer {
    background: linear-gradient(90deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.2) 75%);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}


    .carousel-indicators a {
        width: 19px;
        height: 9px;
        background-color: white;
        z-index: 100;
        position: fixed; 
        bottom: 80px !important;
        border-radius: 2%;
        margin-left: -1%;

    }
    .is-active{
        background-color: grey !important;
    }
    .width-mobile{
        width: 30% !important;
    }
    .width-tablet{
        width: 55% !important;
    }
    .width-desktop{
        width: 35% !important;
    }
    .margin-top-mobile{
         top: 10% !important;

    }
    .margin-top-tablet{
         top: 50% !important;
         left: 5% !important;
    }
    .margin-top-desktop{
         top: 10% !important;
         left: 33% !important;
    }
    .margin-top-50-desktop{
         top: 50% !important;
         left: 35% !important;
    }
</style>

<script>
    
    import { InAppBrowser } from '@ionic-native/in-app-browser'
    import { Device } from '@capacitor/device';
    import Backgroundcustom from '@/components/Backgroundcustom'
    import SubMenu from '@/components/SubMenu'
    import OpcionesMusica from '@/components/OpcionesMusica'
    import RadioService from '@/service/RadioService';
    import {mapGetters} from 'vuex';
    import AuthService from '@/service/AuthService';
    import CancionesService from '@/service/CancionesService';
    import { Share } from '@capacitor/share';

    import { Capacitor } from '@capacitor/core';
    import { BatteryOptimization } from '@capawesome-team/capacitor-android-battery-optimization';

    import { ForegroundService } from '@capawesome-team/capacitor-android-foreground-service';

    import 'animate.css';
    
    let HomePage = {};
    (function () {
        this.name = 'home-page';
        this.created = function () {

            Device.getInfo().then((info) => {
                    this.info = info

                    if(this.info.platform == 'android'){

                           this.$store.dispatch('form/submit', true);

                              /*  ForegroundService.startForegroundService(
                                      {body: 'Estas escuchando radio '+ process.env.VUE_APP_RADIO,
                                      title: 'Radio' + process.env.VUE_APP_RADIO,
                                      id: 9999999,
                                      smallIcon: './favicon.ico',
                                    }).then(() => {
                                    if (Capacitor.getPlatform() !== 'android') {
                                        return;
                                      }
                                }).catch((error) => {
                                    console.log(error);
                                    this.$store.dispatch('form/submit', false);
                                }); */

                                this.$store.dispatch('form/submit', true);

                                 BatteryOptimization.isIgnoringBatteryOptimizations().then((response) => {
                                    if (!response.isIgnoring) {
                                        BatteryOptimization.requestIgnoreBatteryOptimization().then(() => {
                                            if (Capacitor.getPlatform() !== 'android') {
                                                console.log('Saliendo');
                                                return;
                                            }
                                        }).catch((error) => {
                                            console.log(error);
                                            this.$store.dispatch('form/submit', false);
                                        });
                                    }
                                }).catch((error) => {
                                    console.log('Error checking battery optimization: ', error);
                                });


                    }


                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            const id_estacion = this.$route.query["id"];
            if(id_estacion != 0 && id_estacion != null){
                this.mostrarModalShareEstacion = true;
            }else{
                const short_id = this.$route.query["short"];
                if(short_id){
                    this.mostrarModalShare = true;
                }
            }

           if(this.radio_lost == true){
                this.goToRadio(this.current_radio);
            }

            if(this.authenticated == true){
          
                    if(this.user_id == null || this.user_id == undefined)
                        this.getUser();
                    else
                        this.getFavoriteUSer(this.user_id);

                    if(this.radio_info == null || this.radio_info == undefined){
                       this.getRadioInfo(); 
                   }else{
                        this.radio = this.radio_info
                   }
                    
                    if(this.estaciones == null || this.estaciones == undefined){
                        this.getEstacionesInfo();
                    }
                    else{
                        this.current_estaciones = this.estaciones;
                    }

                    if(this.favoritos == null || this.favoritos == undefined)
                        this.getSongsPlaylist('Favoritos');

            }else{
                this.getRadioInfo();
                this.getEstacionesInfo();
            }

             this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.mounted  = function () {
          
        };

        this.data = function () {
            return {
                info: null,
                current_estaciones: [],
                radio:{'name': null,
                            'url_instagram': null,
                            'url_web': null,
                            'url_compartir_ios': null,
                            'url_compartir_android': null,
                },
                st: process.env.VUE_APP_STATIC,
                mostrarModalShare: false,
                mostrarModalShareEstacion: false,
                showListasDialog: false,
            }
        };
        this.methods = {
            goToListas(){

                if(this.authenticated == false){
                    this.showListasDialog = true;
                }else{
                    this.goTo('listas');
                }

                
            },
            getImageSrc(name) {
                  if (name === 'Adulto') {
                    return require('@/assets/adulto-nuevo.png');
                  } else if (name === 'Juvenil') {
                    return require('@/assets/juvenil-nuevol.png');
                  } else if (name === 'Infantil') {
                    return require('@/assets/infantil-nuevo.png');
                  } else {
                    return '';
                  }
                },
            reproducirEstacion(){
                this.goToRadio(this.$route.query["id"]);
                this.mostrarModalShareEstacion = false;
            },
            noReproducirEstacion(){
                this.mostrarModalShareEstacion = false;
            },
            reproducirPlaylist(){
                this.$store.dispatch('form/submit', true);
                    RadioService.getShortUrlByid(this.$route.query["short"]).then((response) => {

                        const id_g = response.data.genero_id
                        const name_g = response.data.genero_nombre;
                        const portada_g = response.data.portada_genero

                        const id_a = response.data.artista_id
                        const type_a = response.data.tipo_arista_nombre;
                        const name_a = response.data.artista_nombre
                        const port_a = response.data.artista_portada

                        const daytimeid_q = response.data.daytimeid_q
                        const openFromPlayer_q = false;
                        const horario_q =  response.data.horario_q

                        if(id_g && name_g && portada_g){
                            this.$router.push({name: 'playlist',
                               params: {
                                    id: id_g,
                                    name: name_g,
                                    img: portada_g,
                                    daytimeid: daytimeid_q,
                                    openFromPlayer: false,
                                    dasytime_nombre: horario_q,
                             
                                }
                           });
                        }
                        
                        if(id_a && type_a && name_a && port_a){
                             this.$router.push({name: 'artist-songs',
                               params: {
                                    artist_id: id_a,
                                    artist_type_name: type_a,
                                    img: port_a,
                                    artist_name: name_a,
                                    open_from_player: false,
                                    daytimeid: daytimeid_q,
                                    daytime_nombre: horario_q,
                               
                                }
                            });
                        }
                        
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            noReproducirPlaylist(){
                this.mostrarModalShare = false;
            },
            async copyURL(mytext) {
                try {
                  await navigator.clipboard.writeText(mytext);
                  this.$store.dispatch('form/result', {isError: false, message: 'Comparte el mensaje copiado'});
                } catch($e) {
                  this.$store.dispatch('form/result', {isError: true, message: 'No pude copiar'});
                }
            },
             share(){
                var url_t = process.env.VUE_APP_SHARE_BASE;
                if(this.info.platform == 'web'){
                    this.copyURL('Te invito a escuchar la mejor programación en RADIO ACONCAGUA. Realiza la descarga en GOOGLE PLAY y AppStore. Android:' + this.radio.url_compartir_android + '. IOS:' + this.radio.url_compartir_ios);
                }else{
                    Share.share({
                      title: 'Radio ' + process.env.VUE_APP_RADIO,
                      text: 'Te invito a escuchar la mejor programación en RADIO ACONCAGUA. Realiza la descarga en GOOGLE PLAY y AppStore. Android:' + this.radio.url_compartir_android + '. IOS:' + this.radio.url_compartir_ios,
                      url: url_t,
                      dialogTitle: 'Invitacion',
                    }).then(response => {
                            console.log(response)
                        })
                        .catch(err => {
                           console.log(err)
                            this.$store.dispatch('form/submit', false);
                    });
                }
            },
            getSongsPlaylist(name){
                this.$store.dispatch('form/submit', true);
                   CancionesService.getCancionesByNamePLayList(name, this.user_id).then((response) => {
                     
                            if(response.data.length > 0){
                                 this.$store.dispatch('radio/set-favoritos', response.data);
                            }
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            getUser(){
                this.$store.dispatch('form/submit', true); 

                AuthService.getUser(this.username)
                    .then(response => {
                        //result.message = response.data.message;
                        if(response.data.status === 'ERROR'){
                            result.isError = true;
                        }else{
                            this.$store.dispatch('auth/set-user-id', response.data.data.id);
                            this.getFavoriteUSer(response.data.data.id);
                            this.$store.dispatch('form/submit', false); 
                        }
                    })
                    .catch(err => {
                       console.log(err)
                        this.$store.dispatch('form/submit', false);
                });
            },
             
             getFavoriteUSer(id){
                this.$store.dispatch('form/submit', true);
                   CancionesService.getFavoriteUSer(id).then((response) => {
                        this.$store.dispatch('song/set-favovites', response.data);
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },

            getRadioInfo(){
                this.$store.dispatch('form/submit', true);
                   RadioService.getRadioByKey().then((response) => {
                        this.radio = response.data;
                        this.$store.dispatch('radio/set-radio', response.data);
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            getEstacionesInfo(){
                this.$store.dispatch('form/submit', true);
                   RadioService.getStationsByRadioKeyNEW().then((response) => {
                        this.current_estaciones = response.data;
                        this.$store.dispatch('radio/set-estaciones', response.data);
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },

            goToRadio: function (id) {
                this.$store.dispatch('radio/set-current', id);
                this.$store.dispatch('radio/set-lostconect', false)
                this.$router.push({name: 'radio',
                   params: {
                        id: id
                    }
               });
            },
            goTo: function (ruta) {
                this.$router.push({name: ruta
               });
            },
            
        };
        this.components = {Backgroundcustom, SubMenu, OpcionesMusica};
        this.computed = {
            getMostrarModalShareEstacion(){
                return this.mostrarModalShareEstacion;
            },
            getMostrarModalShare(){
                return this.mostrarModalShare;
            },
            getEstaciones(){
                return this.current_estaciones;
            },
            ...mapGetters({
                 estaciones: 'radio/estaciones',
                 radio_info: 'radio/radio',
                 username: 'auth/username',
                 is_radio: 'song/is_radio',
                 songPlaying: 'song/current',
                 radio_lost: 'radio/lost_conection',
                 current_radio: 'radio/current',
                 authenticated: 'auth/check',
                 favoritos: 'radio/favoritos',
                 user_id: 'auth/user_id',
            })
        };
        this.watch = {
            estaciones(){
                this.current_estaciones = this.estaciones;
            },
        };
        

        this.props = ['device'];
    }).apply(HomePage);

    export default HomePage
</script>


