<template id="home">
    <span>
         <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

           
        <TituloPage :nombre="'DJS'" @back="back()">
            <template v-slot:share>
             <share-component></share-component>
            </template>
        </TituloPage>
        
        <p class="text-white text-3l font-bold">Los Mejores djs.</p>


 <div class="grid mt-3 ml-2 mr-2" v-if="!cargando">
    <div v-for="type in artistTypesssss" :key="type.tipo_artista_id" class="col-6 sm:col-4 lg:col-3 p-2">
        <ButtonPrime :label="type.nombre" @click="filterByType(type.tipo_artista_id)" class="p-button-rounded p-button-info w-full" />
    </div>
</div>


<ProgressBar v-if="cargando"  class="z-index-5" :showValue="false" style="height: 0.5px  !important; background-color: #FAFBE4; z-index: 99999; top: 80px; left: 0; position: fixed; right: 0; " mode="indeterminate"/>
     <backgroundcustom :fondo="0" :device="device"></backgroundcustom>

<div class="grid mt-3 ml-2 mr-2">
    <div v-for="(d, idxx) in filteredArtists" :key="idxx" @click.prevent="openArtistSongs(d, selectedType)" class="col-4 sm:col-2 lg:col-1 p-2">
        <div class="card">
            <ImageFallback v-if="d.foto_artista != null"
                :imageSource="d.foto_artista"
                :fallbackSrc="'@/assets/logo.svg'"
                :alt="'noico'"
                :class="'z-index-0'"
                :width="null"
                :maxHeight="'160px'"
            />
            <ImageFallback v-else
                :imageSource="'@/assets/logo.svg'"
                :fallbackSrc="'@/assets/logo.svg'"
                :alt="'noico'"
                :class="'z-index-0'"
                :width="null"
                :maxHeight="'160px'"
            />
            <p class="text-white font-bold text-xs">{{ d.nombre }} {{ d.apellido }}</p>
        </div>
    </div>
</div>

         <side-bar v-model:visible="visibleRight" :baseZIndex="10000" position="right" class="bg-primary-cus" :showCloseIcon="false">
            <h3 style="color: white !important;">{{this.artistTypeSelected.nombre}}</h3>
             <div class="grid">
                <div class="col-12 md:col-12 lg:col-12"  v-for="(c, idx) in artists" :key="idx" >
                    <span @click.prevent="openArtistSongs(c)" class="text-white font-bold" style="color: white !important;">
                         {{c.nombre}} {{c.apellido}}</span>
                    <!--span class="text-white font-bold" style="color: #0a0850 !important;">-{{c.name}}</span-->
                </div>
            </div>
 </side-bar>
<br /><br /><br /><br /><br /><br /><br /><br /><br />
    </span>     
</template>

<style>
        .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
     color: black !important;
}

.neon-border-bottom {
    position: relative;
    border-bottom: solid white !important;
}

.neon-border-bottom::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: white;
    box-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 40px white;
}
</style>

<script>

    import Backgroundcustom from '@/components/Backgroundcustom';
    import ArtistService from '@/service/ArtistService';

    import {mapGetters} from 'vuex';
    import ImageFallback from '@/components/ImageFallback.vue';

    import ShareComponent from '@/components/ShareComponent';
    import TituloPage from '@/components/TituloPage';

    import { mapState, mapActions } from 'vuex';
    
    
    let SelectGenero = {};
    (function () {
        this.name = 'home-page';
        // let _self = null;
           
        this.created = function () {


            if(this.djs_cargados == null){
                this.getAlltipoArtista();
            }else{
                this.artistType = this.djs_cargados;
                this.artistTypesssss = this.djs_cargados.filter(type => type.tipo.es_dj === 1)
                        .map(type => {
                            return {
                            tipo_artista_id: type.tipo.tipo_artista_id,
                            nombre: type.tipo.nombre,
                            portada: type.tipo.portada,
                            artistas: type.artistas
                            };
                        });

                if(this.djs_tipo != null){
                    this.filterByType(this.djs_tipo[0].tipo_arista_id);
                }
            }
             
           
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
                selectedDayTime: {'tiempo_dia_id': -1, 'nombre': null},
                artistType: [],
                artistTypeSelected: null,
                artists: [],
                st: process.env.VUE_APP_STATIC,
                visibleRight: false,
                filteredArtists: [],
                cargando: false,
                artistTypesssss: [],
            }
        };
        this.methods = {
            filterByType(typeId) {
                console.log("Filtrando por tipo:", typeId);
                // Acceder a la propiedad 'tipo' dentro de cada objeto en artistType
                this.selectedType = this.artistType.find(type => type.tipo.tipo_artista_id === typeId);
                if (this.selectedType) {
                    console.log("Tipo seleccionado:", this.selectedType.tipo);
                    this.filteredArtists = this.selectedType.artistas;
                    console.log("Artistas filtrados:", this.filteredArtists);
                    this.$store.dispatch('djs/set-seleccionado', this.filteredArtists);

                } else {
                    console.log("No se encontró el tipo con ID:", typeId);
                    this.filteredArtists = [];
                }
            },

            openArtistSongs(a, tipo){
                this.visibleRight = false;
                var at_name = a.nombre
                if(a.apellido)
                var at_name = a.nombre + ' ' + a.apellido

                var port = 0
      
                if(tipo.portada !== "")
                    port = tipo.portada;

                var selec_id = -1
                var nom_day_time = -1;


                this.$router.push({name: 'artist-prev-songs',
                   params: {
                        artist_id: a.artista_id,
                        artist_type_name: tipo.nombre,
                        img: port,
                        artist_name: at_name,
                        open_from_player: false,
                        daytimeid: selec_id,
                        daytime_nombre: nom_day_time,
                    }
               });

            },
            setVisibleModal(){
                console.log('visible');
                this.visibleRight = true;
            },

    
    getAlltipoArtista () {
        this.cargando = true;

        ArtistService.allArtistTypeAndArtist2().then((response) => {

            
            this.artistType = response.data;
         this.$store.dispatch('djs/set-djs', response.data);
            this.$store.dispatch('form/submit', false);
                
        this.artistTypesssss = response.data
            .filter(type => type.tipo.es_dj === 1)
            .map(type => {
                return {
                tipo_artista_id: type.tipo.tipo_artista_id,
                nombre: type.tipo.nombre,
                portada: type.tipo.portada,
                artistas: type.artistas
                };
            });

            this.cargando = false;
        }).catch(error => {
            console.error('Error fetching artist types:', error);
            this.cargando = false; // Hide loading indicator
        });
    },

      back() {
            this.$router.push({name: 'home'});
        },
        
        goTo: function (route, id, name, url) {
            this.$router.push({name: route,
                params: {
                    id: id,
                    name: name,
                    img: url,
                }
            });
        }
        };
        this.components = {Backgroundcustom, ImageFallback, ShareComponent, TituloPage};
        this.computed = {
               ...mapGetters({
                djs_cargados: 'djs/djs',
                djs_tipo:'djs/seleccionado'

            })
        };

        

        this.props = ['device'];
    }).apply(SelectGenero);

    export default SelectGenero
</script>
