<template>
    <span v-if="authenticated == true">
        <span v-if="min==true">
            <img v-if="isFavorite(cancion_id) == false" @click.prevent="addFavorite(cancion_id)" class="z-index-0 custom-background-class ml-3 mt-2" alt="no icon" src="@/assets/estrella.png" style="height: 20px !important;">
            <img v-if="isFavorite(cancion_id) == true " @click.prevent="removeFavorite(cancion_id)" class="z-index-0 custom-background-class ml-3 mt-2" alt="no icon" src="@/assets/estrella-ne.png" style="height: 20px !important;">
        </span>
        <span v-else>
            <img v-if="isFavorite(cancion_id) == false" @click.prevent="addFavorite(cancion_id)" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/estrella.png" style="height: 25px !important;">
            <img v-if="isFavorite(cancion_id) == true " @click.prevent="removeFavorite(cancion_id)" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/estrella-ne.png" style="height: 25px !important;">
        </span>
    </span>
</template>

<script>
    import {mapGetters} from 'vuex';
    import PlayerService from '@/service/PlayerService';

    let Favorite = {};
    (function () {
        this.name = 'favorite-component';
        this.created = function () {};
        this.data = function () {
            return {}
        };
        this.methods = {
            isFavorite(id){
                if (this.fav.find(item => item === parseInt(id))){
                     return true;
                 }else{ return false;}
            },
            removeFavorite(cancion_id){
                this.$store.dispatch('form/submit', true);
                this.$store.dispatch('radio/set-agrege-fav', true);
                   PlayerService.removeFavorite(this.user_id, cancion_id).then((response) => {
                        this.$store.dispatch('song/set-favovites', []);
                        this.$store.dispatch('song/set-favovites', response.data);
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            addFavorite(cancion_id){
                this.$store.dispatch('form/submit', true);
                   PlayerService.addFavorite(this.user_id, cancion_id).then((response) => {

                        if(response.data.status == 'ERROR'){
                            this.$store.dispatch('form/result', {isError: true, message: response.data.message});
                        }else{
                            this.$store.dispatch('song/set-favovites', []);
                            this.$store.dispatch('radio/set-agrege-fav', true);
                            this.$store.dispatch('song/set-favovites', response.data.data);
                        }

                        this.$store.dispatch('form/submit', false);
                        
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
        };
        this.mounted = function () {};
        this.components = {}
        this.computed = {
             ...mapGetters({
                 user_id: 'auth/user_id',
                 fav: 'song/favovites',
                 authenticated: 'auth/check',
            }),
        };
        this.watch = {
            
        };
        this.props = ['cancion_id', 'min'];
    }).apply(Favorite);
    export default Favorite;
</script>

<style scoped>
    
</style>
