import axios from "axios";

export default {
    
    getProgramation() {
        const url = `${process.env.VUE_APP_API_URL}/getprogramationstation`;
        let data = {'key': process.env.VUE_APP_KEY_RADIO}
        return axios.post(url, data)
    },

}
