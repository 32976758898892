<template>
    <nav class="navbar is-fixed-bottom z-5" role="navigation" aria-label="main navigation" style="position:fixed;">
        <div class="has-text-centered h-5rem">
            <div class="grid">

                <div class="col-4 mt-2" :class="{'bordet-top-now': selectedRoute == 'home'}" @click.prevent="goTo('home')">
                    <img  class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/homeicon.svg" style="height: 70px !important;">
                </div>

                <div v-if="true" class="col-4 mt-2" :class="{'bordet-top-now': selectedRoute == 'select-artist-type'}" @click.prevent="goToArtistSelection('buscar-cancion')">
                    <img  class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/buscaricon.svg" style="height: 70px !important;">
                </div>

                <div class="col-4 mt-2" :class="{'bordet-top-now': selectedRoute == 'favorite'}" @click.prevent="openSideBar()">
                    <img  class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/menuicon.svg" style="height: 70px !important;">
                </div>

            </div>
        </div>

            <side-bar v-model:visible="visibleRight" position="left" :showCloseIcon="false" class="bg-primary-cus text-black" >
            <menu-prime :model="items" class="bg-primary-cus"/>
        </side-bar>


        <dialog-prime header="Registrate" v-model:visible="showfavDialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :position="'Bottom'" :modal="true">
            <p class="m-0">Usted Puede tener Favoritos solo si esta Registrado.</p>
                
            <template #footer>
                <button-prime class="w-7rem p-button-link" label="Ingresar" @click.prevent="goTo('login-email')" style="font-weight: 800; color: #aa00ff;"/>
                <button-prime class="w-7rem p-button-link" label="Registrarse" @click.prevent="goTo('register-user')" style="font-weight: 800; color: #aa00ff;"/>
            </template>
        </dialog-prime>

    <dialog-prime v-model:visible="showOptionsPanel">
      <h3>Seleccione una opción</h3>
      <ul>
        <li><router-link to="/offline-music">Música Offline</router-link></li>
        <li><router-link to="/favorite">Favoritos</router-link></li>
      </ul>
      <button-prime label="Cerrar" @click="showOptionsPanel = false"/>
    </dialog-prime>


    </nav>
</template>

<script>
    import {mapGetters} from 'vuex';
     import GenerosService from '@/service/GenerosService';

    export default {
        name: "bottom-bar",

        data () {
                return {
                    disablingButton: false,
                    selectedRoute: null,
                    showfavDialog: null, 
                    showOptionsPanel: false,
                    visibleRight: false,
                     items: [
                    {
                        label: 'Radio ' + process.env.VUE_APP_RADIO,
                        items: [{label: 'Inicio', icon: 'pi pi-hm pi-home', 
                        command: () => {this.$router.push({name: 'home'}); this.visibleRight = false;
                            }
                        },
                        {label: 'Offline', icon: 'pi pi-hm pi-home', 
                        command: () => {this.$router.push({name: 'offline-music'}); this.visibleRight = false;
                            }
                        },
                        {label: 'Perfil', icon: 'pi pi-hm pi-user', 
                        command: () => {this.$router.push({name: 'user-profile'}); this.visibleRight = false;
                            }
                        }
                        ]
                    },
                    {
                        label: 'Estaciones',
                        items: [
                        {label: 'Adulto', icon: 'pi pi-fw pi-plus',  command: () => 
                            {   
                                this.goToRadio(1);
                            }
                        },
                        {label: 'Juvenil', icon: 'pi pi-fw pi-plus',  command: () => {this.goToRadio(2);} },
                        {label: 'Infantil', icon: 'pi pi-fw pi-plus', command: () => {this.goToRadio(3);} },
                        {label: 'Latina', icon: 'pi pi-fw pi-plus', command: () => {this.goToRadio(10);} },
                        ]
                    },
                    {
                        label: 'Artistas',
                        items: [

                            {label: 'DJ´s', icon: 'pi pi-fw pi-plus',  command: () => {this.$router.push({name: 'djs'});
                            this.visibleRight = false;} },
                        
                           {label: 'Generos', icon: 'pi pi-fw pi-plus',  command: () => {this.$router.push({name: 'select-genero'});
                            this.visibleRight = false;} },

                            {label: 'Momentos', icon: 'pi pi-fw pi-plus',  command: () => {this.goToMoment('redirect-select-moment');
                            this.visibleRight = false;} },

                            {label: 'Artistas', icon: 'pi pi-fw pi-plus',  command: () => {this.$router.push({name: 'all-time-artist'});
                            this.visibleRight = false;} },

                            {label: 'Sesiones en Vivos', icon: 'pi pi-fw pi-plus',  command: () => {this.$router.push({name: 'sesiones'});
                            this.visibleRight = false;} },

                            {label: 'Podcast', icon: 'pi pi-fw pi-plus',  command: () => {this.$router.push({name: 'podcast'});
                            this.visibleRight = false;} },

                        ]
                    },
                     {
                        label: 'Sistema',
                        items: [

                             {label: 'Salir', icon: 'pi pi-fw pi-power-off',  command: () => {this.$router.push({name: 'logout'});
                            this.visibleRight = false;} },

                        ]
                    },
                ],  
                }
            },

        computed:{
                isDisablingBottom(){
                    return this.disablingButton;
                },
                ...mapGetters({
                 authenticated: 'auth/check'
                }),
            },

        methods: {
            goToRadio(id){
                this.$store.dispatch('radio/set-current', id);
                this.$store.dispatch('radio/set-lostconect', false)
                this.$router.push({name: 'redirect-radio',params: {id: id}});
                this.visibleRight = false;
            },
             goToRedirectPlayList: function (id, name, url) {
                this.$router.push({name: 'redirect-playlist-genero',
                   params: {
                        id: id,
                        name: name,
                        img: url,
                         open_from_player:false,
                        daytimeid: -1,
                        daytime_nombre: null,
                    }
               });
            },
             getAllGeneros(){
               GenerosService.all().then((response) => {
                    this.generos = response.data;
                    this.items[3].items = [];
                    for (var i = 0; i < response.data.length; i++) {
                        var item = {  label: response.data[i]['nombre'], 
                                        id_genero: response.data[i]['genero_id'], 
                                        porta: response.data[i]['portada'],
                                        thisss: this,
                                        icon: 'pi pi-fw pi-plus',  
                                        command: function(){
                                            this.thisss.visibleRight = false;
                                            this.thisss.goToRedirectPlayList(this.id_genero, this.label, this.porta)
                                        }
                                    };
                        this.items[3].items.push(item);
                    }
                }).catch((error) => {
                    console.log(error);
                    
                });
            },
              openSideBar(){
                this.visibleRight = true;
                //this.getAllGeneros();
            },
            showOptionsPanelClick(){
                this.showOptionsPanel = true;
            },
            goToHome(){
                this.$router.push({name: 'home'});
            },
            goToMoment(route){
                this.$router.push({name: route, params: {moment: true, daytimeid: -1, daytime_nombre: null}});
            },

            goToArtistSelection(route){
                this.$router.push({name: route, params: {moment: false, daytimeid: -1, daytime_nombre: null}});
            },

            goTo (route) {
                if((route == 'favorite' && this.authenticated == false) || (route == 'listas' && this.authenticated == false)){
                    this.showfavDialog = true;
                }else{
                    this.selectedRoute = route;
                    this.$router.push({name: route});
                }
            },
            scrollUp () {
               window.scrollTo(0, 0); 
            }
        }

    }
</script>

<style scoped>
    .navbar > div {
        padding: 0rem 0 0rem 0;
        height: auto;
        background-color: black;
        color: white;
        opacity: 1;
        width: 100%;
        position: fixed; 
        bottom: 0;
        left: 0;
        right: 0;
        strong {
            color: white;
        }
    }

    .bordet-top-now{
        border-top: solid white !important;
    }
</style>
