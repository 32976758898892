import axios from "axios";

export default {
    
    getall(id) {
        const url = `${process.env.VUE_APP_API_URL}/geallpodcast`;
        let data = {'key': process.env.VUE_APP_KEY_RADIO}
        return axios.post(url, data)
    },


}
