<template id="noticias">
    <span>
        <backgroundcustom :fondo="0" :device="device"></backgroundcustom>

        <p class="text-7l font-bold text-white">NOTICIAS</p>

        <div class="card" v-if="newsType">
            <div class="line-height-3 card-container blue-container">
                <div class="overflow-y-auto surface-overlay border-blue-500 border-1 border-round m-3" style="height: 130px; background-color: transparent !important;">
                        <div class="card">
                            <div class="grid mt-4 mr-3 ml-2 overflow-y-auto" style="text-align: center !important;">
                                <div class="col-4 md:col-2 lg:col-1" @click.prevent="selectTipoNow(-1)">
                                    <img :class="{'is-active-custom': -1 == selected_tipo_id}" class="z-index-0" alt="no icon" src="@/assets/logo-radio.png" style="width:20%;">
                                     <p  class="text-1xl font-bold text-white">Ultimas</p>
                                </div>

                                <div class="col-4 md:col-2 lg:col-1" v-for="(g, idx) in newsType" :key="idx" @click.prevent="selectTipoNow(g.tipo_noticia_id)">
                                    <img :class="{'is-active-custom': g.tipo_noticia_id == selected_tipo_id}" class="z-index-0" alt="no icon" :src="st+g.poster" style="width:20%;">
                                     <p  class="text-1xl font-bold text-white">{{g.nombre}}</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>

        <div class="grid mt-4 mr-3 ml-2" v-if="news.length > 0">
            <div class="col-12 md:col-4 lg:col-4" v-for="(g, idx) in news" :key="idx"  style="border-bottom: solid white !important;">
                <span @click.prevent="openModalNoticia(g)">
                <img v-if="g.imagen_principal != ''" class="z-index-0 ml-2" alt="no icon" :src="st+g.imagen_principal" style="width: 25%;box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;">
                <img v-else class="z-index-0" alt="no icon" src="@/assets/logo-radio.png" style="width:25%;">
                <p class="text-white text-6l font-bold">{{g.nombre}}</p>
                <p class="text-white text-7l font-bold">{{g.descripcion_corta}}. <span>Mas..</span></p>
                <p class="text-white text-7l font-bold">Publicacion: {{g.fecha_publicacion}}</p>
                </span>
            </div>
            </div>
             <div v-else class="grid mt-4 mr-3 ml-2">
                <div class="col-12 md:col-4 lg:col-4"><p class="text-white">Sin Noticias</p></div>
            </div>

             <side-bar v-model:visible="visibleFULL" :baseZIndex="10000" position="full" :showCloseIcon="true">
                    <h3 v-if="selected_news">{{selected_news.nombre}}</h3>
                     <div class="grid">
                        <div class="col-12 md:col-12 lg:col-12">
                            <img v-if="selected_news.imagen_principal != ''" class="z-index-0 ml-2" alt="no icon" :src="st+selected_news.imagen_principal" style="width: 10%; box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;">
                            <p>Publicacion: {{selected_news.fecha_publicacion}}</p>
                            <div class="card mt-3">
                                <div class="line-height-3 card-container blue-container">
                                    <div class="overflow-auto surface-overlay p-3 border-grey-100 border-1 border-round" style="max-height: 350px;">
                                        {{selected_news.descrpion_larga}}
                                    </div>
                                </div>
                            </div>
          
                        
                        </div>
                    </div>
            </side-bar>



    </span>     
</template>

<style>
    .p-sidebar-close .p-sidebar-icon .p-link{
        margin-top: 30px !important;
    }

    .p-sidebar-close-icon .pi .pi-times{
        color: white !important;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
         margin-top: 30px !important;
    }
</style>


<script>

    import Backgroundcustom from '@/components/Backgroundcustom';
    import NewsService from '@/service/NewsService';
    import {mapGetters} from 'vuex';
    
    let News = {};
    (function () {
        this.name = 'noticias-page';
        // let _self = null;

        this.created = function () {
            this.getLastNews();
            this.getAllNewsType();
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            //this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
                selected_news: null,
                visibleFULL: false,
               news: [],
               newsType : [],
               st: process.env.VUE_APP_STATIC,
               selected_tipo_id: null,
            }
        };
        this.methods = {
            openModalNoticia(ne){
                this.selected_news =  ne;
                this.visibleFULL = true;
            },
            selectTipoNow(id){
                this.selected_tipo_id = id;
                if(id == -1){
                    this.getLastNews();
                }else{
                    this.getNewByType(); 
                }
            },
            getLastNews(){
                this.$store.dispatch('form/submit', true);
               NewsService.getLastNews().then((response) => {
                    this.news = response.data;
                     this.selected_tipo_id = -1;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getNewByType(){
                this.$store.dispatch('form/submit', true);
               NewsService.getNewByType(this.selected_tipo_id).then((response) => {
                    this.news = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllNewsType(){
                this.$store.dispatch('form/submit', true);
               NewsService.allNewsType().then((response) => {
                    this.newsType = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
        };
        this.components = {Backgroundcustom};
        this.computed = {
             ...mapGetters({
               // groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        

        this.props = ['device'];
    }).apply(News);

    export default News
</script>
