<template id="home">
    <span>
         <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

                <TituloPage :nombre="'SESIONES EN VIVO '" @back="back()">
            <template v-slot:share>
                <share-component></share-component>
            </template>
        </TituloPage>

       
        <p class="text-white text-3l font-bold">Las mejores registros de sesiones en vivo de tus Djs favoritos</p>
<div class="grid mt-3 ml-2 mr-2">
    <div v-for="(d, idxx) in filteredArtists" :key="idxx" @click.prevent="openArtistSongs(d, selectedType)" class="col-4 sm:col-2 lg:col-1 p-2">
        <div class="card">
            <ImageFallback v-if="d.foto_artista != null"
                :imageSource="d.foto_artista"
                :fallbackSrc="'@/assets/logo.svg'"
                :alt="'noico'"
                :class="'z-index-0'"
                :width="null"
                :maxHeight="'160px'"
            />
            <ImageFallback v-else
                :imageSource="'@/assets/logo.svg'"
                :fallbackSrc="'@/assets/logo.svg'"
                :alt="'noico'"
                :class="'z-index-0'"
                :width="null"
                :maxHeight="'160px'"
            />
            <p class="text-white font-bold text-xs">{{ d.nombre }} {{ d.apellido }}</p>
        </div>
    </div>
</div>


        <side-bar v-model:visible="visibleFULL" :baseZIndex="10000" position="full" class="bg-primary-cus" :showCloseIcon="true">
            <selecteddaytime :device="device" @horario="seleccionarHorario"></selecteddaytime>
        </side-bar>

         <side-bar v-model:visible="visibleRight" :baseZIndex="10000" position="right" class="bg-primary-cus" :showCloseIcon="false">
            <h3 style="color: white !important;">{{this.artistTypeSelected.nombre}}</h3>
             <div class="grid">
                <div class="col-12 md:col-12 lg:col-12"  v-for="(c, idx) in artists" :key="idx" >
                    <span @click.prevent="openArtistSongs(c)" class="text-white font-bold" style="color: white !important;">
                         {{c.nombre}} {{c.apellido}}</span>
                    <!--span class="text-white font-bold" style="color: #0a0850 !important;">-{{c.name}}</span-->
                </div>
            </div>
 </side-bar>
<br /><br /><br /><br /><br /><br /><br /><br /><br />
    </span>     
</template>

<style>
        .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
     color: black !important;
}

.neon-border-bottom {
    position: relative;
    border-bottom: solid white !important;
}

.neon-border-bottom::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: white;
    box-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 40px white;
}
</style>

<script>

    import Backgroundcustom from '@/components/Backgroundcustom';
    import ArtistService from '@/service/ArtistService';
    import Selecteddaytime from '@/components/SelectedDayTime';
    import {mapGetters} from 'vuex';
    import ImageFallback from '@/components/ImageFallback.vue';
    import ShareComponent from '@/components/ShareComponent'
    import TituloPage from '@/components/TituloPage'
    
    let SelectGenero = {};
    (function () {
        this.name = 'home-page';

        this.created = function () {

           if(this.djs_cargados == null){
                this.getAlltipoArtista();
            }else{
                this.artistType = this.djs_cargados;
                this.filterByType(13);
            }
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
                visibleFULL: false,
                selectedDayTime: {'tiempo_dia_id': -1, 'nombre': null},
                artistType: [],
                artistTypeSelected: null,
                artists: [],
                st: process.env.VUE_APP_STATIC,
                visibleRight: false,
                filteredArtists: [],
            }
        };
        this.methods = {
            filterByType(typeId) {
                this.selectedType = this.artistType.find(type => type.tipo.tipo_artista_id === typeId);
                if (this.selectedType) {
                    this.filteredArtists = this.selectedType.artistas;
                } else {
                    this.filteredArtists = [];
                }
            },

            seleccionarHorario(s){
                this.selectedDayTime = s;
                this.visibleFULL = false;
            },
            openModal(){
                this.visibleFULL = true;
            },
            openArtistSongs(a, tipo){
                this.visibleRight = false;
                var at_name = a.nombre + ' ' + a.apellido
                var port = 0
      
                if(tipo.portada !== "")
                    port = tipo.portada;

                var selec_id = -1
                var nom_day_time = -1;

                if(this.selectedDayTime != null){
                    selec_id = this.selectedDayTime.tiempo_dia_id
                     nom_day_time = this.selectedDayTime.nombre;
                }

                this.$router.push({name: 'artist-songs',
                   params: {
                        artist_id: a.artista_id,
                        artist_type_name: tipo.nombre,
                        img: port,
                        artist_name: at_name,
                        open_from_player: false,
                        daytimeid: selec_id,
                        daytime_nombre: nom_day_time,
                    }
               });

            },
            setVisibleModal(){
                console.log('visible');
                this.visibleRight = true;
            },
            getArtistaPorTipo(tipo){
                this.artistTypeSelected = tipo;
                this.$store.dispatch('form/submit', true);
               ArtistService.artistByTipo(this.artistTypeSelected.tipo_artista_id).then((response) => {
                    this.artists = response.data;
                    if(this.artists.length == 0){
                         this.$store.dispatch('form/result', {isError: true, message: 'Sin artistas'});
                    }else{
                        this.visibleRight = true;
                    }
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAlltipoArtista(){
                this.$store.dispatch('form/submit', true);
               ArtistService.allArtistTypeAndArtist().then((response) => {
                    this.artistType = response.data;
                    this.filterByType(13);
                    this.$store.dispatch('djs/set-djs', response.data);
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            allArtistTypeAndArtistDayTime(id_day_time){
                this.$store.dispatch('form/submit', true);
               ArtistService.allArtistTypeAndArtistDayTime(id_day_time).then((response) => {
                    this.artistType = response.data;

                    if(response.data.length == 0 ){
                        this.$store.dispatch('form/result', {isError: true, message: 'Artistas sin momentos'});
                    }else{
                        this.$store.dispatch('radio/set-djs', response.data);
                        this.$store.dispatch('form/submit', false);  
                    }
                    
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
                      back() {
                        this.$router.push({name: 'home'});
                },
            goTo: function (route, id, name, url) {
                this.$router.push({name: route,
                   params: {
                        id: id,
                        name: name,
                        img: url,
                    }
               });
            }
        };
        this.components = {Backgroundcustom, Selecteddaytime, ImageFallback, ShareComponent, TituloPage};
        this.computed = {
             ...mapGetters({
                djs_cargados: 'djs/djs',
                djs_tipo:'djs/seleccionado'
            })
        };
        this.watch = {
             selectedDayTime(newValue){
                if (newValue && newValue.tiempo_dia_id) {
                    this.artistType = [];
                    console.log(newValue.tiempo_dia_id);
                    this.allArtistTypeAndArtistDayTime(newValue.tiempo_dia_id);
                } else {
                    console.log('newValue is null or does not have tiempo_dia_id');
                    if(this.djs == null){
                            this.getAlltipoArtista();
                        }else{
                            this.artistType = this.djs;
                        }
                }
            }
        };
        

        this.props = ['device'];
    }).apply(SelectGenero);

    export default SelectGenero
</script>
