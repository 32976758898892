<template id="login">
    <div class="md-min-width card m-3">
        <!--backgroundcustom :fondo="-3" :device="device" class="mt-5"></backgroundcustom-->
        <div>
            <img style="width: 80px !important;" alt="icono corporativo" src="@/assets/logo-radio.png">
        </div>

            <h3 class="text-white">Restablecer la contraseña</h3>

            <p class="text-white">Para restablecer tu contraseña, por favor, introduce a continuación tu dirección de correo electrónico.</p>

            <div class="field mt-6">
                <InputText style="width: 230px !important;" id="username2" type="email" aria-describedby="username2-help"  :class="{'p-invalid': error.credentials}" v-model="email" placeholder="Correo Electrónico"/>
                <h6 v-if="error.credentials" id="username2-help" :class="{'p-error': error.credentials}">Usuario invalido.</h6>
            </div>

            <button-prime class="ml-1 w-10rem mt-5 p-button-info" label="Restablecer la contraseña" @click.prevent="recoveryPass()"/>

            <img @click.prevent="back()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/boton-back.svg" style="height: 2rem !important; position: fixed; top: 40px !important; left:20px !important;">

    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import AuthService from '@/service/AuthService';
     import Backgroundcustom from '@/components/Backgroundcustom'

    let RecoveryPass = {};
    (function () {
        this.name = 'login-page';
        // let _self = null;

        this.created = function () {
            this.$store.dispatch('form/error', {credentials: false});
        };

        this.mounted  = function () {
           this.$store.dispatch('song/set-current-screen', {'cancion_id': -2});
        };

        this.data = function () {
            return {
                email: '',
            }
        };
        this.methods = {
            back(){
                this.$router.push({name: 'login'});
            },
            recoveryPass(){

                let result = {isError: false, message: null}

                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)

                if (this.email === null || this.email.trim() === '') {
                    this.$store.dispatch('form/result', {isError: true, message: 'Email mal obligatorio.'});
                        this.$store.dispatch('form/submit', false)
                        return;
                }

                if (this.email !== null && this.email) {
                    var reEmail = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
                    if (reEmail.test(this.email) === false) {
                        this.$store.dispatch('form/result', {isError: true, message: 'Email mal formateado'});
                        this.$store.dispatch('form/submit', false)
                        return;
                    }
                }

                 AuthService.recoveryPass(this.email)
                    .then(response => {
                      console.log(response);

                      result.message = response.data.message;
                      if(response.data.status === 'ERROR'){
                            result.isError = true;
                        }else{
                            this.$router.replace({name: 'login'}); 
                        }

                        this.$store.dispatch('form/result', result);
                        this.$store.dispatch('form/submit', false); 

                    })
                    .catch(err => {
                      if (err.response.status === 400) {
                        this.$store.dispatch('form/submit', false);
                      }
                    });
            },
        };
        this.computed = {
            ...mapGetters({
                error: 'form/error-states'
            }),
        };
        this.components = {Backgroundcustom};
        this.watch = {
            email(newValue){
                this.email = newValue.toLowerCase();
            }
        };
        this.props = ['device'];
    }).apply(RecoveryPass);

    export default RecoveryPass
</script>
