const state = () => ({
    vimeoPlayer: {
        isActive: false,
        isMinimized: false,
        videoId: null,
        current_time: 0,
        zIndex: 2000,
        // Puedes agregar más propiedades según sea necesario
    }
});

const getters = {
    // Getters para acceder a las propiedades del estado
    vimeoPlayerState: (state) => state.vimeoPlayer,
    isPlayerActive: (state) => state.vimeoPlayer.isActive,
    isPlayerMinimized: (state) => state.vimeoPlayer.isMinimized,
    currentVideoId: (state) => state.vimeoPlayer.videoId,
    videoCurrentTime: (state) => state.vimeoPlayer.current_time,
    zIndex: (state) => state.vimeoPlayer.zIndex,
};

const actions = {
    // Acciones para modificar el estado
    setPlayerState({ commit }, payload) {
        commit('UPDATE_PLAYER_STATE', payload);
    },
    togglePlayerActive({ commit }, isActive) {
        commit('SET_PLAYER_ACTIVE', isActive);
    },
    togglePlayerMinimized({ commit }, isMinimized) {
        commit('SET_PLAYER_MINIMIZED', isMinimized);
    },
    setCurrentVideoId({ commit }, videoId) {
        commit('SET_VIDEO_ID', videoId);
    },
    setZIndex({ commit }, zIndex) {
        commit('SET_Z_INDEX', zIndex);
    }
};

const mutations = {
    // Mutaciones para cambiar el estado
    UPDATE_PLAYER_STATE(state, payload) {
        state.vimeoPlayer = { ...state.vimeoPlayer, ...payload };
    },
    SET_PLAYER_ACTIVE(state, isActive) {
        state.vimeoPlayer.isActive = isActive;
    },
    SET_PLAYER_MINIMIZED(state, isMinimized) {
        state.vimeoPlayer.isMinimized = isMinimized;
    },
    SET_VIDEO_ID(state, videoId) {
        state.vimeoPlayer.videoId = videoId;
    },
    SET_Z_INDEX(state, zIndex) {
      state.vimeoPlayer.zIndex = zIndex;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
