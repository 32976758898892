<template id="redirect-playlist-genero-page">
    <div class="mt-6">
        <p class="subtitle is-size-5"><strong><i class="mdi mdi-timer-sand-full has-text-white mdi-spin"></i>Cargando Playlist Genero...</strong></p>              
    </div>
</template>

<style lang="less" scoped>
    
</style>


<script>
    import {mapGetters} from 'vuex';

    let RedirectArtistSongs = {};
    (function () {
        this.name = 'redirect-artist-songs';
        this.created = function () {
                this.$router.push({name: 'artist-songs',
                   params: {
                        artist_id: this.$route.params.artist_id,
                        artist_type_name: this.$route.params.artist_type_name,
                        img: this.$route.params.img,
                        artist_name: this.$route.params.artist_name,
                        open_from_player: this.$route.params.open_from_player,
                        daytimeid: this.$route.params.daytimeid,
                        dasytime_nombre: this.$route.params.dasytime_nombre
                    }
               });
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
           
        };

        this.data = function () {
            return {
              
            }
        };
        this.methods = {
           
        };
        this.computed = {
           
             ...mapGetters({
                //current_cap: 'auth/current-capitulo'
            })
        };
        this.watch = {

        };
        this.components = {};
        
        this.props = ['device'];
    }).apply(RedirectArtistSongs);

    export default RedirectArtistSongs
</script>
