<template>
  <div class="download-button">
    <button>
      <span @click="openDialog()" v-if="!getDescargando" style="color: #fff;">
        <i class="pi pi-download pi-white" style="color: #fff; font-size: 20px;"></i>

      </span>
      <span v-else @click.prevent="verDescargas()">
          <ProgressSpinner style="width: 20px; height: 20px; margin-bottom: 5px !important;" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
      </span>
    </button>
   
        <dialog-prime header="Descargas" v-model:visible="showListDialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :position="'Bottom'" :modal="true">
            <p class="m-0">Usted esta bajando la o las canciones:</p>

            <span class="mr-6">
              <OfflineMusicList/>
            </span>
                
            <template #footer>
                <button-prime class="w-7rem p-button-link" label="Cerrar" @click.prevent="cerrarListDialog()" style="font-weight: 800; color: #aa00ff;"/>
            </template>
        </dialog-prime>
         <dialog-prime header="Musica Offline" v-model:visible="showDialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :position="'Bottom'" :modal="true">
            <p class="m-0">Usted comenzará a bajar de nuestra nube la canción para escucharla cuando no tenga conexión.</p>

            <template #footer>
                <button-prime class="w-7rem p-button-link" label="SI" @click.prevent="si()" style="font-weight: 800; color: #aa00ff;"/>
                <button-prime class="w-7rem p-button-link" label="DESCARGAS" @click.prevent="verDescargas()" style="font-weight: 800; color: #aa00ff;"/>
            </template>
        </dialog-prime>
  </div>
</template>


<script>
  import CancionesService from '@/service/CancionesService';
import { mapGetters, mapActions } from 'vuex';
import { Filesystem } from '@capacitor/filesystem';
import { Http } from '@capacitor/filesystem';
import { Directory } from '@capacitor/filesystem';
import { Encoding } from '@capacitor/filesystem';
import { Plugins } from '@capacitor/core';
const { App } = Plugins;
import Axios from "axios";
import OfflineMusicList from '@/components/OfflineMusicList';

let DescargaComponent = {};
(function() {
  this.name = 'DescargaComponent';
  this.data = function () {
            return {
                 current_size: null,
                 descargando: false,
                 showDialog: false,
                 showListDialog: false,
                
            }
        };
  this.created = function () {
       // this.getSizeFile(this.urlCancion);
  };
  this.props = ['urlCancion','nombreArchivo','idCancion', 'current_cancion'];
  this.components = {OfflineMusicList};
  this.computed = {
    ...mapGetters({
        all_sogs: 'songs_downloading/downloaded'
    }),
    getDescargando: function() {
      var result = this.getSongDownloadState(this.idCancion)?.progreso;
      if(result == undefined || result == null){
        this.descargando = false;
        return false;
      }else{
        if(result >= 0 && result < 100){
          return this.descargando = true;
        }else{
          return this.descargando = false;
        }
      }
      return this.descargando;
    },
  };

this.methods = {
  verDescargas(){
    this.showDialog = false;
    this.showListDialog =  true;
  },
  cerrarListDialog(){
    this.showListDialog =  false;
  },
  openListDialog(){
    this.showListDialog =  true;
  },
  openDialog(){
    this.showDialog = true;
  },
  no(){
    this.showDialog = false;
  },
  si(){
    this.desicion();
  },
  getSizeFile(url){
        this.$store.dispatch('form/submit', true);
        CancionesService.getSizeByUrl(url).then((response) => {
          this.current_size = parseInt(response.data)
          if(this.current_size > 0){
          }
        }).catch((error) => {
            console.log(error);
            this.$store.dispatch('form/submit', false);
        });
  }, 
 desicion() {

  var bajandoooo = 0;
  for (var i = 0; i < this.all_sogs.length; i++){
    if(this.all_sogs[i].progreso < 100  && this.all_sogs[i].progreso >= 0){
      bajandoooo = bajandoooo + 1;
    }
  }

  if(bajandoooo >= 3){
    this.$store.dispatch('form/result', {isError: true, message: 'Usted tiene un máximo de 3 canciones para descargar simultaneamente.'});
    this.showListDialog =  true;
    return;
  }

  const song = this.getSongDownloadState(this.idCancion);
  if (song) {
    if(song.progreso == 100 ){
      this.$store.dispatch('form/result', {isError: true, message: 'La canción ya está en su música offline.'});
    }else{
       if(song.progreso < 100  && song.progreso >= 0){
          this.$store.dispatch('form/result', {isError: true, message: 'La canción se esta descargando.'});
          this.showListDialog =  true;
        }else{
          if(song.progreso == -1){
            this.$store.dispatch('songs_downloading/removeDownloadedSong', song);
            this.descargarAudio();
          }
        }
        
    } 
  } else {
    this.descargarAudio();
  }
  this.showDialog = false;
},
getSongDownloadState(id) {
  const song = this.all_sogs.find(song => song.id === id);
  return song;
},
obtenerExtension(nombreArchivo) {
        let partes = nombreArchivo.split('.');
        return partes.length > 1 ? partes.pop() : '';
    },
formatearNombreArchivo(fileName) {
    let formateado = fileName.replace(/ /g, '_');
    formateado = formateado.replace(/[^a-zA-Z0-9_-]/g, '');
    formateado = formateado.replace(/#1/g, 'Vol I');
    formateado = formateado.replace(/#2/g, 'Vol II');
    formateado = formateado.replace(/#3/g, 'Vol III');
    formateado = formateado.replace(/#4/g, 'Vol IV');
    return formateado;
},

formatNombreFanta(fileName) {
    let formateado = fileName;
    formateado = formateado.replace(/#1/g, 'Vol I');
    formateado = formateado.replace(/#2/g, 'Vol II');
    formateado = formateado.replace(/#3/g, 'Vol III');
    formateado = formateado.replace(/#4/g, 'Vol IV');
    formateado = formateado.replace(/[^a-zA-Z0-9 _-]/g, '');
    return formateado;
},

descargarAudio() {
  const extencion = this.obtenerExtension(this.nombreArchivo);

  const artista = this.current_cancion.artista
    ? `${this.current_cancion.artista.nombre}-${this.current_cancion.artista.apellido}`
    : 'null';
  const genero = this.current_cancion.genero ? this.current_cancion.genero.nombre : 'null';

  const fileName = this.formatearNombreArchivo(this.idCancion + "__" + genero + '__' + artista + '__' + this.current_cancion.nombre);

  const nombre_fataaaaa = this.formatNombreFanta(this.current_cancion.nombre);

  let this__ = this;

  const directoryPath = 'canciones';
  const filePath = `${directoryPath}/${fileName}`;

  this__.$store.dispatch('songs_downloading/addDownloadedSong', {
    id: this__.idCancion,
    nombre: fileName+ '.' + extencion,
    nombre_fanta: nombre_fataaaaa,
    url: this__.urlCancion,
    ruta_archivo: filePath+ '.' + extencion,
    progreso: 1
  });

  const CHUNK_SIZE = 10 * 1024 * 1024;


  Axios({
    method: 'get',
    url: this__.urlCancion,
    responseType: 'arraybuffer',
    onDownloadProgress: progressEvent => {
      const totalLength = progressEvent.total;

      const downloaded = progressEvent.loaded;

      const progress = Math.floor((downloaded / totalLength) * 100);

      this__.$store.dispatch('songs_downloading/updateProgress', {
        id: this__.idCancion,
        progreso: progress
      });

     
    },
    isDownload: true,
  }).then(response => {

    const totalSize = response.data.byteLength;
    const totalChunks = Math.ceil(totalSize / CHUNK_SIZE);
    let currentChunk = 0; // Inicializa la parte actual en 0

    const saveChunk = (chunkData) => {


      const chunkFileName = `${filePath}__${currentChunk}.${extencion}`; // Agregar el número de parte al nombre del archivo

        // Convertir el ArrayBuffer en una cadena base64
      const chunkBase64 = btoa(new Uint8Array(chunkData).reduce((data, byte) => {
        return data + String.fromCharCode(byte);
      }, ''));

    // Utiliza una promesa para escribir el fragmento
    return new Promise((resolve, reject) => {

          Filesystem.writeFile({
            path: chunkFileName,
            data: chunkBase64,
            directory: Directory.Documents,
            encoding: Filesystem.Encoding.UTF8,
          }).then(() => {

            currentChunk++;
 
            if (currentChunk === totalChunks) {
              this__.$store.dispatch('songs_downloading/updateProgress', {
                id: this__.idCancion,
                progreso: 100
              });
            } else {
         
              const progress = Math.floor((currentChunk / totalChunks) * 100);
              this__.$store.dispatch('songs_downloading/updateProgress', {
                id: this__.idCancion,
                progreso: progress
              });
              resolve(downloadNextChunk());
            }
          }).catch(writeError => {
            console.error(`Error al escribir la parte ${currentChunk}:`, writeError);
          });
      });
    };

    const downloadNextChunk = () => {
      const startByte = currentChunk * CHUNK_SIZE;
      const endByte = Math.min(startByte + CHUNK_SIZE, totalSize);
      const chunkData = response.data.slice(startByte, endByte);
      saveChunk(chunkData);
    };

    downloadNextChunk();
  }).catch(error => {
    this__.$store.dispatch('songs_downloading/updateProgress', {
      id: this__.idCancion,
      progreso: -1
    });
    console.error('Error durante la descarga:', error);
  });
},


accederOffline() {

  this.$router.push({
    name: 'offline-music',
    params: {
      idCancion: this.idCancion,
    }
  });
},


};


}).apply(DescargaComponent);
export default DescargaComponent;
</script>

<style scoped>
.download-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 32px;
  height: 32px;
  position: relative;
}

.spinner-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  transform-origin: center center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
}

.pi-lg {
  font-size: 48px !important;
}

/* Estilos para la barra de progreso */
progress {
  width: 100%;
  height: 10px;
  margin-top: 10px;
}
</style>

