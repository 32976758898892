import { createStore } from 'vuex';
import form from './form';
import auth from './auth';
import song from './song';
import radio from './radio';
import songs_downloading from './songs_downloading';
import video_player from './video_player';
import djs from './djs';
//import database from './db';

const store = createStore({
  modules: {
    form: form,
    auth: auth,
    song: song,
    radio: radio,
    djs: djs,
    songs_downloading: songs_downloading,
    video_player: video_player,
    //database: database,
  },
});

//store.dispatch('db/initialize');

export default store;
