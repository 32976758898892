<template id="profile-user">
    <div class="md-min-width card mb-5">
        <backgroundcustom :fondo="1" :device="device" class="mt-5"></backgroundcustom>

        <TituloPage :nombre="'PERFIL USARIO'" @back="backHome()">
            <template v-slot:share>
                <share-component></share-component>
            </template>
        </TituloPage>

        <div>
            <img style="width: 90px !important;" alt="icono corporativo" src="@/assets/rojo/avatar.svg"><br />
            <span class="text-white">{{name_user}}</span>
        </div>

            <div class="field">
                <h5 for="username2" class="text-white font-bold">Nombre de Usuario<br /><span class="text-white font-light">{{username}}</span></h5>
            </div>
            <!--div class="field">
                <h5 for="nameid" class="text-white font-light">Nombre<br /><span class="text-white font-bold"></span></h5>
            </div-->
            <!--div class="field ml-4" @click.prevent="openModal()">
                <h5 for="emailid" class="text-white font-bold">Correo Electronico<br /><span class="text-white font-light">{{email}}</span></h5>
            </div-->

             <h3 class="text-white ml-4">Actualizar Contraseña</h3>

            <div class="field">
                <!--h5 for="pass1" class="text-white">Contraseña Nueva</h5-->
                <pass-word v-model="password" toggleMask :feedback="false" placeholder="Contraseña Nueva" class=""/>

                 <pass-word v-model="repassword" toggleMask :feedback="false" placeholder="Repetir Contraseña" class="mt-2" />
            </div>

          <div class="button-container">
    <button-prime class="ml-1 w-9rem mt-5 p-button-info" label="Actualizar Contraseña" @click.prevent="changePass()"></button-prime>
    <button-prime class="ml-1 w-9rem mt-5 p-button-info" label="Desconectar" @click.prevent="salir()"></button-prime>
    <button-prime class="ml-1 w-9rem mt-5 p-button-text" label="Borrar cuenta" @click.prevent="deleteuser()" style="font-weight: 800; color: #FAFBE4;"></button-prime>
</div>
             
             <side-bar v-model:visible="visibleFULL" :baseZIndex="10000" position="right" :showCloseIcon="true" class="">
                    <h3 class="text-primary">Edicion Usuario</h3>
                     <div class="grid" v-if="GETmostrarFOrm">
                        <div class="col-12 md:col-12 lg:col-12">
                            
                            <span>Selecciona Cumpleaños</span><br />
                            <calendar-prime v-model="birth_day" :inline="true" placeholder="Seleccionar Cumpleaños" dateFormat="dd-mm-yy"/>

                            <drop-down id="dropGenero" v-model="selected_genero_persona" :options="generos_persona" optionLabel="nombre" placeholder="Seleccionar Genero" class="mt-2" />

                            <drop-down id="dropEstacion" v-model="selected_estacion" :options="estaciones" optionLabel="name" placeholder="Selecciona estacion favorita" class="mt-2"/>
                            <br /><br />

                            <button-prime class="ml-1 w-9rem mt-5 p-button-info" label="Actualizar Datos Usuario" @click.prevent="editUser()"/>
                            
                        </div>
                    </div>
            </side-bar>

        <br /><br /><br /><br /><br /><br />
    </div>
</template>

<style>
    
</style>


<script>
    import {mapGetters} from 'vuex';
    import AuthService from '@/service/AuthService';
     import Backgroundcustom from '@/components/Backgroundcustom'
       import TituloPage from '@/components/TituloPage'

     import moment from "moment";

    let RegisterUser = {};
    (function () {
        this.name = 'change-pass';
        // let _self = null;

        this.created = function () {
            this.getUser(this.username);
            this.getGenerosPersona();

        };

        this.mounted  = function () {
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
                radio: process.env.VUE_APP_RADIO,
                password: null,
                repassword: null,
                name_user: null,
                email: null,
                visibleFULL: false,
                selected_estacion: null,
                selected_genero_persona: null,
                generos_persona: [],
                user_radio: null,
                birth_day: null,
                id_user: null,
                mostrarFOrm:false,
            }
        };
        this.methods = {
            salir(){
 this.$router.push({name: 'logout'});
            },
                     backHome() {
                this.$router.push({name: 'home'});
                },
      
            openModal(){
                this.visibleFULL = true;
                let z = this;
                setTimeout(() => {
                    z.mostrarFOrm = true
                }, 500)

            },
            closeModal(){
                this.visibleFULL = false;
                this.mostrarFOrm = false
            },
            getOnlyDate(date){
                  return moment(date).format('YYYY-MM-DD');
            },
            editUser(){  
                this.$store.dispatch('form/submit', true);

                if (this.selected_estacion === null ||  this.selected_estacion === undefined) {
                    this.$store.dispatch('form/result', {isError: true, message: 'Estacion favorita obligatoria'});
                    this.$store.dispatch('form/submit', false)
                    this.visibleFULL= false;
                    return;
                }

                if (this.birth_day === null ||  this.birth_day === undefined) {
                    this.$store.dispatch('form/result', {isError: true, message: 'Cumpleaños obligatorio'});
                    this.$store.dispatch('form/submit', false)
                    this.visibleFULL= false;
                    return;
                }

                if (this.selected_genero_persona === null ||  this.selected_genero_persona === undefined) {
                    this.$store.dispatch('form/result', {isError: true, message: 'Genero obligatorio'});
                    this.$store.dispatch('form/submit', false)
                    this.visibleFULL= false;
                    return;
                }

                AuthService.editUserRadio(this.id_user, this.getOnlyDate(this.fecha_nacimiento), this.selected_estacion.estacion_id, this.selected_genero_persona.genero_persona_id)
                    .then(response => {
                            this.user_radio = response.data;
                            this.selected_genero_persona = this.user_radio.genero_persona;
                            this.selected_estacion = this.user_radio.estacion_favorita;
                            this.birth_day = this.user_radio.fecha_nacimiento;
                            this.visibleFULL= false;
                            this.$store.dispatch('form/result', {isError: false, message: 'Edicion Exitosa'});
                            this.$store.dispatch('form/submit', false); 
                    })
                    .catch(err => {
                       console.log(err)
                        this.$store.dispatch('form/submit', false);
                      
                });

            },
            getUser(user){
                this.$store.dispatch('form/submit', true); 
                AuthService.getUser(this.username)
                    .then(response => {
                        //result.message = response.data.message;
                        if(response.data.status === 'ERROR'){
                            result.isError = true;
                        }else{
                            //this.$store.dispatch('form/result', result);
                            this.name_user = response.data.data.first_name + ' ' +response.data.data.last_name;

                            this.email = response.data.data.email;
                            this.id_user = response.data.data.id;
                            this.getUserRadio(response.data.data.id);
                            this.$store.dispatch('form/submit', false); 
                        }
                    })
                    .catch(err => {
                       console.log(err)
                        this.$store.dispatch('form/submit', false);
                      
                });
            },
            getUserRadio(id){
                this.$store.dispatch('form/submit', true); 
                AuthService.getUserRadio(id)
                    .then(response => {
                        //result.message = response.data.message;
                        if(response.data.status === 'ERROR'){
                            result.isError = true;
                        }else{
                            //this.$store.dispatch('form/result', result);
                            this.user_radio = response.data

                            this.selected_genero_persona = this.user_radio.genero_persona;
                            this.selected_estacion = this.user_radio.estacion_favorita;
                            this.birth_day = this.user_radio.fecha_nacimiento;
                            this.$store.dispatch('form/submit', false); 
                        }
                    })
                    .catch(err => {
                       console.log(err)
                        this.$store.dispatch('form/submit', false);
                      
                });
            },
             getGenerosPersona(user){
                this.$store.dispatch('form/submit', true); 
                AuthService.getGenerosPersona()
                    .then(response => {
         
                            this.generos_persona = response.data;
                            this.$store.dispatch('form/submit', false); 
                        
                    })
                    .catch(err => {
                       console.log(err)
                        this.$store.dispatch('form/submit', false);
                      
                });
            },
            back(){
                this.$router.push(-1);
            },
            deleteuser(){
                let result = {isError: false, message: null}
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)

                AuthService.deleteuser(this.username)
                    .then(response => {
                        result.message = response.data.message;
                        if(response.data.status === 'ERROR'){
                            result.isError = true;
                        }else{
                            this.$router.replace({name: 'logout'});
                            this.$store.dispatch('form/result', result);
                            this.$store.dispatch('form/submit', false); 
                        }
                    })
                    .catch(err => {
                      if (err.response.status === 400) {
                        this.$store.dispatch('form/submit', false);
                      }
                    });
            },
            changePass(){
                let result = {isError: false, message: null}
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)

                if (this.password === null || this.password.trim() === '') {
                    this.$store.dispatch('form/result', {isError: true, message: 'Contraseña obligatorio'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                if (this.repassword === null || this.repassword.trim() === '') {
                    this.$store.dispatch('form/result', {isError: true, message: 'Repita Contraseña'});
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                if(this.repassword !== null && this.password !== null){
                   if (this.repassword !== this.password.trim()) {
                    this.$store.dispatch('form/result', {isError: true, message: 'Contraseña no son iguales'});
                    this.$store.dispatch('form/submit', false)
                    return;
                    } 
                }

                AuthService.cangePass(this.username, this.password)
                    .then(response => {
                        result.message = response.data.message;
                        if(response.data.status === 'ERROR'){
                            result.isError = true;
                        }else{
                            this.$router.replace({name: 'home'});
                            this.$store.dispatch('form/result', result);
                            this.$store.dispatch('form/submit', false); 
                        }
                    })
                    .catch(err => {
                      if (err.response.status === 400) {
                        this.$store.dispatch('form/submit', false);
                      }
                    });
            },
        };
        this.computed = {
            GETmostrarFOrm(){
                return this.mostrarFOrm;
            },
            ...mapGetters({
                error: 'form/error-states',
                username: 'auth/username',
                estaciones: 'radio/estaciones',
            }),
        };
        this.components = {Backgroundcustom, TituloPage};
        this.watch = {
            
        };
        this.props = ['device'];
    }).apply(RegisterUser);

    export default RegisterUser
</script>

<style scoped>
.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
