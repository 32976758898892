<template>
       <span>
            <p class="text-white-entrance text-xs font-bold shimmer" style="width: 100% !important"> 
                “La Radio de tus sueños , la Música de tu vida”
            </p>
        </span>
</template>

<script>
   
    let EmptyComponent = {};
    (function () {

        this.name = 'empty-component';

        this.created = function () {
            
        };
        this.data = function () {
            return {
                 
            }
        };
        this.methods = {
            
        };
        this.mounted = function () {};
        this.components = {}
        this.computed = {};
        this.watch = {};
        this.props = [];
    }).apply(EmptyComponent);
    export default EmptyComponent;
</script>

<style scoped>
.text-white-entrance {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 2s forwards 0.5s, shimmer 1.5s 1 3s, setColor 0.1s forwards 4.5s;
    color: #C70EFF;
    font-family: Arial, sans-serif;
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

@keyframes setColor {
    to {
        color: white;
    }
}

.shimmer {
    background: linear-gradient(90deg, rgba(255,255,255,0.2) 25%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.2) 75%);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

</style>
