const state = () => ({
	current: null,
	is_playing: false,
	pausa: false,
	current_time: 0,
	duration_time: 0,
	new_time: -1,
	next_song: null,
	list_songs: [],
	is_radio: false,
	current_screen: {cancion_id: -1},
	favovites: [],
	listas: [],
	played: [],
});
 
const getters = {
	'current'(state) {
		return state.current
	},
	'current_screen'(state) {
		return state.current_screen
	},
	'is_playing'(state) {
		return state.is_playing
	},
	'pausa'(state) {
		return state.pausa
	},
	'current_time'(state) {
		return state.current_time
	},
	'duration_time'(state) {
		return state.duration_time
	},
	'new_time'(state) {
		return state.new_time
	},
	'next_song'(state) {
		return state.next_song
	},
	'list_songs'(state) {
		return state.list_songs
	},
	'played'(state) {
		return state.played
	},
	'is_radio'(state) {
		return state.is_radio
	},
	'favovites'(state) {
		return state.favovites
	},
	'listas'(state) {
    	return state.listas.filter(lista => lista.nombre !== 'Favoritos');
  	}	
};
 
const actions = {
	'set-current'(context, value) {
		context.commit('set-current', value)
	},
	'set-current-screen'(context, value) {
		context.commit('set-current-screen', value)
	},
	'set-is-playing'(context, value) {
		context.commit('set-is-playing', value)
	},
	'set-pausa'(context, value) {
		context.commit('set-pausa', value)
	}
	,
	'set-current-time'(context, value) {
		context.commit('set-current-time', value)
	},
	'set-duration-time'(context, value) {
		context.commit('set-duration-time', value)
	},
	'set-new-time'(context, value) {
		context.commit('set-new-time', value)
	},
	'set-next-song'(context, value) {
		context.commit('set-next-song', value)
	},
	'set-list-songs'(context, value) {
		context.commit('set-list-songs', value)
	},
	'set-is-radio'(context, value) {
		context.commit('set-is-radio', value)
	},
	'set-favovites'(context, value) {
		context.commit('set-favovites', value)
	},
	'set-listas'(context, value) {
    	context.commit('set-listas', value); // Agrega esto
  	},
  	'set-played'(context, value) {
		context.commit('set-played', value)
	},
};
 
const mutations = {
	'set-current'(state, value) {
		state.current = value
	},
	'set-current-screen'(state, value) {
		state.current_screen = value
	},
	'set-is-playing'(state, value) {
		state.is_playing = value
	},
	'set-pausa'(state, value) {
		state.pausa = value
	},
	'set-current-time'(state, value) {
		state.current_time = value
	},
	'set-duration-time'(state, value) {
		state.duration_time = value
	},
	'set-new-time'(state, value) {
		state.new_time = value
	},
	'set-next-song-time'(state, value) {
		state.next_song = value
	},
	'set-list-songs'(state, value) {
		state.list_songs = value
	},
	'set-is-radio'(state, value) {
		state.is_radio = value
	},
	'set-favovites'(state, value) {
		state.favovites = value
	},
	'set-listas'(state, value) {
    	state.listas = value;
  	},
	'set-played'(state, value) {
    	state.played = value;
  	}
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}