<template id="login">
    <div class="md-min-width card m-3">
        <backgroundcustom :fondo="0" :device="device" class="mt-1"></backgroundcustom>

        <div class="flex align-items-center justify-content-center">
                <div class="flex align-items-center justify-content-center"><img style="width: 80px !important;" alt="icono corporativo" src="@/assets/logo-aconcagua.png"></div>

                <div class="flex align-items-center justify-content-center ml-2"><img style="width: 80px !important;" alt="icono corporativo" src="@/assets/logo-radio.png"></div>

                <div class="flex align-items-center justify-content-center"><img style="width: 80px !important;" alt="icono corporativo" src="@/assets/insignia-san-nicolas.png"></div>
            </div>
        <div>
            
        </div>

            <h3 class="text-white">Radio {{nombre_radio}}</h3>

            <div class="field mt-6">
                <h5 for="username2" class="text-white">Email</h5>
                <InputText style="width: 230px !important;" id="username2" type="username" aria-describedby="username2-help"  :class="{'p-invalid': error.credentials}" v-model="username"/>
                <h6 v-if="error.credentials" id="username2-help" :class="{'p-error': error.credentials}">Usuario invalido.</h6>
            </div>

            <div class="field">
                <h5 for="username2" class="text-white">Contraseña</h5>
                <pass-word v-model="password" toggleMask :feedback="false"/>
            </div>

            <button-prime class="ml-1 w-8rem mt-5 p-button-info" label="Ingresar" @click.prevent="logIn()"/>
            <button-prime class="ml-1 w-8rem mt-5 p-button-info" label="Registrarse" @click.prevent="registrate()" />
  
            <br />

            <h5 class="text-white font-bold" @click.prevent="recoveryPass()">Recuperar Contraseña</h5>

            <h5 class="text-white font-bold" @click.prevent="goTo('login-email')">Ingreso con Email</h5>
            
            

            <button-prime class=" w-8rem p-button-info" label="Visitar" @click.prevent="goToHome()" />
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import AuthService from '@/service/AuthService';
    import Backgroundcustom from '@/components/Backgroundcustom'
    //import { Storage } from '@capacitor/storage';
    import { Preferences } from '@capacitor/preferences';

    let Login = {};
    (function () {
        this.name = 'login-page';
        // let _self = null;

        this.created = function () {
            this.$store.dispatch('form/error', {credentials: false});
            this.startapp();
        };

        this.mounted  = function () {
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -2});
        };

        this.data = function () {
            return {
                nombre_radio: process.env.VUE_APP_RADIO,
                username: '',
                password: ''
            }
        };
        this.methods = {
            goToHome(){
                this.$router.push({name: 'home'});
            },
            goTo: function (ruta) {
                this.$router.push({name: ruta
               });
            },
            startapp(){
              /*  if(this.authenticated){
                    this.$router.push({name: 'home'});
                }else{
                    Storage.get({ key: 'username' }).then(response => {
                        if(response.value === null){
                            console.log("username no encontrado");
                            this.$store.dispatch('auth/logout');
                        }else{
                            this.$store.dispatch('auth/set-username', response.value );
                            Storage.get({ key: 'access_token' }).then(response => {
                                if(response.value === null){
                                        console.log("access token no encontrado");
                                        this.$store.dispatch('auth/logout');
                                    }else{
                                        this.$store.dispatch('auth/set-access-token', response.value);
                                        this.$router.push({name: 'home'});
                                    }
                                }).catch(err => {
                                    console.log(err);
                                });
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }*/
           },
            registrate(){
                this.$router.push({name: 'register-user'});
            },
            recoveryPass(){
                this.$router.push({name: 'recovery-pass'}); 
            },
            logInEnPelota(){
                this.logIn();
            },
            logIn() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    username: false,
                    password: false,
                    credentials: false
                });
                
                if (this.username === null || this.username.trim() === '') {
                    this.$store.dispatch('form/error', {username: true});
                }
                
                if (this.password === null || this.password.trim() === '') {
                    this.$store.dispatch('form/error', {password: true});
                }
            
                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }
               
                this.$store.dispatch('auth/logout');
               
                AuthService.log_in(this.username, this.password)
                    .then(response => {
                    
                    console.log(response)
                      this.$store.dispatch('auth/set-access-token', response.data.access_token);
                      this.$store.dispatch('auth/set-refresh-token', response.data.refresh_token);
                       AuthService.getUserDetail(this.username, this.password)
                            .then(response2 => {
                 
                                this.$store.dispatch('auth/set-username', this.username);
                                this.$store.dispatch('auth/set-groups', response2[0]);
                                this.$store.dispatch('auth/set-user-id', response2.user_id);
                    
                               Preferences.set({key: 'access_token', value:response.data.access_token}).then(response => {
                                  console.log(response);
                                }).catch(err => {
                                  console.log(err);
                                });


                                Preferences.set({key: 'id_usuario', value: response2.user_id}).then(response => {
                                  console.log(response);
                                }).catch(err => {
                                  console.log(err);
                                }); 

                                if(response2.recovery_pass == true){
                   
                                    this.$router.replace({name: 'user-profile'}); 
                                    this.$store.dispatch('form/result', {isError: true, message: 'Actualice su contraseña.'});
                                }else{
                            
                                    this.$router.replace({name: 'home', params:{id: 0}}); 
                                }

                                this.$store.dispatch('form/submit', false);
                            }).catch((error) => {
                                console.log(error);
                            });
                        })
                    .catch(err => {
                        console.log(err);
                        this.$store.dispatch('form/result', {isError: true, message: 'Verifiqué el usuario y la contraseña'});
                      this.$store.dispatch('form/submit', false);
                    });
                    
            }
        };
        this.computed = {
            ...mapGetters({
                error: 'form/error-states',
                 authenticated: 'auth/check'
            }),
        };
        this.components = {Backgroundcustom};
        this.watch = {
            username(newValue){
                this.username = newValue.toLowerCase();
            }
        };
        this.props = ['device'];
    }).apply(Login);

    export default Login
</script>
