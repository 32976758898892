<template>
  <ul style="width: 100% !important; list-style: none;">
    <h1 class="text-white" v-if="getCanciones.length == 0">Sin descargas</h1>
    <li v-for="song in getCanciones" :key="song.id">
      <div class="grid">
        <div class="col-9">
          <span v-if="song.progreso < 100">
            <span>{{ song.nombre_fanta }}</span>
            <ProgressBar :value="song.progreso" max="100"></ProgressBar>
          </span>
          <span v-if="song.progreso == 100">
            <span>{{ song.nombre_fanta }}. <span class="p-mr-2 p-text-bold p-text-success">DESCARGA COMPLETA.</span>
            </span>
          </span>
          <span v-if="song.progreso == -1">
            <span>{{ song.nombre_fanta }}. <span class="p-mr-2 p-text-bold p-text-danger">ERROR EN DESCARGA.</span></span>
          </span>
        </div>
        <div class="col-3" v-if="song.progreso == -1" @click="eliminarCancion(song)">X</div>
      </div>
    </li>
  </ul>
</template>

<script>
import { Filesystem, Directory } from '@capacitor/filesystem';
import { mapGetters } from 'vuex';
import { Capacitor } from '@capacitor/core';

export default {
  props: [],

  computed: {
    getCanciones(){
      return this.canciones;
    },
    ...mapGetters({
      downloadedSongs: 'songs_downloading/downloaded',
    })
  },

  components: { },
  created() {
    this.canciones = this.downloadedSongs;
  },
  methods: {
    async eliminarCancion(song) {
      try {

        // Elimina la canción de la lista en la vista
        this.canciones = this.canciones.filter(cancion => cancion.id !== song.id);
        this.$store.dispatch('songs_downloading/removeDownloadedSong', song);

      } catch (error) {
        console.error('Error al eliminar la canción:', error);
      }
    },
  }
};
</script>

<style scoped>
  /* Add your styles here */
</style>
