<template id="home">
    <transition  appear enter-active-class="animate__animated animate__fadeIn">
        <span v-if="getEstacionSeleccionada && radio != null">

            <ProgressBar class="z-index-5" :showValue="false" style="height: 2px  !important; background-color: #0e0033; z-index: 4; top: -5px; left: 0;"/>
            
            <backgroundcustom :fondo="1" :device="device"></backgroundcustom>
                
            <TituloPage :nombre="estacionSeleccionada.name" @back="back()">
                <template v-slot:share>
                    <img class="z-index-0" alt="no icon" src="@/assets/new/Compartir.png" style="width: 30px; height: 30px; color: whitesmoke;" @click.prevent="share()">
                </template>
            </TituloPage>

            <!--EstacionesBotones :estaciones="estaciones" :estacion_seleccionada="estacionSeleccionada"></EstacionesBotones-->

            <img style="box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;" class="z-index-0 mt-2" :class="{'width-80': device.isMobile,'width-25' : device.isDesktop || device.isTablet}" alt="no icon" :src="st+estacionSeleccionada.nuevo_poster_2">
                
            <span>
                <span v-if="info">
                    <span v-if="info.platform != 'android'" id="iframe-ion-content" style=" color: black; background-color: transparent !important;">
                    <iframe id="myIframe"   scrolling="no" :src="estacionSeleccionada.url_radio_info"
                                width="100%" height="85px" frameborder="0" style="background-color: transparent !important;" :class="{'margin-left-center' : device.isDesktop, 'margin-left-tablet' : device.isTablet}" > </iframe>
                    </span>
                </span>
                        
                <div class="card">
                    <div class="flex card-container yellow-container">
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                        <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-900 border-round">
                            <img @click.prevent="back()" class="mr-3 z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/arrowBack.svg" style="height: 3rem !important;">

                            <img v-if="getIsPlaying == false" @click.prevent="play()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/play.svg" style="height: 4rem !important;">

                            <img v-else @click.prevent="stop()" class="ml-3 z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/pausa.svg" style="height: 3rem !important;">

                        </div>
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                    </div>
                </div>
        
                <ProgramationByRadio :id_radio_estacion="radio_estacion_id" :device="device"></ProgramationByRadio>
            </span>
            <br /><br /><br /><br /><br /><br />
        </span>  
    </transition>   
</template>


<style>

#myIframe{
    background-color: black !important;
}
.now_playing span{ color: black !important; }
.now_playing { color: black !important; }
    
    .margin-left-center{
        margin-left: 40vw !important;
    }

    .margin-left-tablet{
        margin-left: 33vw !important;
    }

    .width-mobile{
        width: 50% !important;
    }

    .width-tablet{
        width: 55% !important;
    }

    .width-80{
        width: 80% !important;
    }

    .width-15{
        width: 15% !important;
    }

    .width-25{
        width: 25% !important;
    }

    .width-desktop{
        width: 35% !important;
    }

    .margin-top-mobile{
         top: 10% !important;
    }

    .margin-top-tablet{
         top: 30% !important;
         left: 35% !important;
    }

    .margin-top-desktop{
         top: 10% !important;
         left: 33% !important;
    }

    .margin-top-30-desktop{
         top: 30% !important;
         left: 40% !important;
    }
</style>


<script>
    import { InAppBrowser } from '@ionic-native/in-app-browser';
    import { Device } from '@capacitor/device';
    import Backgroundcustom from '@/components/Backgroundcustom';

    import ProgramationByRadio from '@/components/ProgramationByRadio';
    import RadioService from '@/service/RadioService';
    import {mapGetters} from 'vuex';
    import { Share } from '@capacitor/share';

    //import EstacionesBotones from '@/components/EstacionesBotones';

    import TituloPage from '@/components/TituloPage';


    
    //import { inject } from 'vue'
    
    //let globalVar_auidio_infantil= inject('auidio_infantil')


    let RadioPage = {};
    (function () {
        const options = {
                        location: 'yes',
                        closebuttoncaption: 'Close',
                        hidenavigationbuttons: 'yes',
                        hideurlbar: 'yes',
                    }

        this.name = 'home-page';
        this.created = function () {
            this.radio_estacion_id = this.$route.params.id;
            console.log(this.$route.params.id);
            if(this.radio_info == null){
                console.log('inininininf')
               this.getRadioInfo(); 
           }else{
                this.radio = this.radio_info
           }
            this.current_estaciones = this.estaciones;
            if(this.estaciones == null){
                this.getEstacionesInfo();
            }
            else{
                this.estacionSeleccionada = this.getEstacionById(this.$route.params.id);
                this.play();
            }

            Device.getInfo().then((info) => {
                    this.info = info;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -2});
        };

        this.data = function () {
            return {
                auidio_infantil: null,
                auidio_adultos: null,
                auidio_juvenil: null,
                is_playing: false,
                radio_audio: null,
                radio:null,
                estacionSeleccionada: null,
                info: null,
                st: process.env.VUE_APP_STATIC,
                audio: null,
                current_estaciones: null,
            }
        };
        this.methods = {
            getImageSrc(name) {
                  if (name === 'Adulto') {
                    return require('@/assets/adulto-nuevo.png');
                  } else if (name === 'Juvenil') {
                    return require('@/assets/juvenil-nuevol.png');
                  } else if (name === 'Infantil') {
                    return require('@/assets/infantil-nuevo.png');
                  } else {
                    return '';
                  }
            
            },
            async copyURL(mytext) {
                try {
                  await navigator.clipboard.writeText(mytext);
                  this.$store.dispatch('form/result', {isError: false, message: 'Comparte la url copiada'});
                } catch($e) {
                  this.$store.dispatch('form/result', {isError: true, message: 'No pude copiar'});
                }
            },
             share(){
                var url_t = process.env.VUE_APP_SHARE_BASE +'?id=' + this.$route.params.id.toString();
                if(this.info.platform == 'web'){
                    this.copyURL(url_t);
                }else{
                    Share.share({
                          title: 'Radio ' + process.env.VUE_APP_RADIO,
                          text: 'Te invito a escuchar la mejor programación en Radio ' + process.env.VUE_APP_RADIO,
                          url: url_t,
                          dialogTitle: 'Invitacion',
                        }).then(response => {
                                console.log(response)
                            })
                            .catch(err => {
                               console.log(err)
                                this.$store.dispatch('form/submit', false);
                        });
                }
                
            },
            getRadioInfo(){
                this.$store.dispatch('form/submit', true);
                   RadioService.getRadioByKey().then((response) => {
                        this.radio = response.data;
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            getEstacionesInfo(){
                this.$store.dispatch('form/submit', true);
                   RadioService.getStationsByRadioKeyNEW().then((response) => {
                        this.current_estaciones = response.data;
                        this.estacionSeleccionada = this.getEstacionById(this.$route.params.id);
                        this.play();
                        const iframe1 = document.getElementById("myIframe");
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },

            myHandler(error) {
                  document.getElementById('myIFrameXXX').style.display = 'none';
                  console.log('E:iframe : ' + error);
                  return true;
                },
            getEstacionById(id){
                console.log("ges estacion en radio page", id)
               return  this.current_estaciones.find(item => item.estacion_id === parseInt(id));
            },
            stop(){
                //this.$router.replace({name: 'home'});

                this.$store.dispatch('song/set-current', -1);

                this.$store.dispatch('song/set-current', {  activo: null,
                            artista:null,
                            artista_id:null,
                            cancion:null,
                            cancion_id:null,
                            fecha_creacion_cancion:null,
                            fecha_ingreso:null,
                            genero:null,
                            genero_id:null,
                            nombre:null,
                            portada:null,
                            vimeo_id:null,
                            url_cancion:null,
                        });

                this.$store.dispatch('song/set-is-playing', false);
                this.$store.dispatch('song/set-is-radio', false);
                
                //this.songPlaying = null;

            },
            play() {
                if(this.songPlaying == null ){
                    var estacion = {  activo: true,
                                        artista: {'nombre': this.radio.name, 'apellido': ''},
                                        artista_id: -99,
                                        cancion:this.estacionSeleccionada.url_audio,
                                        url_cancion: null,
                                        cancion_id:-99,
                                        fecha_creacion_cancion:2002,
                                        fecha_ingreso:2002,
                                        genero: {'nombre': this.estacionSeleccionada.name},
                                        genero_id:-99,
                                        nombre: this.estacionSeleccionada.name,
                                        portada: this.st+this.estacionSeleccionada.nuevo_poster,
                                        vimeo_id: -2,
                                        estacion_id: this.estacionSeleccionada.estacion_id,
                                    }
                    this.$store.dispatch('song/set-current', estacion);
                    this.$store.dispatch('song/set-is-playing', true);
                    this.$store.dispatch('song/set-is-radio', true);
                }else{
                    if(this.radio != null){
                        this.$store.dispatch('song/set-current', -1);
                        this.$store.dispatch('song/set-is-playing', false);
                         var estacion = {  activo: true,
                                        artista: {'nombre': this.radio.name, 'apellido': ''},
                                        artista_id: -99,
                                        cancion: this.estacionSeleccionada.url_audio,
                                        url_cancion: null,
                                        cancion_id:-99,
                                        fecha_creacion_cancion:2002,
                                        fecha_ingreso:2002,
                                        genero: {'nombre': this.estacionSeleccionada.name},
                                        genero_id:-99,
                                        nombre: this.estacionSeleccionada.name,
                                        portada: this.st+this.estacionSeleccionada.nuevo_poster,
                                        vimeo_id: -2,
                                        estacion_id: this.estacionSeleccionada.estacion_id,
                                    }
                          this.$store.dispatch('song/set-current', estacion);
                        this.$store.dispatch('song/set-is-playing', true);
                        this.$store.dispatch('song/set-is-radio', true);
                    }
                }
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
            back(){
                this.$router.replace({name: 'home'});
                if(this.audio !== null){
                    this.audio.pause();
                    this.audio = null;
                }
            },
            loadStartCallBack(){
                console.log('loadStart calllllllllllll');
            },
            openInstagram(){
                const browser_instagram = InAppBrowser.create(this.radio.url_instagram , '_blank', options)
            },

            openWebSite(){
                const browser_web = InAppBrowser.create(this.radio.url_web, '_blank', options);
                browser_web.on('loadstop').subscribe(event => {
                        console.log('WEBBBBBBBB termino cargar ');
                    });    
            },

            shareapp(){
                if(info.platform == 'android'){
                    const browser_android = InAppBrowser.create(this.radio.url_compartir_android, '_blank', options);
                }
                if(info.platform == 'ios'){
                     const browser_ios = InAppBrowser.create(this.radio.url_compartir_ios, '_blank', options);
                }
            }
        };
        this.components = {Backgroundcustom, ProgramationByRadio, TituloPage};
        this.computed = {
            getEstacionSeleccionada(){
                return this.estacionSeleccionada;
            },
            getIsPlaying(){
                return this.playing;
            },
            ...mapGetters({
                songPlaying: 'song/current',
                playing: 'song/is_playing',
                pausa: 'song/pausa',
                currentTime: 'song/current_time',
                durationTime: 'song/duration_time',
                estaciones: 'radio/estaciones',
                radio_info: 'radio/radio',
            })
        };
        this.watch = {
            $route (to, from){
                console.log(to);
                console.log(from);
                if(this.audio != null)
                    this.audio.pause();
    
            },
        };

        this.props = ['device'];
    }).apply(RadioPage);

    export default RadioPage
</script>
