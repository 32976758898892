const state = () => ({
	estaciones: null,
	estaciones_new: null,
	generos: null,
	daytime: null,
	djs: null,
	radio: null,
	lost_conection: null, 
	current: null,
	favoritos: null,
	agrege_fav: false,
	programacion: null,
	generos_cargados: [],
	artistas_cargados: [],
	daytime_cargados: [],
});
 
const getters = {
	'estaciones'(state) {
		return state.estaciones
	},
	'estaciones_new'(state) {
		return state.estaciones_new
	},
	'generos'(state) {
		return state.generos
	},
	'daytime'(state) {
		return state.daytime
	},
	'djs'(state) {
		return state.djs
	},
	'radio'(state) {
		return state.radio
	},
	lost_conection(state){
		return state.lost_conection
	},
	current(state){
		return state.current
	},
	favoritos(state){
		return state.favoritos
	},
	agrege_fav(state){
		return state.agrege_fav
	},
	programacion(state){
		return state.programacion
	},
	generos_cargados(state){
		return state.generos_cargados
	},
	daytime_cargados(state){
		return state.daytime_cargados
	},
	artistas_cargados(state){
		return state.artistas_cargados
	},
};
 
const actions = {
	'set-estaciones-new'(context, value) {
		context.commit('set-estaciones-new', value)
	},
	'set-estaciones'(context, value) {
		context.commit('set-estaciones', value)
	},
	'set-generos'(context, value) {
		context.commit('set-generos', value)
	},
	'set-daytime'(context, value) {
		context.commit('set-daytime', value)
	},
	'set-djs'(context, value) {
		context.commit('set-djs', value)
	},
	'set-radio'(context, value) {
		context.commit('set-radio', value)
	},
	'set-lostconect'(context, value) {
		context.commit('set-lostconect', value)
	},
	'set-current'(context, value) {
		context.commit('set-current', value)
	},
	'set-favoritos'(context, value) {
		context.commit('set-favoritos', value)
	},
	'set-agrege-fav'(context, value) {
		context.commit('set-agrege-fav', value)
	},
	'set-programacion'(context, value) {
		context.commit('set-programacion', value)
	},
	'set-artistas-cargados'(context, value) {
		context.commit('set-artistas-cargados', value)
	},
	'set-generos-cargados'(context, value) {
		context.commit('set-generos-cargados', value)
	},
	'set-daytime-cargados'(context, value) {
		context.commit('set-daytime-cargados', value)
	}
};
 
const mutations = {
	'set-estaciones-new'(state, value) {
		state.estaciones_new = value
	},
	'set-estaciones'(state, value) {
		state.estaciones = value
	},
	'set-generos'(state, value) {
		state.generos = value
	},
	'set-daytime'(state, value) {
		state.daytime = value
	},
	'set-djs'(state, value) {
		state.djs = value
	},
	'set-radio'(state, value) {
		state.radio = value
	},
	'set-lostconect'(state, value) {
		state.lost_conection = value
	},
	'set-current'(state, value) {
		state.current = value
	},
	'set-favoritos'(state, value) {
		state.favoritos = value
	},
	'set-agrege-fav'(state, value) {
		state.agrege_fav = value
	},
	'set-programacion'(state, value) {
		state.programacion = value
	},
	'set-artistas-cargados'(state, value) {
		state.artistas_cargados = value
	},
	'set-generos-cargados'(state, value) {
		state.generos_cargados = value
	},
	'set-daytime-cargados'(state, value) {
		state.daytime_cargados = value
	}
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}