<template id="home">
    <span>
        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-12">
                <img class="mt-4 z-index-0 custom-background-class" alt="no icon" src="@/assets/portada.jpg" style="height: 7rem !important;">
            </div>
        </div>
    </span>     
</template>


<script>
    import Backgroundcustom from '@/components/Backgroundcustom'
    import {mapGetters} from 'vuex';
    


    let RedirectRadio = {};
    (function () {
        this.name = 'home-page';

        this.created = function () {
            console.log('idididi', this.$route.params.id);
            this.goToRadio('radio', this.$route.params.id)
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               
            }
        };
        this.methods = {

              goToRadio: function (name, id) {
                this.$store.dispatch('radio/set-current', id);
                this.$store.dispatch('radio/set-lostconect', false)
                this.$router.push({name: name,
                   params: {
                        id: id
                    }
               });
            },

        };
        this.components = {Backgroundcustom};
        this.computed = {
             ...mapGetters({
               // groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        

        this.props = ['device'];
    }).apply(RedirectRadio);

    export default RedirectRadio
</script>
