<template id="home">
    <span>
        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

        <TituloPage :nombre="'GÉNEROS MUSICALES'" @back="back()">
            <template v-slot:share>
                <share-component></share-component>
            </template>
        </TituloPage>

        <div class="grid ml-4 mr-4">
            <div class="col-12 md:col-12 lg:col-12">
                <p class="text-white text-xs font-bold">Escucha los generos musicales de la radio San nicolas y Aconcagua</p>
            </div>  
        </div>  


        <div class="grid mt-4 mr-3 ml-2">
            <div class="col-6 md:col-1 lg:col-1" v-for="(g, idx) in generos" :key="idx" >
                <ButtonPrime :label="g.nombre" @click.prevent="goTo('artist-by-genero', g.genero_id, g.nombre)" class="p-button-rounded p-button-info ml-2" style="width:100%"/>
            </div>
        </div>

        <p class="text-3l font-bold text-white" v-if="generos && generos.length == 0">
            Sin resultados.
        </p>
    </span>     
</template>


<script>

    import Backgroundcustom from '@/components/Backgroundcustom';
    import GenerosService from '@/service/GenerosService';
    import {mapGetters} from 'vuex';

    import ShareComponent from '@/components/ShareComponent.vue';
    import TituloPage from '@/components/TituloPage';


    let SelectGenero = {};
    (function () {
        this.name = 'home-page';
        // let _self = null;

        this.created = function () {
            console.log(this.generos);
            if(this.generos == null){
                this.getAllGenerosDayTime(null);
            }
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
                st: process.env.VUE_APP_STATIC
            }
        };
        this.methods = {
            getAllGenerosDayTime(day){
                this.$store.dispatch('form/submit', true);
               GenerosService.all(day).then((response) => {
                    //this.generos = response.data;
                    this.$store.dispatch('radio/set-generos', response.data);
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },

            back() {
                this.$router.push({name: 'home'});
            },
            goTo: function (route, id, name) {
                this.$router.push({name: route,
                   params: {
                        id: id,
                        nombre: name,
                    }
               });
            }
        }; 

        this.components = {Backgroundcustom, ShareComponent, TituloPage};

        this.computed = {
            ...mapGetters({
               generos: 'radio/generos'
            })
        };
        this.watch = {
             
        };
        

        this.props = ['device'];
    }).apply(SelectGenero);

    export default SelectGenero
</script>
