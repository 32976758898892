<template>
  <div class="offline-music">
    <backgroundcustom :fondo="1" :device="device"></backgroundcustom>
    <h1 class="text-white">Música offline</h1>


           <TituloPage :nombre="'Música offline'" @back="back()">
            <template v-slot:share>

            </template>
        </TituloPage>


     <TabView ref="tabview1" style="background: transparent;">
          <TabPanel header="MUSICA" style="background: transparent;"  @click="init()">
             <span v-if="isDataLoaded && getFilteredSongs">
            
              <InputText class="mt-3" v-model="searchQuery" placeholder="Buscar por Genero Artista o nombre" style="width: 90%;"/>

                    <h1 class="text-white" v-if="getFilteredSongs.length == 0">Sin Canciones</h1>

                      <ul style="width: 100% !important;" class="mt-5">
            <li v-for="(s, index) in getFilteredSongs" :key="index" class="grid p-d-flex p-ai-center p-jc-between" style="flex-wrap: nowrap;">
                <div class="p-col-1 p-d-flex p-ai-center">
                    <img src="@/assets/logo-radio.png" style="width:17px; height: 17px;">
                </div>
                <div class="p-col-8 p-d-flex p-ai-center ml-2 mr-2" style="word-wrap: break-word; overflow-wrap: break-word; overflow: hidden; text-overflow: ellipsis; font-size: 1em; text-align: left;" @click="reproducirCancion_file(s.uri, s)">
                    {{formatNameNew(s.name)}}
                </div>
                <div class="p-col-3 p-d-flex p-jc-end ml-2" @click="eliminarCancion_file_new(s)">X</div>
            </li>
        </ul>
        </span>

          </TabPanel>
          <TabPanel header="DESCARGAS" style="background: transparent;">
            <OfflineMusicList/>
          </TabPanel>
      </TabView>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import OfflineMusicList from '@/components/OfflineMusicList'; 
import Backgroundcustom from '@/components/Backgroundcustom';
import { Filesystem, Directory } from '@capacitor/filesystem';
import {mapGetters} from 'vuex';
import TituloPage from '@/components/TituloPage';

export default {
  name: 'OfflineMusic',
  components: { Backgroundcustom, OfflineMusicList,TituloPage },
  props: ['device'],
  data() {
    return {
      offline_canciones: [],
      canciones_file: [],
      canciones: [],
      searchQuery: '',
       activeIndex: 0,
       filteredSongs: [],
        isDataLoaded: false, 
    };
  },

mounted() { 
    this.init();
  },

created() { 
  //this.canciones_file = [{"ctime":"1686196042110","uri":"file:///private/var/mobile/Containers/Data/Application/6B356626-65F9-495D-92C4-290B2CAA3842/Documents/canciones/137__Folklore__Dj_Andes-Danzas_Tradicionales_De_Chile_Norte_Centro_y_Isla_De_Pascua.mp3","mtime":"1686196042125","size":17430175,"name":"137__Folklore__Dj_Andes-Danzas_Tradicionales_De_Chile_Norte_Centro_y_Isla_De_Pascua.mp3","type":"file"}];
   //this.filteredSongs = this.canciones_file;
},
 watch:{
    searchQuery(newValue) {
      if (!this.canciones_file || newValue.length < 3) {
         // Limpia filteredSongs antes de filtrar
        this.filteredSongs = this.canciones_file;
          return;
        
      }

      this.filteredSongs = []; // Limpia filteredSongs antes de filtrar
      for (let i = 0; i < this.canciones_file.length; i++) {
        if (this.canciones_file[i].uri.toLowerCase().includes(newValue.toLowerCase())) {
          this.filteredSongs.push(this.canciones_file[i]);
        }
      }

    }
  },
  computed:{
    getFilteredSongs(){
      return this.filteredSongs;
    },
    getCancionesfile(){
      return this.canciones_file;
    },
    ...mapGetters({
                songPlaying: 'song/current',
            })
  },
  onTabChange(e){
    console.log("Music tab was clicked");
    if (e.index === 0) {  // Adjust the index according to your setup.
      
      console.log('onMusicTabClicked');
    this.isDataLoaded = false;
    this.ini();
    }
    
  },
  methods: {

    back(){
       this.$router.push({name: 'home'});
    },

                
    handleTabChange(index) {
      console.log('inicio');
      this.activeIndex = index;
      if (this.activeIndex === 0) { 
        this.init();  
      }
},

async getDirectory(directoryName, options) {
  try {
    // Check if directory already exists
    const statResult = await Filesystem.stat({
      path: directoryName,
      directory: Directory.Documents
    });
    // If directory exists, return the stat result
    if (statResult.type === 'directory') {
      return statResult;
    }
  } catch (error) {
    // Handle error (directory not found)
    console.log(`Directory ${directoryName} not found, creating it...`);
  }

  try {
    // Create the directory
    const result = await Filesystem.mkdir({
      path: directoryName,
      directory: Directory.Documents,
      recursive: options.recursive || false
    });
    return result;
  } catch (error) {
    console.log(`Error creating directory ${directoryName}: ${error.message}`);
    throw error;
  }
},

 async init(){
      // Obtener la lista de archivos en el directorio de canciones
    try {
      const directoryPath = 'canciones';
      console.log(111);
      await this.getDirectory(directoryPath, { recursive: false });
        const response = await Filesystem.readdir({
            path: directoryPath,
            directory: Directory.Documents,
        });

      var filtered  = [];
      const canciones_file_todas = response.files.filter((archivo) => archivo.type === 'file');

      console.log(canciones_file_todas.length);

      console.log(222);

      for (var i = 0; i < canciones_file_todas.length; i++) {
          console.log(333);
          console.log(canciones_file_todas[i].name);
          let segments = canciones_file_todas[i].name.split('__');

          var parte = segments[segments.length - 1]; // Obtener la última 
          var match = parte.match(/(\d+)\.[^.]+$/);

          var  idCancio = segments[0];
          var  genero = segments[1];
          var  artista = segments[2];
          var  nombre = segments[3];
          var  parte = segments[4];

          console.log(444);
          console.log(match);

          if(match){
            if(match[1] == 0){
              filtered.push(canciones_file_todas[i]);
            }
        
            console.log(555);
            this.offline_canciones.push({  activo: true,
                                    artista: {'nombre': 'Musica', 'apellido': 'Offline'},
                                    artista_id: -88,
                                    cancion: canciones_file_todas[i].uri,
                                    url_cancion: null,
                                    cancion_id:idCancio,
                                    fecha_creacion_cancion:2002,
                                    fecha_ingreso:2002,
                                    genero: {'nombre': ''},
                                    genero_id:-88,
                                    nombre: nombre,
                                    portada: null,
                                    vimeo_id: -2,
                                    estacion_id: 0,
                                    parte: match[1],
                                    offline: 1,
                                });

        }

      }
        console.log(666);
        console.log(filtered);
        this.filteredSongs = filtered;
        this.isDataLoaded = true;
      } catch (error) {
        console.log('Error al leer el directorio:', error);
      }


    },


eliminarCancion_file_new(song) {
    let relativePath = song.uri.substring(song.uri.indexOf('/canciones/'));
    let id = relativePath.split('__')[0].substring(relativePath.lastIndexOf('/') + 1);

    console.log(id);

    // Obtén una lista de todos los archivos en el directorio
    Filesystem.readdir({
        path: 'canciones',
        directory: Directory.Documents
    })
    .then(files => {
        console.log(files.files.length);

        // Filtra los archivos que comparten el mismo ID de canción
        let filesToDelete = files.files.filter(file => file.name.startsWith(id));
        console.log(filesToDelete.length);

        // Crea una array de promesas para eliminar cada archivo
        let deletePromises = filesToDelete.map(file => {
            return Filesystem.deleteFile({
                path: '/canciones/' + file.name,
                directory: Directory.Documents
            })
            .catch(error => {
                console.error('Error al eliminar la parte de la canción:', file, error);
            });
        });

        // Espera a que todas las promesas se resuelvan
        return Promise.all(deletePromises);


    })
    .then(() => {
       this.init();
    })
    .catch(error => {
        console.error('Error al eliminar la canción:', error);
        this.canciones_file = this.canciones_file.filter(cancion => !cancion.uri.includes(id));
        this.filteredSongs = this.canciones_file;
    });
},


    async eliminarCancion_file(song) {
        try {
            let relativePath = song.uri.substring(song.uri.indexOf('/canciones/'));
            await Filesystem.deleteFile({
                path: relativePath,
                directory: Directory.Documents
            });

            this.canciones_file = this.canciones_file.filter(cancion => cancion.uri !== song.uri);
            this.filteredSongs = this.canciones_file;
            
        } catch (error) {
            console.error('Error al eliminar la canción:', error);
            this.canciones_file = this.canciones_file.filter(cancion => cancion.uri !== song.uri);
            this.filteredSongs = this.canciones_file;
        }
    },

    formatNameNew(name) {
        let segments = name.split('__');
        if (segments.length >= 5) {
            const [_, genero, artista, nombreCompleto, partNumber] = segments;
            const formattedSegments = [genero, artista, nombreCompleto].filter(seg => seg !== 'null' && seg !== undefined && seg.trim() !== '');
            return formattedSegments.join(' ').replace(/__/g, ' ').replace(/-/g, ' ').replace(/0/g, '').replace(/_/g, ' ');
        }
        console.log('Nombre no tiene suficientes segmentos');
        return name; 
    },

     formatName(name) {
        let segments = name.split('__');
        if (segments.length >= 5) {
          const [_, genero, artista, __, nombre, parte] = segments;

          const formattedSegments = [genero, artista, nombre].filter(seg => seg !== 'null');
          let partNumber = parseInt(parte);
          if (!isNaN(partNumber)) {
            partNumber += 1;
            formattedSegments.push(partNumber);
          }
          return formattedSegments.join(' ').replace(/__/g, ' ');
        }
        console.log('Nombre no tiene suficientes segmentos');
        return name; 
      },

     removeDoubleSlashes(path) {
        return path.replace(/\/\//g, '/');
      },

     play(fileuri, raw_name){
          var name = this.formatNameNew(raw_name);

          let segments = raw_name.split('__');

          var parte = segments[segments.length - 1]; // Obtener la última 
          var match = parte.match(/(\d+)\.[^.]+$/);

          var  idCancio = segments[0];
          var  genero = segments[1];
          var  artista = segments[2];
          var  nombre = segments[3];

          if(match){
              var offline_cancion = {  activo: true,
                                    artista: {'nombre': 'Musica', 'apellido': 'Offline'},
                                    artista_id: -88,
                                    cancion: fileuri,
                                    url_cancion: null,
                                    cancion_id: idCancio,
                                    fecha_creacion_cancion:2002,
                                    fecha_ingreso:2002,
                                    genero: {'nombre': ''},
                                    genero_id:-88,
                                    nombre: name,
                                    portada: null,
                                    vimeo_id: -2,
                                    estacion_id: 0,
                                    parte: match[1],
                                    offline: 1,
                                };
    
                    this.$store.dispatch('song/set-list-songs', this.offline_canciones);
                    this.$store.dispatch('song/set-current', offline_cancion);
                    this.$store.dispatch('song/set-is-playing', true);
                    this.$store.dispatch('song/set-is-radio', false);


            }
        
            },
            async reproducirCancion(ruta_archivo, name) {
                try {

                  const uri = await Filesystem.getUri({
                    path: ruta_archivo,
                    directory: Directory.Documents
                  }); 

                  const fileUrl = Capacitor.convertFileSrc(uri.uri);
                  this.play(fileUrl, name);
                  //const audio = new Audio(fileUrl);
                  //audio.play();
               } catch (error) {
                  console.error('Error al reproducir la canción:', error);
                }
              },
              async reproducirCancion_file(ruta_archivo, s) {
                try {
                    const ruta_archivo____ = Capacitor.convertFileSrc(ruta_archivo);
                    console.log(ruta_archivo____);
                  //const audio = new Audio(ruta_archivo____);
                  //audio.play();
                  this.play(ruta_archivo____, s.name);
                } catch (error) {
                  console.error('Error al reproducir la canción reproducirCancion_file reproducirCancion_file:', error);
                }
              },

  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
}
li:last-child {
  border-bottom: none;
}
span {
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
}
button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 0.375rem;
  background-color: #2ecc71;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
button:hover {
  background-color: #27ae60;
}
</style>
