<template>
    <span>
        <ProgressBar  class="z-index-5 aquictm" :showValue="false" style="height: 4px  !important; background-color: #0e0033; z-index: 99999; top: 80px; left: 0; position: fixed; right: 0; " mode="indeterminate"/>

        <div class="loader-container z-4" style="z-index: 99999 !important;">
            <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

            <ProgressSpinner style="width:50px;height:50px;z-index: 99999 !important; " strokeWidth="8" animationDuration=".5s"/>
            <h3 class="text-white" style="z-index: 99999 !important;">{{message}}</h3>

            <ButtonPrime @click="closeLoader" style="z-index: 99999 !important;">Cerrar</ButtonPrime>
        </div>
    </span>
</template>

<script>
     import Backgroundcustom from '@/components/Backgroundcustom'

    let LoaderMessage = { };
    (function () {

        this.name = 'loader-component';

        this.created = function () {
            
        };
        this.data = function () {
            return {
                 
            }
        };
        this.methods = {
             closeLoader() {
                this.$store.dispatch('form/connect', false);
            }
        };
        this.mounted = function () {};
        this.components = {Backgroundcustom}
        this.computed = {};
        this.watch = {};
        this.props = ['device', 'message'];
    }).apply(LoaderMessage);
    export default LoaderMessage;
</script>


<style scoped>
    .loader-container {
        position: fixed;
        z-index: 1000;
        background-color: transparent;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        flex-direction: column;
        div {
            text-align: center;
        }
    }

    @keyframes p-progress-spinner-color {
        100%,
        0% {
            stroke: white;
        }
        40% {
            stroke: yellow;
        }
        66% {
            stroke: yellow;
        }
        80%,
        90% {
            stroke: red;
        }
}
</style>

