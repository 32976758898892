<template>
  <div>
    <backgroundcustom :fondo="1" :device="device"></backgroundcustom>


      <TituloPage :nombre="'PODCAST'" @back="back()">
            <template v-slot:share>
    
            </template>
        </TituloPage>


    <div class="grid mt-4 mr-3 ml-2">
      <div class="col-12 md:col-12 lg:col-12" v-for="(podcast, idx) in podcasts" :key="idx">

         <div class="grid mt-4 mr-3 ml-2">
           <div class="col-4 md:col-4 lg:col-1">

                  <ImageFallback 
                    @click.prevent="openPodcastDetails(podcast)"
                    style="width: 100%;box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"
                    :imageSource="podcast.portada || podcast.categoria_podcast.imagen"
                    :fallbackSrc="'@/assets/logo-podcast.png'"
                    :alt="'noico'"
                    :class="'z-index-0'"
                    :width="null"
                    :maxHeight="'50px'"
                  />
          </div>

          <div class="col-8 md:col-8 lg:col-11">
            <p class="text-white text-7l font-bold text-white">{{ podcast.titulo }}</p>
            <p class="text-xs" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ podcast.descripcion_corta }}</p>
          </div>

         </div>

        <div v-if="selectedPodcast === podcast" class="podcast-details">
          <p class="text-white">{{ podcast.descripcion_larga }}</p>
          <div v-for="(episodio, idx) in podcast.episodios" :key="idx">
              <div class="grid mt-4 mr-3 ml-2 card-rounded shadow-4">
                  <div class="col-2 md:col-2 lg:col-2">
                      <ImageFallback 
                          style="width: 100%;box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"
                          :imageSource="episodio.imagen || podcast.portada || podcast.categoria_podcast.imagen"
                          :fallbackSrc="'@/assets/logo-podcast.png'"
                          :alt="'noico'"
                          :class="'z-index-0'"
                          :width="null"
                          :maxHeight="'50px'"
                      />

                      <span v-if="current">

                        <p class="text-white text-xs ml-3" v-if="episodio.cancion_id == current.cancion_id && playing == true && current.cancion_id == episodio.cancion_id">{{convertTime(currentTimeSong)}}</p>
                      </span> 
                  </div>
                  <div class="col-8 md:col-8 lg:col-8">
                      <p class="text-white ml-3">{{ episodio.titulo }}</p>
                      <p class="text-white ml-3">{{ episodio.descripcion_corta}}</p>
                      
                      <slider-prime v-if="current && current.cancion_id == episodio.cancion_id && playing == true && current.cancion_id == episodio.cancion_id" v-model="currentTimeSong"  :min="0" :max="totalDurationSong" style="width: 100%; background-color: white !important "/>

                  </div> 
                  <div class="col-2 md:col-2 lg:col-2">
                      <img @click.prevent="play(episodio, podcast)" class="z-index-0 custom-background-class " alt="no icon" src="@/assets/rojo/play.svg" style="height: 2rem !important;">

                       <p class="text-white text-xs mr-4" v-if="current && current.cancion_id == episodio.cancion_id && playing == true && current.cancion_id == episodio.cancion_id">{{convertTime(totalDurationSong)}}</p>
                  </div>
              </div>

          </div>
      </div>





      </div>
    </div>
  </div>
</template>

<script>
import Backgroundcustom from '@/components/Backgroundcustom';
import PodcastService from '@/service/PodcastService';
import ImageFallback from '@/components/ImageFallback.vue';
import {mapGetters} from 'vuex';
import TituloPage from '@/components/TituloPage'

export default {
  name: 'PodcastRadio',

  components: {
    Backgroundcustom,
    ImageFallback,
    TituloPage
  },

  data() {
    return {
      podcasts: [],
      selectedPodcast: null,
      current: null,
    }
  },
  props:['device'],
  created() {
    if(this.songPlaying)
      this.current = this.songPlaying;
    this.getPodcasts();
  },
  computed: {
    getSongPlaying(){
      return this.songPlaying;
    },
    ...mapGetters({
                songPlaying: 'song/current',
                playing: 'song/is_playing',
                pausa: 'song/pausa',
                currentTime: 'song/current_time',
                durationTime: 'song/duration_time',
                artistas_cargados: 'radio/artistas_cargados',
            })
  },
  methods: {
       convertTime(seconds){
                const format = val => `0${Math.floor(val)}`.slice(-2);
                var hours = seconds / 3600;
                var minutes = (seconds % 3600) / 60;
                return [minutes, seconds % 60].map(format).join(":");
            },
     play(episodio, podcast){

      var podcast = {  activo: true,
                                        artista: {'nombre': 'Podcast', 'apellido': ''},
                                        artista_id: -77,
                                        cancion: episodio.cancion,
                                        url_cancion: null,
                                        cancion_id:episodio.orden,
                                        fecha_creacion_cancion:2023,
                                        fecha_ingreso:2023,
                                        genero: {'nombre': ''},
                                        genero_id:-77,
                                        nombre: podcast.titulo + '-' +episodio.titulo,
                                        portada: null,
                                        vimeo_id: -2,
                                        estacion_id: 0,
                                    }
                    this.$store.dispatch('song/set-current', podcast);
                    this.$store.dispatch('song/set-is-playing', true);
                    this.$store.dispatch('song/set-is-radio', false);


      var todos_episodios = []
      if(podcast.episodios){
         for (var i = 0; i < podcast.episodios.length; i++) {
      

          const podcast = {  activo: true,
                                        artista: {'nombre': 'Podcast', 'apellido': ''},
                                        artista_id: -77,
                                        cancion: podcast.episodios[i].cancion,
                                        url_cancion: null,
                                        cancion_id: podcast.episodios[i].orden,
                                        fecha_creacion_cancion:2023,
                                        fecha_ingreso:2023,
                                        genero: {'nombre': ''},
                                        genero_id:-77,
                                        nombre: podcast.titulo + '-' +podcast.episodios[i].titulo,
                                        portada: null,
                                        vimeo_id: -2,
                                        estacion_id: 0,
                                    }
                                todos_episodios.push(podcast)
        
        
      }
                    this.$store.dispatch('song/set-list-songs', null);
                    this.$store.dispatch('song/set-list-songs', todos_episodios);
      }
     
                    
                
            },
    getPodcasts() {
      PodcastService.getall().then((response) => {
        this.podcasts = response.data;
      }).catch((error) => {
        console.log(error);
      });
    },

    openPodcastDetails(podcast) {
      this.selectedPodcast = this.selectedPodcast === podcast ? null : podcast;
    },
            back() {
                this.$router.push({name: 'home'});
                },
  },
  watch:{
            songPlaying(newValue){
                if(newValue){
                  this.current = newValue;
                  this.selectedSong = newValue;
                  this.currentTimeSong = 0;
                }
           },

            currentTime(newValue){
                if(newValue){
                    if(((newValue + 15 < this.currentTimeSong) || (newValue - 15 > this.currentTimeSong) ) && this.currentTimeSong>0){
                        this.$store.dispatch('song/set-new-time', this.currentTimeSong);
                    }else{
                        this.currentTimeSong = newValue;
                    }
                }
            },
            durationTime(newValue){
                if(newValue){
                    this.totalDurationSong = newValue;
                }
            }
        },
}
</script>
