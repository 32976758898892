const state = () => ({
	djs: null,
    seleccionado: null,
    alltime: null,
    moment: null,
});
 
const getters = {
	'djs'(state) {
		return state.djs
	},
	'seleccionado'(state) {
		return state.seleccionado;
	},
	'alltime'(state) {
		return state.alltsime;
	},
    'moment'(state) {
		return state.moment;
	},
};
 
const actions = {
	'set-seleccionado'(context, value) {
		context.commit('set-seleccionado', value)
	},
	'set-djs'(context, value) {
		context.commit('set-djs', value)
	},
    'set-alltime'(context, value) {
		context.commit('set-alltime', value)
	},
    'set-moment'(context, value) {
		context.commit('set-moment', value)
	},
};
 
const mutations = {
	'set-seleccionado'(state, value) {
		state.seleccionado = value
	},
	'set-djs'(state, value) {
		state.djs = value
	},
	'set-alltime'(state, value) {
		state.alltime = value
	},
	'set-moment'(state, value) {
		state.moment = value
	}
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}