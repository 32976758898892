<template>
    <span>
        <tool-bar class="fixed top-0 ml-0 w-12 h-5rem"  style="z-index: 200; background-color: #f90101 !important; border: 0px solid #0e0033;">

        <template #start>
        <img @click.prevent="goTo('home')" class="mt-4" alt="icono corporativo" src="@/assets/logo.svg" style="width: 35px !important;">
     <div class="super-frase-container" style="position: fixed; top: 58px; left: 50%; transform: translate(-50%, -50%); z-index: 1000;">
            <super-frase class="mt-6"></super-frase>
        </div>
        </template>
        
        <template #end>

             <span v-if="authenticated == true">
                 <!--i v-if="user_id != null " class="pi pi-bell text-white mr-2 mt-4" style="font-size: 16px; color: white" @click.prevent="openNotification()"><span style="font-size:16px;color: red !important; margin-left: -1px;" v-if="getCountNoLeido > 0"><strong>{{getCountNoLeido}}</strong></span-->        
                
                <img @click.prevent="goTo('user-profile')" class="mt-4" alt="icono corporativo" src="@/assets/rojo/perfil.svg" style="width: 30px !important;">
            
        
            </span>
            <span v-else class="mt-4">
                <button-prime class=" p-button-link text-xs" label="Ingresar" @click.prevent="goTo('login-email')" style="font-weight: 800; color: #FAFBE4;"/>
            </span>
            
        </template>

           

        </tool-bar>

        
        <side-bar v-model:visible="visibleNotifications" position="full" :showCloseIcon="True" class="bg-primary-cus text-black" >
                <ProgressBar v-if="cargando_noti == true"  class="z-index-5" :showValue="false" style="height: 4px  !important; background-color: white; z-index: 99999; top: 80px; left: 0; position: fixed; right: 0; " mode="indeterminate"/>

                <div class="card">
                    <h5 class="text-white">Notificaciones</h5>


                    <TabView ref="tabview1" style="background: transparent;">
                        <TabPanel header="NUEVAS" style="background: transparent;">
                            <p>NUEVAS NOTIFICACIONES.</p>
                            <span v-if="notis_no_leidas.length == 0">No tienne</span>
                            <span v-else>
                                <span v-for="(d, idx) in notis_no_leidas" :key="idx">
                                    <p @click.prevent="lookNotification(d)">{{d.fecha_envio}}: {{d.tema}} <i class="pi pi-sort-amount-down mr-2 mt-2" style="font-size: 18px;"></i></p>

                                    <span><p v-if="selectedNoti.notificacion_id == d.notificacion_id">{{d.mensaje}}</p></span>

                                </span>
                            </span>
                        </TabPanel>
                        <TabPanel header="LEIDAS" style="background: transparent;">
                            <p>NOTIFICACIONES LEIDAS.</p>
                            
                            <span v-if="notis_leidas.length == 0">No tienne</span>
                            <span v-else>
                                <span v-for="(x, idx) in notis_leidas" :key="idx">
                                    <p @click.prevent="lookNotificationleida(x)">{{x.fecha_envio}}: {{x.tema}} <i class="pi pi-sort-amount-down mr-2 mt-2" style="font-size: 18px;"></i></p>

                                    <span><p v-if="selectedNoti.notificacion_id == x.notificacion_id">{{x.mensaje}}</p></span>
                                </span>
                            </span>
                        </TabPanel>
                    </TabView>
                </div>
        </side-bar>

        <side-bar v-model:visible="visibleRight" position="left" :showCloseIcon="false" class="bg-primary-cus text-black" >
            <menu-prime :model="items" class="bg-primary-cus"/>
        </side-bar>
        
    </span>
</template>

<script>

    import {mapGetters} from 'vuex';
    import GenerosService from '@/service/GenerosService';
    import RadioService from '@/service/RadioService';
    import SuperFrase from '@/components/SuperFrase';

    let ToolbarComp = {};
    (function () {

        this.name = 'tooolbar';

        this.created = function () {
            //this.getAllGeneros();
            if(this.user_id != null){
                this.tengoNotificacionesNoleidas(this.user_id);
            }
            
        };
        this.data = function () {
            return {
                selectedColaborator: null,
                all_colaboratrs: [],
                filteredEmployeeMultiple: [],
                visibleRight: false,
                visibleNotifications: false,
                generos: [],
                carganbdo_noti: false,
                all_notis_user: [],
                notis_leidas: [],
                notis_no_leidas: [],
                selectedNoti: {'notificacion_id' : null},
                cant_notis_no_lidas: 0,
                    items: [
                    {
                        label: 'Radio ' + process.env.VUE_APP_RADIO,
                        items: [{label: 'Inicio', icon: 'pi pi-hm pi-home', 
                        command: () => {this.$router.push({name: 'home'}); this.visibleRight = false;
                            }
                        },
                        {label: 'Offline', icon: 'pi pi-hm pi-home', 
                        command: () => {this.$router.push({name: 'offline-music'}); this.visibleRight = false;
                            }
                        },
                        {label: 'Perfil', icon: 'pi pi-hm pi-user', 
                        command: () => {this.$router.push({name: 'user-profile'}); this.visibleRight = false;
                            }
                        }
                                //{label: 'Salir', icon: 'pi pi-fw pi-power-off', to: '/logout'},
                                ]
                    },
                    {
                        label: 'Estaciones',
                        items: [
                        {label: 'Adulto', icon: 'pi pi-fw pi-plus',  command: () => {this.$router.push({name: 'redirect-radio',params: {id: 1}});
                            this.visibleRight = false;} },
                        {label: 'Juvenil', icon: 'pi pi-fw pi-plus',  command: () => {this.$router.push({name: 'redirect-radio',params: {id: 2}});
                            this.visibleRight = false;} },
                        {label: 'Infantil', icon: 'pi pi-fw pi-plus', command: () => {this.$router.push({name: 'redirect-radio',params: {id: 3}});
                            this.visibleRight = false;} },
                        ]
                    },
                    {
                        label: 'Artistas',
                        items: [
                                                {
                        label: '',
                        items: [
                             {label: 'Todos', icon: 'pi pi-fw pi-plus',  command: () => {this.$router.push({name: 'select-artist-type'});
                            this.visibleRight = false;} },
                            
                        {label: 'DJ´s', icon: 'pi pi-fw pi-plus',  command: () => {this.$router.push({name: 'djs'});
                            this.visibleRight = false;} },

                           

                             {label: 'Salir', icon: 'pi pi-fw pi-power-off',  command: () => {this.$router.push({name: 'logout'});
                            this.visibleRight = false;} },
                        ]
                    },

                        ]
                    }
                ],  
            }
        };
        this.methods = {
            lookNotification(noti){
                    if(this.selectedNoti.notificacion_id == noti.notificacion_id){
                        this.selectedNoti =  {'notificacion_id': null};
                    }else{
                        this.selectedNoti = noti
                        this.marcarLEida(noti.notificacion_id);
                    }
            },
            lookNotificationleida(noti){
                    if(this.selectedNoti.notificacion_id == noti.notificacion_id){
                        this.selectedNoti =  {'notificacion_id': null};
                    }else{
                        this.selectedNoti = noti
                    }
            },
            marcarLEida(id){
                 RadioService.marcarNotificacionLeida(id).then((response) => {
                        console.log('noti',response.data)
         
                       this.tengoNotificacionesNoleidas(this.user_id);
            
                }).catch((error) => {
                    console.log(error);
                });
            },
            openNotification(){
                this.visibleNotifications = true;
                this.carganbdo_noti = true;
                this.getAllNotiuser(this.user_id);
                this.notis_leidas = [] 
                this.notis_no_leidas = []
                this.all_notis_user = []

            },
            goToRedirectPlayList: function (id, name, url) {
                this.$router.push({name: 'redirect-playlist-genero',
                   params: {
                        id: id,
                        name: name,
                        img: url,
                    }
               });
            },
            getAllNotiuser(id){
               RadioService.getTodasNotificacionesUsuario(id).then((response) => {
                  
                    this.all_notis_user = response.data;
                    for (var i = 0; i < this.all_notis_user.length; i++) {
                        if(this.all_notis_user[i]['leido']== true){
                            this.notis_leidas.push(this.all_notis_user[i]);
                        }else{
                            this.notis_no_leidas.push(this.all_notis_user[i]);
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            tengoNotificacionesNoleidas(id){
               RadioService.tengoNotificacion(id).then((response) => {
                        this.cant_notis_no_lidas = response.data;
                }).catch((error) => {
                    console.log(error);
                });
            },
            getAllGeneros(){
               GenerosService.all().then((response) => {
                    this.generos = response.data;
                    this.items[3].items = [];
                    for (var i = 0; i < response.data.length; i++) {
                        var item = {  label: response.data[i]['nombre'], 
                                        id_genero: response.data[i]['genero_id'], 
                                        porta: response.data[i]['portada'],
                                        thisss: this,
                                        icon: 'pi pi-fw pi-plus',  
                                        command: function(){
                                            this.thisss.visibleRight = false;
                                            this.thisss.goToRedirectPlayList(this.id_genero, this.label, this.porta)
                                        }
                                    };
                        this.items[3].items.push(item);
                    }
                }).catch((error) => {
                    console.log(error);
                    
                });
            },
            goHome(){
                this.$router.push({name: 'home'});
            },
            openSideBar(){
                this.visibleRight = true;
                //this.getAllGeneros();
            },
            logOut() {
                this.$router.push({name: 'logout'});
            },
            goTo: function (route) {
                this.$router.push({name: route});
            }
        };
        this.mounted = function () {};
        this.components = {SuperFrase}
        this.computed = {
            getCountNoLeido(){
                return this.cant_notis_no_lidas;
            },
            ...mapGetters({
                 authenticated: 'auth/check',
                 user_id: 'auth/user_id',

            })
        };
        this.watch = {
            user_id(newValue){
                console.log(newValue)
                this.tengoNotificacionesNoleidas(newValue);
            }
        };
        this.props = [];
    }).apply(ToolbarComp);
    export default ToolbarComp;
</script>

<style scoped>


.p-menu.p-submenu-header{
    background-color: white !important;
}

.p-submenu-header{
    background-color: white !important;
}


</style>
