	import { createApp } from 'vue'
import Appp from './App.vue';
import router from './router';
import store from './store/index';
// import Axios from 'axios';

import ToolBar from 'primevue/toolbar';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import PrimeVue from 'primevue/config';
import Sidebar from 'primevue/sidebar';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password'; 
import Menu from 'primevue/menu';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import FullCalendar from 'primevue/fullcalendar';

import InputSwitch from 'primevue/inputswitch';
import FileUpload from 'primevue/fileupload';
import Calendar from 'primevue/calendar';
import ProgressSpinner from 'primevue/progressspinner';
import Message from 'primevue/message';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import DialogPrime from 'primevue/dialog';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip';
import Tag from 'primevue/tag';
import ProgressBar from 'primevue/progressbar';
import Checkbox from 'primevue/checkbox';
import ImagePrime from 'primevue/image';
import SliderPrime from 'primevue/slider';
import Card from 'primevue/card';


import "primevue/resources/themes/bootstrap4-light-purple/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import 'animate.css';

import Axios from "axios";

import AuthService from "./service/AuthService"
import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite } from '@capacitor-community/sqlite';
//import { Doze } from 'cordova-plugin-doze-optimize';
	
//var exec = require('cordova-plugin-doze-optimize/src/www');
//import { PowerOptimization } from '@awesome-cordova-plugins/power-optimization/ngx';


const app = createApp(Appp).use(router);
app.use(PrimeVue);
app.use(store);
app.use(ConfirmationService);

//BackgroundMode.enable();
app.component('CardPrime', Card);
app.component('ToolBar', ToolBar);
app.component('ButtonPrime', Button);
app.component('SplitButton', SplitButton);
app.component('SideBar', Sidebar);
app.component('InputText', InputText);
app.component('PassWord', Password);
app.component('MenuPrime', Menu);
app.component('DropDown', Dropdown);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('DataTable', DataTable);
app.component('ColumnPrime', Column);
app.component('InputSwitch', InputSwitch);
app.component('FileUpload', FileUpload);
app.component('CalendarPrime', Calendar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('MessagePrime', Message);
app.component('AutoComplete', AutoComplete);
app.component('AccordionPrime', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('DialogPrime', DialogPrime);
app.component('ConfirmDialog', ConfirmDialog);
app.component('TextArea', Textarea);
app.component('TagPrime', Tag);
app.component('ProgressBar', ProgressBar);
app.component('CheckBox', Checkbox);
app.component('ImagePrime', ImagePrime);
app.component('SliderPrime', SliderPrime);



app.directive('tooltip', Tooltip);

/* const  auidio_juvenil = new Audio('http://aconcaguastream.tecnosoul.com.ar:8000/aconcagua');
const  auidio_infantil = new Audio('http://aconcaguastream.tecnosoul.com.ar:8000/aconcagua3');
const  auidio_adultos = new Audio('http://aconcaguastream.tecnosoul.com.ar:8000/aconcagua2');
app.provide('auidio_infantil', auidio_infantil);
app.provide('auidio_juvenil', auidio_juvenil);
app.provide('auidio_adultos', auidio_adultos); */

router.isReady().then(() => {
  app.mount('#app');
});


/*
const isNative = Capacitor.isNative;

if (isNative) {
    // Initialize the SQLite plugin
    const sqlite = new CapacitorSQLite();
    sqlite.initializePlugin();

    // Then, create or open a connection
    sqlite.createConnection("my_db", false, "no-encryption", 1);
} else {
    // Handle the case for web environment
    console.log('Running in a web environment. SQLite initialization skipped.');
}*/

store.dispatch('radio/set-generos-cargados', []);
store.dispatch('radio/set-artistas-cargados', []);

router.beforeEach((to, from, next) => {
	store.dispatch('form/submit', true);
	//console.log(store.getters['auth/refresh_token']);
	//console.log(store.getters['auth/access_token']);


	if ({}.hasOwnProperty.call(to.meta, "requiresAuth") && to.meta.requiresAuth && !store.getters['auth/access_token']) {
//        console.log("SG - 1");
		next();
		store.dispatch('form/submit', false);
	} else if (!{}.hasOwnProperty.call(to.meta, "requiresAuth")  && store.getters['auth/refresh_token'] && to.meta == true) {
       // console.log("SG - 2");
		AuthService.refresh_access(store.getters['auth/refresh_token']).then(res => {
			store.dispatch('auth/set', res.data);
			next();
			store.dispatch('form/submit', false);
		}).catch(error => {
			console.log(error);
			next({name: 'logout'})
			store.dispatch('form/submit', false);
		});
	} else {
       // console.log("SG - 3");
		next();
		store.dispatch('form/submit', false);
	}
});

Axios.interceptors.request.use(function (config) {	
  if (config.method !== 'options' && store.getters['auth/access_token'] && !config.isDownload) {
		config.headers.Authorization = `Bearer ${store.getters['auth/access_token']}`;
		//config.headers.uuid = window.device.uuid;
		//config.headers.employee = store.getters['auth/user'];
  }
  return config
}, function (error) {
  console(error.reponse.data);
});


