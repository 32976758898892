<template id="home">
    <span>
        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

        <TituloPage :nombre="'MOMENTOS MUSICALES'" @back="back()">
            <template v-slot:share>
                <share-component></share-component>
            </template>
        </TituloPage>

        <p class="text-3l font-bold text-white" v-if="selectedDayTime ==  undefined || selectedDayTime ==  null ||  selectedDayTime.tiempo_dia_id == ''|| selectedDayTime.tiempo_dia_id == undefined  || selectedDayTime.tiempo_dia_id == 'undefined'||  selectedDayTime.tiempo_dia_id == '-1' " @click.prevent="openModal()">
            Todos los momentos <img  class="z-index-0 custom-background-class" alt="no icon" src="@/assets/cinco.svg" style="height: 15px !important;">
        </p>
        <p class="text-3l font-bold text-white" v-else @click.prevent="openModal()">
            {{selectedDayTime.nombre}} 
            <img  class="z-index-0 custom-background-class" alt="no icon" src="@/assets/cinco.svg" style="height: 15px !important;">
        </p>

         <div class="search-bar mr-2 ml-2">
            <input type="text" v-model="searchQuery" placeholder="Buscar canciones..." class="input-search">
        </div>


        <div class="grid mt-3 mr-2 ml-2">
    <div class="col-4 sm:col-2 lg:col-1 p-2" v-for="(artist, index) in filteredArtists" :key="index" @click.prevent="openArtistSongs(artist, artist.tipo)">
        <ImageFallback v-if="artist.foto_artista != null"
            :imageSource="artist.foto_artista"
            :fallbackSrc="'@/assets/logo-radio.png'"
            :alt="'noico'"
            :class="'z-index-0'"
            :width="null"
            :maxHeight="'160px'"
        />
        <ImageFallback v-else
            :imageSource="'@/assets/logo-radio.png'"
            :fallbackSrc="'@/assets/logo-radio.png'"
            :alt="'noico'"
            :class="'z-index-0'"
            :width="null"
            :maxHeight="'160px'"
        />
        <p class="text-white font-bold text-xs">{{ artist.nombre }} {{ artist.apellido }}</p>
    </div>
</div>


        <div style="width: 100%"></div>

        <side-bar id="sidemomentos" v-model:visible="visibleFULL" :baseZIndex="10000" position="full" :showCloseIcon="false">
            <selecteddaytime :device="device" @horario="seleccionarHorario" @cerrar="cerrar()"></selecteddaytime>
        </side-bar>

         <side-bar v-model:visible="visibleRight" :baseZIndex="10000" position="right" class="bg-primary-cus" :showCloseIcon="false">
            <h3 style="color: white !important;">{{this.artistTypeSelected.nombre}}</h3>
             <div class="grid">
                <div class="col-12 md:col-12 lg:col-12"  v-for="(c, idx) in artists" :key="idx" >
                    <span @click.prevent="openArtistSongs(c)" class="text-white font-bold" style="color: white !important;">
                         {{c.nombre}} {{c.apellido}}</span>
                    <!--span class="text-white font-bold" style="color: #0a0850 !important;">-{{c.name}}</span-->
                </div>
            </div>
 </side-bar>
<br /><br /><br /><br />
    </span>     
</template>

<style>

#sidemomentos .p-sidebar-full .p-sidebar {
    background-color: transparent !important;

}
#sidemomentos .p-sidebar {
    background-color: transparent !important;
}

#sidemomentos .p-sidebar .p-sidebar-content {
    background-color: transparent !important;
    box-shadow: none;
}

.input-search {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
}
        .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
     color: black !important;
}

.neon-border-bottom {
    position: relative;
    border-bottom: solid white !important;
}

.neon-border-bottom::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;

    box-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white, 0 0 40px white;
}
</style>

<script>

    import Backgroundcustom from '@/components/Backgroundcustom';
    import GenerosService from '@/service/GenerosService';
    import ArtistService from '@/service/ArtistService';
    import Selecteddaytime from '@/components/SelectedDayTime';
    import {mapGetters} from 'vuex';
    import ImageFallback from '@/components/ImageFallback.vue';
    import ShareComponent from '@/components/ShareComponent'
    import TituloPage from '@/components/TituloPage'
    
    let SelectGenero = {};
    (function () {
        this.name = 'home-page';
        // let _self = null;

        this.created = function () {

            if(this.$route.params.daytimeid !== undefined && this.$route.params.daytimeid !== '' && this.$route.params.daytime_nombre !== undefined && this.$route.params.daytime_nombre !== '' && this.$route.params.daytime_nombre !== -1){
                this.selectedDayTime.tiempo_dia_id = this.$route.params.daytimeid
                this.selectedDayTime.nombre = this.$route.params.daytime_nombre;

            }else{
               if(this.$route.params.moment === 'true'){
                    this.openModal();
                } 
            }

            if(this.djs == null){
                this.getAlltipoArtista();
            }else{
                this.artistType = this.djs;
                if(this.djs_moment != null){
                    this.artistType = this.djs_moment;
                }
            }



        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
                visibleFULL: false,
                selectedDayTime: {'tiempo_dia_id': -1, 'nombre': null},
                artistType: [],
                artistTypeSelected: null,
                artists: [],
                st: process.env.VUE_APP_STATIC,
                visibleRight: false,
                  searchQuery: '',
            }
        };
        this.methods = {
            cerrar(){
                this.visibleFULL = false;
            },
            seleccionarHorario(s){
                this.selectedDayTime = s;
                this.visibleFULL = false;
            },
            openModal(){
                this.visibleFULL = true;
            },
            openArtistSongs(a, tipo){
                this.visibleRight = false;
                var at_name = a.nombre + ' ' + a.apellido
                var port = 0
      
                if(tipo.portada !== "")
                    port = tipo.portada;

                var selec_id = -1
                var nom_day_time = -1;

                if(this.selectedDayTime != null){
                    selec_id = this.selectedDayTime.tiempo_dia_id
                     nom_day_time = this.selectedDayTime.nombre;
                }

                this.$router.push({name: 'artist-prev-songs',
                   params: {
                        artist_id: a.artista_id,
                        artist_type_name: tipo.nombre,
                        img: port,
                        artist_name: at_name,
                        open_from_player: false,
                        daytimeid: selec_id,
                        daytime_nombre: nom_day_time,
                    }
               });

            },
            setVisibleModal(){
                console.log('visible');
                this.visibleRight = true;
            },
            getArtistaPorTipo(tipo){
                this.artistTypeSelected = tipo;
                this.$store.dispatch('form/submit', true);
               ArtistService.artistByTipo(this.artistTypeSelected.tipo_artista_id).then((response) => {
                    this.artists = response.data;
                    if(this.artists.length == 0){
                         this.$store.dispatch('form/result', {isError: true, message: 'Sin artistas'});
                    }else{
                        this.visibleRight = true;
                    }
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAlltipoArtista(){
                this.$store.dispatch('form/submit', true);
               ArtistService.allArtistTypeAndArtist().then((response) => {
                    this.artistType = response.data;
                    this.$store.dispatch('djs/set-djs', response.data);
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            allArtistTypeAndArtistDayTime(id_day_time){
                this.$store.dispatch('form/submit', true);
               ArtistService.allArtistTypeAndArtistDayTime(id_day_time).then((response) => {
                    this.artistType = response.data;

                    if(response.data.length == 0 ){
                        this.$store.dispatch('form/result', {isError: true, message: 'Artistas sin momentos'});
                    }else{
                        this.$store.dispatch('djs/set-djs-moment', response.data);
                        this.$store.dispatch('form/submit', false);  
                    }
                    
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
                      back() {
                this.$router.push({name: 'home'});
                },
            goTo: function (route, id, name, url) {
                this.$router.push({name: route,
                   params: {
                        id: id,
                        name: name,
                        img: url,
                    }
               });
            }
        };
        this.components = {Backgroundcustom, Selecteddaytime, ImageFallback, ShareComponent, TituloPage};
        this.computed = {
            filteredArtists() {
            if (!this.searchQuery) {
                return this.allArtists;
            }
            return this.allArtists.filter((artist) => {
                return artist.nombre.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
                       artist.apellido.toLowerCase().includes(this.searchQuery.toLowerCase());
            });
        },
              allArtists() {
        return this.artistType.reduce((all, g) => {
            const artistsWithTypeInfo = g.artistas.map(artist => ({
                ...artist,
                tipo: g.tipo
            }));
            return all.concat(artistsWithTypeInfo);
        }, []);
    },
             ...mapGetters({
                djs: 'djs/djs',
                djs_moment: 'djs/moment'
            })
        };
        this.watch = {
             selectedDayTime(newValue){
                if (newValue && newValue.tiempo_dia_id) {
                    this.artistType = [];
                    console.log(newValue.tiempo_dia_id);
                    this.allArtistTypeAndArtistDayTime(newValue.tiempo_dia_id);
                } else {
                    console.log('newValue is null or does not have tiempo_dia_id');
                    if(this.djs == null){
                            this.getAlltipoArtista();
                        }else{
                            this.artistType = this.djs;
                            if(this.djs_moment != null){
                                this.artistType = this.djs_moment;
                            }
                        }
                }
            }
        };
        

        this.props = ['device'];
    }).apply(SelectGenero);

    export default SelectGenero
</script>
