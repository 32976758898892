<template id="home">
    <span>
        <ProgressBar class="z-index-5" :showValue="false" style="height: 2px  !important; background-color: #0e0033; z-index: 4; top: -5px; left: 0;"/>

        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>


           <TituloPage :nombre="nombre_playlist.toUpperCase()" @back="back()">
            <template v-slot:share>
               <share-component></share-component>
            </template>
        </TituloPage>



 <div v-if="getvimeo_id === null" class="grid" style="margin-top: 5px !important; font-size: 3rem; height: 30vh !important;">
            <div class="col-12 md:col-12 lg:col-12 d-flex justify-content-center" style="height: 30vh !important;">
            
            <span v-if="getCurrentSong" style="height: 30vh !important;">
                    <span v-if="getCurrentSong.portada !== null">
                        <ImageFallback  style="height: 30vh !important;"
                                  :imageSource="getCurrentSong.portada"
                                  :fallbackSrc="'@/assets/logo-radio.png'"
                                  :alt="'noico'"
                                  :class="'z-index-0'"
                                  :width="null"
                                  :maxHeight="'50px'"

                                />
                    </span>
                    <span v-else>
              
                        <ImageFallback style="height: 30vh !important; width:250px !important; box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;"
                                  :imageSource="st+portada"
                                  :fallbackSrc="'@/assets/logo-radio.png'"
                                  :alt="'noico'"
                                  :class="'z-index-0'"
                                  :width="null"
                                  :maxHeight="'50px'"
                                />
                    </span>
             
            </span>
            </div>
        </div>


         <transition  appear enter-active-class="animate__animated animate__fadeIn">
        
            <span>


    
           
        <div v-if="getCurrentSong && getCurrentSong.artista">
            <div class="grid ml-4 mt-3" style="text-align: left !important;">

                <div class="col-9 col-xs-12" style="aling: left !important;">
                    <span @click="setVisibleModal()">
                    <span class="text-white font-bold text-xs">
                        {{ getCurrentSong.artista.nombre }} {{ getCurrentSong.artista.apellido }}
                    </span><br />
                    <span class="text-white font-bold text-xs">
                        {{ getCurrentSong.nombre }}
                    </span>
                    </span>
                </div>

              
            </div>
        </div>




            <div class="card" v-if="getvimeo_id === null && songPlaying != null && getCurrentSong != null" style="width: 100%">
                <span v-if="songPlaying.cancion_id == getCurrentSong.cancion_id">

                    <div class="flex card-container yellow-container" style="width: 100%">
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round">
                                      <p class="text-white text-xs ml-3">{{convertTime(currentTimeSong)}}</p>
                        </div>

                        <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-900 border-round" style="width: 90%"></div>
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round">
                             <p class="text-white text-xs mr-4">{{convertTime(totalDurationSong)}}</p>
                        </div>
                    </div>

                    <div class="flex card-container yellow-container" style="width: 100%">
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>

                        <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-900 border-round" style="width: 90%">
                                <slider-prime v-model="currentTimeSong"  :min="0" :max="totalDurationSong" style="width: 100%; background-color: white !important "/>
                        </div>
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                    </div>
                </span>
            </div>


        

            
             <div class="card mt-2" v-if="getCurrentSong">

                <div class="grid">
                    <div class="col-3 flex align-items-center justify-content-center">
                         <DeleteSongLista v-if="id_playlist != null" :idCancion="getCurrentSong.cancion_id" :idLista="id_playlist" @cancionEliminada="refrescarLista"></DeleteSongLista>

                    </div>

                    <div class="col-2 flex align-items-center justify-content-center">
                             <img @click.prevent="setVisibleModal()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/retroceder.svg" style="height: 30px !important;">       
                    </div>
                    <div class="col-2 flex align-items-center justify-content-center">
                        
                        <span v-if="songPlaying != null && getCurrentSong != null && getvimeo_id === null">
                            <img v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true"  @click.prevent="clickpausa()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/pausa.svg" style="height: 45px !important;">
                        </span>

                         <img v-if="getvimeo_id === null && playing == false " @click.prevent="play(true)" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/play.svg" style="height: 50px !important;">
                        <span v-if="songPlaying != null && getCurrentSong != null && getvimeo_id === null">
                            <img v-if="songPlaying.cancion_id != getCurrentSong.cancion_id && playing == true" @click.prevent="play(true)" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/play.svg" style="height: 50px !important;">
                        </span>

                    </div>
                    <div class="col-2 flex align-items-center justify-content-center">
                         <img @click.prevent="setVisibleModal()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/adelantar.svg" style="height: 30px !important;">
                    </div>

                </div>
            </div>

      <p class="text-white font-bold text-xs mt-6">MAS PLAYLIST
            <ButtonPrime @click="toggleSortOrder" class="p-button-outlined p-button-primary ml-2"  style="color:white;">
                <i :class="sortOrder === 'asc' ? 'pi pi-sort-amount-up' : 'pi pi-sort-amount-down'"></i>
            </ButtonPrime>
        </p>


<div class="grid m-3" v-if="songPlaying != null && getCurrentSong">
    <div v-for="(cancion, index) in canciones" :key="index" class="col-12 md:col-6 lg:col-4">

    <div class="mt-5">
        <div class="grid card-rounded shadow-2">
            <div class="col-2">
                <img :src="cancion.portada" alt="Cancion portada" style="width:50px; height: 50px;" class="m-1" />
            </div>

        <div class="col-8">
            <div class="text-center overflow-ellipsis">
                <p class="font-sm mt-2 mb-0" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ cancion.nombre }}</p>
                <p class="text-xs mt-1 mb-0" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ cancion.artista.nombre }} {{ cancion.artista.apellido }}</p>
            </div>
            <div class="text-center overflow-ellipsis">
                <p v-if="cancion.fecha_creacion_cancion != undefined && cancion.fecha_creacion_cancion !== null" class="text-xs mt-1 mb-0" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{formatDate(cancion.fecha_creacion_cancion) }} </p>
                <p style=" color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" class="text-xs mt-1 mb-0 mb-0"  v-if="cancion.tiempo_dia !== undefined && cancion.tiempo_dia.nombre !== undefined && cancion.tiempo_dia.nombre !== null">
                    Momento: {{cancion.tiempo_dia.nombre}}
                    </p>
            </div>
        </div>

          <div class="col-2" v-if="getCurrentSong != null">
               <DeleteSongLista v-if="id_playlist != null" :idCancion="getCurrentSong.cancion_id" :idLista="id_playlist" @cancionEliminada="refrescarLista"></DeleteSongLista>


            <div class="mt-2"  v-if="songPlaying != null">
               <span v-if=" getCurrentSong != null && cancion != null" >
                            <img v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true && songPlaying.cancion_id == cancion.cancion_id && cancion.vimeo_id == null"  @click.prevent="clickpausa()" class="z-index-0 custom-background-class ml-1 mt-2" alt="no icon" src="@/assets/white-pausa-icon.png" style="height: 1.5rem !important;">

                             <span v-else >
                            <img @click.prevent="playMenu(cancion)" class="z-index-0 custom-background-class ml-1 mt-2" alt="no icon" src="@/assets/rojo/play.svg" style="height: 1.5rem !important;">
                            </span>
                
                </span>
                <br /><br />
                    
            </div>
          </div>
        
          <div class="col-2">
           <p class="text-white text-xs ml-3" v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true && songPlaying.cancion_id == cancion.cancion_id && getvimeo_id == null">{{convertTime(currentTimeSong)}}</p>
            </div>
          <div class="col-8 mb-3">
           <slider-prime class="mt-3" v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true && songPlaying.cancion_id == cancion.cancion_id && getvimeo_id == null" v-model="currentTimeSong"  :min="0" :max="totalDurationSong" style="width: 100%; background-color: white !important;"/>
            </div>
            <div class="col-2">
          <p class="text-white text-xs mr-4 mb-3" v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true && songPlaying.cancion_id == cancion.cancion_id && getvimeo_id == null">{{convertTime(totalDurationSong)}}</p>
            </div>

      </div>
      </div>
    </div>

  </div>


            <br /><br /><br /><br /><br /><br /><br /><br />
</span>
</transition>

 <side-bar v-model:visible="visibleRight" :baseZIndex="10000" position="right" :showCloseIcon="false" style="background-color: #1f1f1f !important;">
            <h3 style="color: white !important;">{{this.playlist_name}}</h3>
             <div class="grid">
                <div class="col-12 md:col-12 lg:col-12"  v-for="(c, idx) in canciones" :key="idx" >
                    <span>
                        <img @click.prevent="playMenu(c)" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/boton-play.svg" style="height: 1rem !important;">
                       <span @click.prevent="playMenu(c)" v-if="c.artista !== null" class="text-white font-bold" style="color: white !important;">{{c.artista.nombre}} {{c.artista.apellido}} -</span>
                        <span class="text-white font-bold" style="color: white !important;"><span @click.prevent="playMenu(c)">{{c.nombre}} </span> 

                        <DeleteSongLista v-if="id_playlist != null" :idCancion="c.cancion_id" :idLista="id_playlist" @cancionEliminada="refrescarLista" :isSmall="true"></DeleteSongLista>

                         <!--favorite :cancion_id="c.cancion_id" :min="true"></favorite-->

                        </span>
                    </span>
                    
                </div>
            </div>
 </side-bar>

        
    </span>     
</template>


<style>
    .p-slider .p-slider-range{
        background-color: white !important;
    }

    .p-slider .p-slider-handle{
        background-color: white !important;
    }
    .width-mobile{
        width: 50% !important;
    }

    .width-tablet{
        width: 55% !important;
    }

    .width-desktop{
        width: 35% !important;
    }

    .margin-top-mobile{
         top: 10% !important;
    }

    .margin-top-tablet{
         top: 30% !important;
         left: 35% !important;
    }

    .margin-top-desktop{
         top: 10% !important;
         left: 33% !important;
    }

    .margin-top-30-desktop{
         top: 30% !important;
         left: 40% !important;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
     color: white !important;
     margin-top: 10px !important;
    }

</style>


<script>
    import { InAppBrowser } from '@ionic-native/in-app-browser';
    import { Device } from '@capacitor/device';
    import Backgroundcustom from '@/components/Backgroundcustom';
    //import Favorite from '@/components/Favorite';
    import {mapGetters} from 'vuex';
    import CancionesService from '@/service/CancionesService';
    //import Player from '@vimeo/player'
    import moment from 'moment';

    import DeleteSongLista from '@/components/DeleteSongLista.vue';
     import ImageFallback from '@/components/ImageFallback.vue'; 
    import TituloPage from '@/components/TituloPage.vue';
        import ShareComponent from '@/components/ShareComponent'

    TituloPage
    
    //import { inject } from 'vue'
    //let globalVar_auidio_infantil= inject('auidio_infantil')
    let ListasSongs = {};
    (function () {
        const options = {
                        location: 'yes',
                        closebuttoncaption: 'Close',
                        hidenavigationbuttons: 'yes',
                        hideurlbar: 'yes',
                    }

        this.name = 'playlist-page';
        this.created = function () {

            this.nombre_playlist = this.$route.params.nombre;
            this.id_playlist = Number(this.$route.params.id);
            this.getSongsPlaylist(this.nombre_playlist);
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-duration-time', 0);
        };

        this.data = function () {
            return {
                visibleRight: false,
                selectedSong: null,
                st: process.env.VUE_APP_STATIC,
                playlist_id: null,
                playlist_name: null,
                playlist_portada: null,
                is_playing: false,
                radio_audio: null,
                estacionSeleccionada: null,
                info: null,
                vimeo_id: null,
                player: null,
                init_progress: false,
                vimeo_playing: false,
                player_install: false,
                currentTimeSong: 0,
                totalDurationSong: 0,
                open_from_player: false,
                id_playlist: null,
                nombre_playlist: null,
                canciones: [],
                sortOrder: 'asc',
            }
        };
        this.methods = {
             toggleSortOrder() {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            },

             formatDate(fecha){
                   return moment(fecha).format('DD - MM - YYYY');
            },
            refrescarLista(){
                this.getSongsPlaylist(this.$route.params.nombre); 
            },
            reproducirCancionFav(){
                this.selectedSong = this.canciones[0];

                if(this.canciones.length > 0){
                        if(this.selectedSong !== null && this.playing == false && (this.selectedSong.cancion !== null || this.selectedSong.url_cancion !== null)){
                            this.selectedSong = this.canciones[0]
                            this.$store.dispatch('song/set-current-screen', this.canciones[0]);
                            //this.play(false);
                        }
                    }else{
                        this.$router.push({name: 'listas'});
                        this.$store.dispatch('form/result', {isError: true, message: 'Lista sin canciones'});
                    }
                    this.$store.dispatch('form/submit', false);
            },
            converDate(fecha){
                moment.locale('es');
                return moment(fecha).format('LL');
            },
            setVisibleModal(){
                this.visibleRight = true;
            },
            getSongsPlaylist(name){
                this.$store.dispatch('form/submit', true);
                   CancionesService.getCancionesByNamePLayList(name, this.user_id).then((response) => {
                        this.canciones = response.data;
                        this.reproducirCancionFav();
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            stop(){
                var empty_song = {  activo: null,
                                    artista:null,
                                    artista_id:null,
                                    cancion:null,
                                    url_cancion:null,
                                    cancion_id:null,
                                    fecha_creacion_cancion:null,
                                    fecha_ingreso:null,
                                    genero:null,
                                    genero_id:null,
                                    nombre:null,
                                    portada:null,
                                    vimeo_id:null,
                                }
                this.$store.dispatch('song/set-current', -1);
                this.$store.dispatch('song/set-is-playing', false);
               // if(this.auidio_radio !== null){
                //    this.auidio_radio.pause();
                //    this.auidio_radio = null;
                //}
            },
            clickpausa(){
                if(this.songPlaying != null){
                    this.$store.dispatch('song/set-is-playing', false);
                        this.$store.dispatch('song/set-pausa', true);
                    }
            },
            convertTime(seconds){
                const format = val => `0${Math.floor(val)}`.slice(-2);
                var hours = seconds / 3600;
                var minutes = (seconds % 3600) / 60;
                return [minutes, seconds % 60].map(format).join(":");
            },
            play(userpress){

                this.$store.dispatch('song/set-is-radio', false);
                this.$store.dispatch('song/set-is-playing', true);

 
                if(this.songPlaying == null || this.songPlaying == undefined){
  
                    this.$store.dispatch('song/set-list-songs', this.canciones);
                    this.$store.dispatch('song/set-current', this.selectedSong);
                    this.$store.dispatch('song/set-is-radio', false);
                    this.$store.dispatch('song/set-is-playing', true);
                }else{
        
                    if(this.songPlaying.cancion_id == this.selectedSong.cancion_id && this.pausa == true){
           
                        this.$store.dispatch('song/set-pausa', 0);   
                    } else{ 
        
                    
                        if( (this.playing == true && userpress == true ) || (this.playing == false && userpress == false && this.pausa == true)){
                 
                            this.$store.dispatch('song/set-list-songs', this.canciones);
                  
                            this.$store.dispatch('song/set-current', this.selectedSong);
                            this.$store.dispatch('song/set-is-radio', false);
                            this.$store.dispatch('song/set-is-playing', true);
                        }
                        
                      }
                } 
            },

            playMenu(s) {
                this.selectedSong = s;
                this.$store.dispatch('song/set-current-screen', s);
                if(this.selectedSong.vimeo_id === null){
                    if(this.selectedSong != null){
                        this.$store.dispatch('song/set-list-songs', this.canciones);
                 
                        this.$store.dispatch('song/set-current', this.selectedSong);
                        this.play(false);
                    }
                }
                this.visibleRight = false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
            ordenarCanciones() {
                this.canciones.sort((a, b) => {
                    if (this.sortOrder === 'asc') {
                        return new Date(a.fecha_ingreso) - new Date(b.fecha_ingreso);
                    } else {
                        return new Date(b.fecha_ingreso) - new Date(a.fecha_ingreso);
                    }
                });
            },

              back() {
                this.$router.go(-1);
                }
                
        };
        this.components = {Backgroundcustom, DeleteSongLista, ShareComponent, TituloPage, ImageFallback};
        this.computed = {
            getTodasCanciones(){
                return this.canciones;
            },
            getvimeo_id(){
                return this.vimeo_id;
            },
            getCurrentSong(){
                return this.selectedSong;
            },
            getEstacionSeleccionada(){
                return this.estacionSeleccionada;
            },
            getCurrentTimeSong(){
                return this.currentTimeSong;
            },
               ...mapGetters({
                songPlaying: 'song/current',
                playing: 'song/is_playing',
                pausa: 'song/pausa',
                currentTime: 'song/current_time',
                durationTime: 'song/duration_time',
                user_id: 'auth/user_id',
                modifique_fav: 'radio/agrege_fav',
                todos_favoritos: 'radio/favoritos',
            })
        };
        this.watch = {
              sortOrder(){
                this.ordenarCanciones();
            },
           songPlaying(newValue){
                if(newValue){
                    this.selectedSong = newValue;
                    this.currentTimeSong = 0;
                }
           },
            currentTime(newValue){
                if(newValue){
                 
                    if(((newValue + 15 < this.currentTimeSong) || (newValue - 15 > this.currentTimeSong) ) && this.currentTimeSong>0){
                        this.$store.dispatch('song/set-new-time', this.currentTimeSong);
                    }else{

                        this.currentTimeSong = newValue;
                    }
                }
            },
            durationTime(newValue){
                if(newValue){
                    this.totalDurationSong = newValue;
                }
            }
        };

        this.props = ['device'];
    }).apply(ListasSongs);

    export default ListasSongs
</script>
