<template>
  <div>
    <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

           <TituloPage :nombre="'MIS LISTAS'" @back="back()">
            <template v-slot:share>

            </template>
        </TituloPage>





 <InputText v-model="nuevaPlaylistNombre" type="text" placeholder="Nueva"></InputText>

    <ButtonPrime  class="p-button-rounded p-button-info ml-2" :disabled="isProcessing" @click="nuevaPlaylist">Crear  Playlist</ButtonPrime>


    <div class="grid mr-3 ml-2 mt-3">
      <div class="col-12 md:col-12 lg:col-12" v-for="(lista, idx) in listas" :key="idx">
         <div class="grid mr-3 ml-2" @click.prevent="goLista(lista)" >
           <div class="col-2 md:col-4 lg:col-4">
              <img class="mt-2" style="width: 50px !important;" alt="icono corporativo" src="@/assets/logo.svg">
                  <!--ImageFallback 
                    @click.prevent="openPodcastDetails(lista)"
                    style="width: 100%;box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"
                    :imageSource="null"
                    :fallbackSrc="'@/assets/logo-podcast.png'"
                    :alt="'noico'"
                    :class="'z-index-0'"
                    :width="null"
                    :maxHeight="'50px'"
                  /-->
          </div>

          <div class="col-9 md:col-8 lg:col-8 ml-3">
            <p class="text-white text-xl font-bold text-white text-left">{{ lista.nombre }}</p>
            <!--p class="text-xs" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ lista.descripcion_corta }}</p-->
          </div>

         </div>

      </div>
    </div>

  </div>
</template>

<script>
import Backgroundcustom from '@/components/Backgroundcustom';
import PodcastService from '@/service/PodcastService';
//import ImageFallback from '@/components/ImageFallback.vue';
import {mapGetters} from 'vuex';
import PlaylistService from '@/service/PlaylistService';

import TituloPage from '@/components/TituloPage'; 

export default {
  name: 'ListasUser',

  components: {
    Backgroundcustom, TituloPage,
  },

  data() {
    return {
      listas: [],
      selectedPodcast: null,
      current: null,
       nuevaPlaylistNombre: '',
       isProcessing: false,
    }
  },
  props:['device'],
  created() {
    if(this.songPlaying)
      this.current = this.songPlaying;
      
    if (this.$store.getters['song/listas'].length === 0) {
      // Si las listas no están en la caché, llama a la acción getListas
      this.getListas();
    }else{
      this.listas = this.$store.getters['song/listas'];
    }

  },

  computed: {
    getSongPlaying(){
      return this.songPlaying;
    },
    ...mapGetters({
                songPlaying: 'song/current',
                playing: 'song/is_playing',
                pausa: 'song/pausa',
                currentTime: 'song/current_time',
                durationTime: 'song/duration_time',
                artistas_cargados: 'radio/artistas_cargados',
                user_id: 'auth/user_id',
            })
  },
  methods: {
    back(){
       this.$router.push({name: 'home'});
    },
   nuevaPlaylist() {
      this.isProcessing = true; // Comienza el procesamiento
     this.$store.dispatch('form/submit', true)
      PlaylistService.NuevaPlayList(this.nuevaPlaylistNombre, this.user_id)
        .then(response => {
          // Si la playlist se creó con éxito, refresca la lista de playlists
          if (response.data.status === 'SUCCESS') {
            this.getListas();
            this.$store.dispatch('form/result', {isError: false, message: response.data.message}); // Muestra el mensaje de éxito
            
          }
          // Si hubo un error, maneja el error
          else if (response.data.status === 'ERROR') {
            this.$store.dispatch('form/result', {isError: true, message: response.data.message}); // Muestra el mensaje de error
           
          }
           this.$store.dispatch('form/submit', false)
          this.isProcessing = false; 
        })
        .catch(error => {
          this.$store.dispatch('form/result', {isError: true, message: 'Error al crear la playlist: ' + error}); // Muestra el mensaje de error
            this.isProcessing = false; 
            this.$store.dispatch('form/submit', false)
        });
    },


  goLista(lista){
    this.$router.push(
      {name: 'lista-songs', params: {'id': lista.playlist_usuario_id, 'nombre': lista.nombre}});
},

       convertTime(seconds){
                const format = val => `0${Math.floor(val)}`.slice(-2);
                var hours = seconds / 3600;
                var minutes = (seconds % 3600) / 60;
                return [minutes, seconds % 60].map(format).join(":");
            },
     play(episodio, podcast){

      var podcast = {  activo: true,
                        artista: {'nombre': 'Podcast', 'apellido': ''},
                        artista_id: -77,
                        cancion: episodio.cancion,
                        url_cancion: null,
                        cancion_id:episodio.orden,
                        fecha_creacion_cancion:2023,
                        fecha_ingreso:2023,
                        genero: {'nombre': ''},
                        genero_id:-77,
                        nombre: podcast.titulo + '-' +episodio.titulo,
                        portada: null,
                        vimeo_id: -2,
                        estacion_id: 0,
                    }
                    this.$store.dispatch('song/set-current', podcast);
                    this.$store.dispatch('song/set-is-playing', true);
                    this.$store.dispatch('song/set-is-radio', false);


      var todos_episodios = []
      if(podcast.episodios){
         for (var i = 0; i < podcast.episodios.length; i++) {
      

          const podcast = {  activo: true,
                                        artista: {'nombre': 'Podcast', 'apellido': ''},
                                        artista_id: -77,
                                        cancion: podcast.episodios[i].cancion,
                                        url_cancion: null,
                                        cancion_id: podcast.episodios[i].orden,
                                        fecha_creacion_cancion:2023,
                                        fecha_ingreso:2023,
                                        genero: {'nombre': ''},
                                        genero_id:-77,
                                        nombre: podcast.titulo + '-' +podcast.episodios[i].titulo,
                                        portada: null,
                                        vimeo_id: -2,
                                        estacion_id: 0,
                                    }
                                todos_episodios.push(podcast)
        
        
      }
                    this.$store.dispatch('song/set-list-songs', null);
                    this.$store.dispatch('song/set-list-songs', todos_episodios);
      }
     
                    
                
            },
   getListas() {
      this.$store.dispatch('form/submit', true);
      PlaylistService.getByUser(this.user_id).then((response) => {
        this.$store.dispatch('song/set-listas', response.data); // Actualiza las listas de reproducción en el estado de Vuex
        this.listas = response.data.filter(lista => lista.nombre !== 'Favoritos');
        this.$store.dispatch('form/submit', false);
      }).catch((error) => {
        this.$store.dispatch('form/submit', false);
        console.log(error);
      });
    },

    openPodcastDetails(podcast) {
      this.selectedPodcast = this.selectedPodcast === podcast ? null : podcast;
    }
  },
  watch:{
            songPlaying(newValue){

                if(newValue){
                  this.current = newValue;
                  this.selectedSong = newValue;
                  this.currentTimeSong = 0;
                }
           },

            currentTime(newValue){
                if(newValue){
                    if(((newValue + 15 < this.currentTimeSong) || (newValue - 15 > this.currentTimeSong) ) && this.currentTimeSong>0){
                        this.$store.dispatch('song/set-new-time', this.currentTimeSong);
                    }else{
                        this.currentTimeSong = newValue;
                    }
                }
            },
            durationTime(newValue){
                if(newValue){
                    this.totalDurationSong = newValue;
                }
            }
        },
}
</script>
