<template id="home">
    <span>
        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

        <p class="text-1l font-bold text-white mt-3">PROGRAMACION</p>

        <div class="grid mt-4 mr-3 ml-2" v-if="getSelectedStation!=null">

            <div class="col-12 md:col-12 lg:col-2 lg:p-0 lg:m-0" v-for="(d, idxxxx) in getSelectedStation.dias" :key="idxxxx">
           
                <span class="list-none" v-if="d.programacion.length > 0">
                    <span v-for="(x, idxx) in d.programacion" :key="idxx">
                        <div class="card" style="position: relative;">

                            <p class="text-3l  font-bold text-white" style="position: absolute; top: 5px; left: 30px;">{{d.nombre_dia}}</p>

                            <img v-if="x.programa.imagen_principal != null" class="z-index-0 ml-2 mt-3" alt="no icon" :src="st+x.programa.imagen_principal" style="width: 90%;box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;">
                            <!--p class="text-white text-6m">{{x.programa.nombre}}</p-->
                            <p class="text-white text-7m font-bold " style="position: absolute; bottom: -5px; right: 25px;">{{formatTime(x.hora_comienzo) + '-' + formatTime(x.hora_termino)}}</p>
                        </div>
                    </span>
                </span>
                <p v-else class="text-white">--</p>
            </div>
        </div>

    </span>     
</template>


<style>
    .is-active-custom{
        -webkit-box-shadow: 0px -1px 32px 2px rgba(255,255,255,1);
-moz-box-shadow: 0px -1px 32px 2px rgba(255,255,255,1);
box-shadow: 0px -1px 32px 2px rgba(255,255,255,1);

    }

</style>

<script>

    import moment from "moment";
    import Backgroundcustom from '@/components/Backgroundcustom';
    import ProgramationService from '@/service/ProgramationService';
    import {mapGetters} from 'vuex';
    
    let Programation = {};
    (function () {
        this.name = 'home-page';
        // let _self = null;
        this.created = function () {

            this.todas_estaciones = this.estaciones;
            console.log(this.todas_estaciones);
             console.log(this.programmm_store);

            if(this.programmm_store == null){
                this.getProgramacion();

            }else{
                this.programation = this.programmm_store;
                console.log(this.id_radio_estacion);
                 this.selectStaionNow(this.id_radio_estacion);

            }  
           
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            //this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
               diaSemana: [{'id': 1, 'name': 'Lunes'} , {'id': 2, 'name': 'Martes'}, {'id':3, 'name': 'Miercoles'}, {'id':4, 'name': 'Jueves'}, {'id':5, 'name': 'Viernes'}, {'id':6, 'name': 'Sabado'}, {'id':7, 'name': 'Domingo'}],
               programation: [],
               st: process.env.VUE_APP_STATIC,
               selected_station: null,
               todas_estaciones: [],
            }
        };
        this.methods = {
            selectStaionNow(id){
                this.selected_station = this.programation.find(item => item.estacion_id === parseInt(id));
               
            },
            formatTime(time){
                return moment(time, "HH:mm:ss").format("hh:mm");
            },
            getProgramacion(){
               this.$store.dispatch('form/submit', true);
               ProgramationService.getProgramation().then((response) => {
                     this.$store.dispatch('radio/set-programacion', response.data.data );
                    this.programation = response.data.data;
                     this.selectStaionNow(this.id_radio_estacion);
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
        };
        this.components = {Backgroundcustom};
        this.computed = {
            getSelectedStation(){
                return this.selected_station;
            },
            getTodasEstaciones(){
                return this.todas_estaciones;
            },
             ...mapGetters({
                estaciones: 'radio/estaciones',
                programmm_store: 'radio/programacion',
            })
        };
        this.watch = {
             estaciones(newValue){
                this.todas_estaciones = newValue;
             }
        };
        
        this.props = ['device', 'id_radio_estacion'];
    }).apply(Programation);

    export default Programation
</script>
