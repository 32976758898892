<template id="day-time-component">
    <span>

        <TituloPage :nombre="'MOMENTO MUSICAL'" @back="back()">
            <template v-slot:share>
                <ButtonPrime label="X" class="p-button-rounded ml-6 p-outlined" @click.prevent="cerrar()" style="background-color: transparent; border-color: white;"/>
            </template>
        </TituloPage>

        <div class="grid mt-4 mr-3 ml-2">
            <div class="col-6 md:col-1 lg:col-1"  >
                <ButtonPrime label="Todos" class="p-button-rounded p-button-info w-full" @click.prevent="selectHorario(null)"/>
            </div>

            <div class="col-6 md:col-1 lg:col-1" v-for="(g, idx) in daytime" :key="idx" >
                <ButtonPrime :label="g.nombre" @click="selectHorario(g)" class="p-button-rounded p-button-info w-full" />
            </div>
        </div>
    </span>     
</template>


<script>

    import TituloPage from '@/components/TituloPage';
    import DayTimeService from '@/service/DayTimeService';
    import {mapGetters} from 'vuex';
    
    let SelectDayTime = {};
    (function () {
        this.name = 'day-time-component';

        this.created = function () {
            if(this.daytime == null){
                this.getAllDayTimes();
            }
            else{
                this.current_daytime = this.daytime;
            }
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-current-screen', {'cancion_id': -1});
        };

        this.data = function () {
            return {
               current_daytime: [],
               st: process.env.VUE_APP_STATIC

            }
        };
        this.methods = {
            cerrar(){
                this.$emit('cerrar');
            },
            back(){
                this.$router.push({name:'home'});
            },
            selectHorario(h){
                 this.$emit('horario', h);
            },
            getAllDayTimes(){
                this.$store.dispatch('form/submit', true);
               DayTimeService.all().then((response) => {
                    this.$store.dispatch('radio/set-daytime', response.data);
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            goTo: function (route, id, name, url) {

                this.$router.push({name: route,
                   params: {
                        id: id,
                        name: name,
                        img: url,
                    }
               });
            }
        }; 
        this.components = {TituloPage};
        this.computed = {
            getCurrentDayTime(){
                return this.current_daytime; 
            },
            ...mapGetters({
               daytime: 'radio/daytime'
            })
        };
        this.watch = {

        };
        

        this.props = ['device'];
    }).apply(SelectDayTime);

    export default SelectDayTime
</script>
