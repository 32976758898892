<template id="home">
    <span>
        <ProgressBar class="z-index-5" :showValue="false" style="height: 2px  !important; background-color: #0e0033; z-index: 4; top: -5px; left: 0;"/>

        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>

         <p class="text-2l font-bold text-white ">GÉNEROS MUSICALES > {{playlist_name.toUpperCase()}}
            <span>
                     <img class="z-index-0" alt="no icon" src="@/assets/new/Compartir.png" style="width: 30px; height: 30px; color: whitesmoke;" @click.prevent="share()">
                </span>

         </p>


         <p v-if="playlist_horario" class="text-1l font-bold text-white ">Recomendación: {{playlist_horario}}</p>



        <div v-if="getvimeo_id === null" class="grid" style="margin-top: 5px !important;">
            
            <div class="col-12 md:col-12 lg:col-12">
            
            <span v-if="getCurrentSong">
                <span v-if="getCurrentSong.portada !== null">
                    <img class="z-index-0" alt="no icon" :src="getCurrentSong.portada" style="width:250px !important; box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;">  
                </span>
                <span v-else>
                    <img class="z-index-0" alt="no icon" :src="st+playlist_portada" style="width:250px !important; box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;">
                </span>
            </span>

            </div>
        </div>

         <transition  appear enter-active-class="animate__animated animate__fadeIn">
        
            <span>


    
            <span id="iframe-ion-content" v-if="getCurrentSong">
                <span>
                    <p class="text-white font-bold line-height-1" v-if="getCurrentSong.artista !== null">{{getCurrentSong.artista.nombre}} {{getCurrentSong.artista.apellido}}</p>

                    <p class="text-white font-bold line-height-1">{{getCurrentSong.nombre}}</p>

                    <p v-if="getCurrentSong.fecha_creacion_cancion"  class="text-white font-bold line-height-1">{{converDate(getCurrentSong.fecha_creacion_cancion)}}</p>
                </span>
            </span>

            <div class="card" v-if="getvimeo_id === null && songPlaying != null && getCurrentSong != null" style="width: 100%">
                <span v-if="songPlaying.cancion_id == getCurrentSong.cancion_id">

                    <div class="flex card-container yellow-container" style="width: 100%">
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round">
                                      <p class="text-white text-xs ml-3">{{convertTime(currentTimeSong)}}</p>
                        </div>

                        <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-900 border-round" style="width: 90%"></div>
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round">
                             <p class="text-white text-xs mr-4">{{convertTime(totalDurationSong)}}</p>
                        </div>
                    </div>

                    <div class="flex card-container yellow-container" style="width: 100%">
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>

                        <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-900 border-round" style="width: 90%">
                                <slider-prime v-model="currentTimeSong"  :min="0" :max="totalDurationSong" style="width: 100%; background-color: white !important "/>
                        </div>
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                    </div>
                </span>
            </div>

             <div class="card mt-2" v-if="getvimeo_id === null && getCurrentSong">
                <div class="flex card-container yellow-container">
                    <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                    <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-900 border-round">
                        <!--img @click.prevent="back()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/white-left-arrow.png" style="height: 3rem !important;"-->
                         <SeleccionarLista :idCancion="getCurrentSong.cancion_id"></SeleccionarLista>

                        <favorite :cancion_id="getCurrentSong.cancion_id "></favorite>
                        
                        <span v-if="songPlaying != null && getCurrentSong != null">

                            <img v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true"  @click.prevent="clickpausa()" class="z-index-0 custom-background-class ml-3" alt="no icon" src="@/assets/white-pausa-icon.png" style="height: 3rem !important;">
                            <!--img v-if="songPlaying.cancion_id == getCurrentSong.cancion_id" @click.prevent="stop()" class="ml-3 z-index-0 custom-background-class" alt="no icon" src="@/assets/boton-stop.svg" style="height: 3rem !important;"-->
                        </span>

                         <img @click.prevent="play(true)" class="z-index-0 custom-background-class ml-3" alt="no icon" src="@/assets/white-play-icon.png" style="height: 4rem !important;">

                        <img @click.prevent="setVisibleModal()" class="ml-3 z-index-0 custom-background-class" alt="no icon" src="@/assets/white-fordward-icon.png" style="height: 3rem !important;">
                    </div>
                    <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                </div>
            </div>

            <!--div class="card  z-index-5" style="z-index:99 !important; margin-top: -6px !important;" :class="{'bg-transparent' : device.isMobile}">
                <div class="flex card-container yellow-container m-0">
                    <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                    <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-900 border-round mb-2 mt-2">
                        
                        <img @click.prevent="openInstagram()"  class="ml-3 z-index-0 custom-background-class" alt="no icon" src="@/assets/boton-instagram.svg" style="height: 3rem !important;">
                        <img @click.prevent="openWebSite()" class="ml-3 z-index-0 custom-background-class" alt="no icon" src="@/assets/boton-web.svg" style="height: 3rem !important;">
                    </div>
                    <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                </div>
            </div-->

            <!--button-prime class="w-6 mt-3 p-button-transparent" :class="{'w-6':device.isMobile, 'w-1' : device.isDesktop || device.istablet}" label="COMPARTIR APP!" @click.prevent="shareapp()"/-->

             <!--div class="card">
                <div class="flex card-container yellow-container">
                    <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                    <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-900 border-round">
                        <p class="text-lg text-white">Radio Aconcagua</p>
                    </div>
                    <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                </div>
            </div-->
            <br /><br /><br /><br /><br /><br /><br /><br />
</span>
</transition>

 <side-bar v-model:visible="visibleRight" :baseZIndex="10000" position="right" class="bg-primary-cus" :showCloseIcon="false">
            <h3 style="color: white !important;">{{this.playlist_name}}</h3>

           
             <div class="grid" v-if="visibleRight == true">
                <div class="col-12 md:col-12 lg:col-12"  v-for="(c, idx) in canciones" :key="idx" >
                    <span>
                        <img @click.prevent="playMenu(c)" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/boton-play.svg" style="height: 1rem !important;">
                       <span @click.prevent="playMenu(c)" v-if="c.artista !== null" class="text-white font-bold" style="color: white !important;">{{c.artista.nombre}} {{c.artista.apellido}} -</span>
                        <span @click.prevent="playMenu(c)" class="text-white font-bold" style="color: white !important;"> {{c.nombre}}</span>

                        <SeleccionarLista :idCancion="c.cancion_id" :isSmall="true"></SeleccionarLista>
                        
                    </span>
                    
                </div>

            </div>

            
             <!--i v-if="getUnaVez == false" class="pi pi-spin pi-spinner text-white mt-3" style="font-size: 2rem; color: white"></i-->
       
 </side-bar>

        
    </span>     
</template>


<style>
.p-slider .p-slider-range{
    background-color: white !important;
}

.p-slider .p-slider-handle{
    background-color: white !important;
}

    .width-mobile{
        width: 50% !important;
    }

    .width-tablet{
        width: 55% !important;
    }

    .width-desktop{
        width: 35% !important;
    }

    .margin-top-mobile{
         top: 10% !important;
    }

    .margin-top-tablet{
         top: 30% !important;
         left: 35% !important;
    }

    .margin-top-desktop{
         top: 10% !important;
         left: 33% !important;
    }

    .margin-top-30-desktop{
         top: 30% !important;
         left: 40% !important;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
     color: white !important;
     margin-top: 10px !important;
}



</style>


<script>
    import { InAppBrowser } from '@ionic-native/in-app-browser';
    import { Device } from '@capacitor/device';
    import Backgroundcustom from '@/components/Backgroundcustom';
    import Favorite from '@/components/Favorite';
    import {mapGetters} from 'vuex';
    import CancionesService from '@/service/CancionesService';
    import RadioService from '@/service/RadioService';
    import SeleccionarLista from '@/components/SeleccionarLista.vue';



    import { Share } from '@capacitor/share';

    import Player from '@vimeo/player'

    import moment from 'moment';
    
    //import { inject } from 'vue'
    //let globalVar_auidio_infantil= inject('auidio_infantil')
    let PLaylistGenero = {};
    (function () {
        const options = {
                        location: 'yes',
                        closebuttoncaption: 'Close',
                        hidenavigationbuttons: 'yes',
                        hideurlbar: 'yes',
                    }

        this.name = 'playlist-page';
        this.created = function () {
            Device.getInfo().then((info) => {
                    this.info = info;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            var scripts = [
              "https://player.vimeo.com/api/player.js"
            ];
            scripts.forEach(script => {
              let tag = document.createElement("script");
              tag.setAttribute("src", script);
              document.head.appendChild(tag);
            });

            this.init();

            this.playlist_id = this.$route.params.id;
            this.playlist_name = this.$route.params.name;
            this.playlist_portada = this.$route.params.img;
            this.playlist_daytimeid = this.$route.params.daytimeid;
            this.openFromPlayer = this.$route.params.open_from_player;
            this.playlist_horario = this.$route.params.dasytime_nombre;


            if(this.generos_cargados.length > 0){

                var encontr = this.generos_cargados.find(item => (item.genero_id === this.$route.params.id.toString() && item.daytime_id === this.$route.params.daytimeid));
                if(!encontr)
                    this.getSongsGenero(this.$route.params.id,this.pag_star,this.pag_finish, this.playlist_daytimeid);
                else{
                    this.canciones = encontr.canci;
                    this.getSongGeneroPost();
                }
            }else{
                this.getSongsGenero(this.$route.params.id,this.pag_star,this.pag_finish, this.playlist_daytimeid);
            }
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-duration-time', 0);
        };

        this.unmounted = function () {

            if (this.vimeo_id != null) {
                   this.$store.dispatch('video_player/togglePlayerMinimized', true);
                 
            }
        };
    

        this.data = function () {
            return {
                info: null,
                visibleRight: false,
                selectedSong: null,
                st: process.env.VUE_APP_STATIC,
                playlist_id: null,
                playlist_name: null,
                playlist_portada: null,
                playlist_daytimeid: null,
                is_playing: false,
                radio_audio: null,
                estacionSeleccionada: null,
                info: null,
                vimeo_id: null,
                player: null,
                init_progress: false,
                vimeo_playing: false,
                player_install: false,
                currentTimeSong: 0,
                totalDurationSong: 0,
                open_from_player: false,
                pag_star: 0,
                pag_finish: 5,
                unavez: false,
                playlist_horario: null,
            }
        };
        this.methods = {
            async copyURL(mytext) {
                try {
                  await navigator.clipboard.writeText(mytext);
                  this.$store.dispatch('form/result', {isError: false, message: 'Comparte la url copiada'});
                } catch($e) {
                  this.$store.dispatch('form/result', {isError: true, message: 'No pude copiar'});
                }
            },
             share(){
                       this.$store.dispatch('form/submit', true);
                       var url = {'estacion_id': null,
                            'genero_id': this.playlist_id,
                            'genero_nombre': this.playlist_name,
                            'portada_genero': this.playlist_portada,
                            'artista_id':  null,
                            'tipo_arista_nombre': null,
                            'artista_nombre': null,
                            'artista_portada':  null,
                            'daytimeid_q':  this.playlist_daytimeid,
                            'horario_q': this.playlist_horario,
                            }
                       RadioService.createUrlCorta22(url).then((response) => {
                            //var id_url =  response.data;

                            var iddd = JSON.stringify(response.data.data);

                            var url_t = process.env.VUE_APP_SHARE_BASE +'?short=' + iddd;
                            if(this.info.platform == 'web'){
                                this.copyURL(url_t);
                            }else{
                                Share.share({
                                      title: 'Radio ' + process.env.VUE_APP_RADIO,
                                      text: 'Te invito a escuchar la mejor playlist de ' + this.playlist_name+'. '+url_t,
                                      url: url_t,
                                      dialogTitle: 'Invitacion' + process.env.VUE_APP_RADIO,
                                    }).then(response => {
                                            console.log(response)
                                        })
                                        .catch(err => {
                                           console.log(err)
                                            this.$store.dispatch('form/submit', false);
                                    });
                                }
                            this.$store.dispatch('form/submit', false);
                        }).catch((error) => {
                            console.log(error);
                            this.$store.dispatch('form/submit', false);
                        });

                     
                
            },
            converDate(fecha){
                moment.locale('es');
                return moment(fecha).format('LL');
            },
            init(){
                this.init_progress = true;
                this.vimeo_playing = false;

                this.vimeo_id =  null;
                this.termino_video = false;
                this.player_install = false;

            },
            setVisibleModal(){
                console.log('visible');
                this.visibleRight = true;
            },
            getSongGeneroPost(){
                if(this.open_from_player == false || this.openFromPlayer == 'false'){
                                this.selectedSong = this.canciones[0];
                                this.vimeo_id = this.canciones[0].vimeo_id;
                                this.$store.dispatch('song/set-current-screen', this.selectedSong );
                            }else{
                                this.selectedSong = this.songPlaying
                                 this.vimeo_id = this.songPlaying.vimeo_id
                                 this.$store.dispatch('song/set-current-screen', this.songPlaying );
                            }

                            if(this.vimeo_id  === null && this.playing == false && (this.selectedSong.cancion !== null || this.selectedSong.url_cancion !== null)){
                       
                                this.play(false);
                            }else{
                                if (this.vimeo_id !== null) {

                                    if(this.songPlaying){
                                         if(this.selectedSong.cancion_id === this.songPlaying.cancion_id){
                                          
                                          this.$store.dispatch('video_player/togglePlayerMinimized', false);

                                          this.$store.dispatch('video_player/togglePlayerActive', true);
                                      }
                                    }else{
                                        this.playInVimeoPlayer();
                                    }

                               }

                            }
            },
            getSongsGenero(id, strt, finish, daytimeid){
                this.$store.dispatch('form/submit', true);
                  console.log("sacando canciones genero db");

                  if(daytimeid == '-1'){
                    CancionesService.geByGenero(id, strt, finish).then((response) => {
                        this.canciones = response.data;
                        if(this.canciones.length > 0){
                            
                            if(this.unavez == false){
                                this.getSongsGenero(this.$route.params.id, null, null, -1);
                                this.unavez = true;
                            }else{
                                var guardando_genero = { "genero_id" : id, "canci" : response.data, "daytime_id": '-1'}
                                var temp_g = this.generos_cargados;
                                temp_g.push(guardando_genero);
                                this.$store.dispatch('radio/set-generos-cargados', temp_g );
                            } this.getSongGeneroPost();

                        }else{
                            this.$router.push({name: 'select-genero'});
                            this.$store.dispatch('form/result', {isError: true, message: 'Genero sin canciones'});
                        }
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
                  }else{
                    CancionesService.geByGeneroDayTime(id, strt, finish, daytimeid).then((response) => {
                        this.canciones = response.data;
                        if(this.canciones.length > 0){
                            
                            if(this.unavez == false){
                                this.getSongsGenero(this.$route.params.id, null, null, daytimeid.toString());
                                this.unavez = true;
                            }else{
                                var guardando_genero = { "genero_id" : id, "canci" : response.data, "daytime_id": daytimeid.toString()}
                                var temp_g = this.generos_cargados;
                                temp_g.push(guardando_genero);
                                this.$store.dispatch('radio/set-generos-cargados', temp_g );
                            } this.getSongGeneroPost();

                        }else{
                            this.$router.push({name: 'select-genero'});
                            this.$store.dispatch('form/result', {isError: true, message: 'Genero sin canciones'});
                        }
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });

                  }
                   
            },
            stop(){
                var empty_song = {  activo: null,
                                    artista:null,
                                    artista_id:null,
                                    cancion:null,
                                    url_cancion:null,
                                    cancion_id:null,
                                    fecha_creacion_cancion:null,
                                    fecha_ingreso:null,
                                    genero:null,
                                    genero_id:null,
                                    nombre:null,
                                    portada:null,
                                    vimeo_id:null,
                                }
                this.$store.dispatch('song/set-current', -1);
                this.$store.dispatch('song/set-is-playing', false);
               // if(this.auidio_radio !== null){
                //    this.auidio_radio.pause();
                //    this.auidio_radio = null;
                //}
            },
            clickpausa(){
                if(this.songPlaying != null){
                    this.$store.dispatch('song/set-is-playing', false);
                        this.$store.dispatch('song/set-pausa', true);
                    }
            },
            convertTime(seconds){
                const format = val => `0${Math.floor(val)}`.slice(-2);
                var hours = seconds / 3600;
                var minutes = (seconds % 3600) / 60;
                return [minutes, seconds % 60].map(format).join(":");
            },
            play(userpress){

                if(this.songPlaying == null || this.songPlaying == undefined || this.songPlaying == '[object]'){
                    //this.$store.dispatch('song/set-list-songs', null);
                    this.$store.dispatch('song/set-list-songs', this.canciones);

                    this.$store.dispatch('song/set-current', this.selectedSong);
                    this.$store.dispatch('song/set-is-radio', false);
                    this.$store.dispatch('song/set-is-playing', true);
                }else{
                    if(this.songPlaying.cancion_id == this.selectedSong.cancion_id && this.pausa == true){
                        this.$store.dispatch('song/set-is-playing', true);
                        this.$store.dispatch('song/set-pausa', 0);   
                    } else{
                   
                        if( (this.playing == true && userpress == true ) || (this.playing == false && userpress == true && this.pausa == true)){
                           
                            //this.stop();
                            //this.$store.dispatch('song/set-current', -1);
                            //this.$store.dispatch('song/set-list-songs', null);
                            this.$store.dispatch('song/set-list-songs', this.canciones);
                            this.$store.dispatch('song/set-current', this.selectedSong);
                            this.$store.dispatch('song/set-is-radio', false);
                            this.$store.dispatch('song/set-is-playing', true);
                        }
                        
                      }
                } 
            },


playInVimeoPlayer(){

    this.$store.dispatch('song/set-current', this.selectedSong);
     this.$store.dispatch('song/set-list-songs', this.canciones);

    this.$store.dispatch('video_player/setPlayerState', {
        isActive: true,
        isMinimized: false,
        videoId: this.vimeo_id,
        current_time: 0,
        zIndex: 2000,
    });
},

            playMenu(s) {
                this.selectedSong = s;
                this.$store.dispatch('song/set-is-radio', false);
                this.$store.dispatch('song/set-current-screen', s);

                if(this.selectedSong.vimeo_id === null){
                    this.init();

                    if(this.selectedSong != null){
                        this.$store.dispatch('song/set-list-songs', this.canciones);
                        this.$store.dispatch('song/set-current', this.selectedSong);
                        this.play(false);
                    }
                }else{
                     if(this.selectedSong.vimeo_id !== null){
                        this.vimeo_id = this.selectedSong.vimeo_id;
                        this.playInVimeoPlayer();
                        //this.createPlayer();
                    }

                }

                this.visibleRight = false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
            back(){
                this.$router.push({name: 'select-genero'});
            }
        };
        this.components = {Backgroundcustom, Favorite, SeleccionarLista};
        this.computed = {
            getUnaVez(){
                return this.unavez;
            },
            getvimeo_id(){
                return this.vimeo_id;
            },
            getCurrentSong(){
                return this.selectedSong;
            },
            getEstacionSeleccionada(){
                return this.estacionSeleccionada;
            },
            getCurrentTimeSong(){
                return this.currentTimeSong;
            },
               ...mapGetters({
                songPlaying: 'song/current',
                playing: 'song/is_playing',
                pausa: 'song/pausa',
                currentTime: 'song/current_time',
                durationTime: 'song/duration_time',
                generos_cargados: 'radio/generos_cargados',

            })
        };
        this.watch = {

           songPlaying(newValue){
                if(newValue){
                    this.selectedSong = newValue;
                    this.currentTimeSong = 0;
                }
           },
            currentTime(newValue){
                if(newValue){
                    if(((newValue + 15 < this.currentTimeSong) || (newValue - 15 > this.currentTimeSong) ) && this.currentTimeSong>0){
                        this.$store.dispatch('song/set-new-time', this.currentTimeSong);
                    }else{

                        this.currentTimeSong = newValue;
                    }
                }
            },
            durationTime(newValue){
                if(newValue){
                    this.totalDurationSong = newValue;
                }
            }
        };

        this.props = ['device'];
    }).apply(PLaylistGenero);

    export default PLaylistGenero
</script>
