import axios from "axios";

export default {
    
    all(id) {
        const url = `${process.env.VUE_APP_API_URL}/allgeneros`;
        let data = {'id_time_day': id}
        return axios.post(url, data)
    },



}
