<template>
  <div class="grid">
    <div class="col-4 lg:col-4 mt-3">
      <img 
        @click="back" 
        alt="back arrow" 
        src="@/assets/rojo/arrowBack.svg" 
        class="cursor-pointer" 
        style="width: 3rem; height: 1.5rem;">
    </div>
    <div class="col-4 lg:col-4">
      <p class="text-white text-l font-bold">{{ nombre }}</p>
    </div>
    <div class="col-4 lg:col-4 mt-3">
      <!-- Slot for share component -->
      <slot name="share"></slot>
    </div>  
  </div>
</template>

<script setup>
const props = defineProps({
  nombre: String
})

const emit = defineEmits(['back'])

function back() {
  emit('back')
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
