<template id="home">
    <span>
        <ProgressBar class="z-index-5" :showValue="false" style="height: 2px  !important; background-color: #0e0033; z-index: 4; top: -5px; left: 0;"/>
       
        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>


<div class="grid mt-3" style="position: relative; height: 33vh;">
    <!-- Imagen de fondo con degradado -->
    <div v-if="selectedSong" class="col-12 md:col-12 lg:col-12" style="height: 100%; overflow: hidden; padding: none !important;">
        <ImageFallback
            :imageSource="selectedSong.portada ? selectedSong.portada : '@/assets/logo-radio.png'"
            :fallbackSrc="'@/assets/logo-radio.png'"
            :alt="'Imagen de artista'"
            :class="'z-index-0'"
        />
        <!-- Degradado que hace que la imagen se difumine hacia la transparencia en la mitad -->
        <div style="position: absolute; top: 1px; left: 0; width: 100%; height: 100%; background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgb(31, 31, 31) 100%);"></div>
    </div>


<div style="position: absolute; top: 10px; left: 20px; padding: 10px; z-index: 10;">
          <img @click.prevent="back()" class="back-button z-index-10" alt="no icon" src="@/assets/rojo/arrowBack.svg" style="width: 3rem; height: 1.5rem; cursor: pointer;">
  </div>

    <div style="position: absolute; top: 10px; right: 20px; padding: 10px; z-index: 10;">
        <img src="@/assets/rojo/compartir.svg" alt="Compartir" style="width: 20px; height: 20px; cursor: pointer;" @click.prevent="share()" />
    </div>

    <div style="position: absolute; top: 25vh; left: 0; width: 100%; padding: 10px; box-sizing: border-box;">
        <p class="text-white font-bold text-3l" style="margin-bottom: 0;">
            {{ artist_name }}
        </p>
    </div>
</div>



         <transition  appear enter-active-class="animate__animated animate__fadeIn">
            <span>         


        <p class="text-white font-bold text-xs mt-6" v-if="selectedSong">
            <ButtonPrime @click="toggleSortOrder" class="p-button-outlined p-button-primary ml-2"  style="color:white;">
                <i :class="sortOrder === 'asc' ? 'pi pi-sort-amount-up' : 'pi pi-sort-amount-down'"></i>
            </ButtonPrime>
        </p>


<div class="grid m-3" v-if="canciones.length > 0">
    <div v-for="(cancion, index) in canciones" :key="index" class="col-12 md:col-6 lg:col-4">
        <div style="background-color: transparent; border-bottom: 1px solid white;">
            <div class="grid">
                <div class="col-10" >

                    <div class="text-left" @click.prevent="openArtistSongs(cancion)">
                        <p class="font-sm mt-2 mb-0" style="color: white;">{{ cancion.nombre }}</p>
                        <p class="text-xs mt-1 mb-0" style="color: white;">{{ cancion.artista.nombre }} {{ cancion.artista.apellido }}</p>
                        <p v-if="cancion.fecha_creacion_cancion != undefined && cancion.fecha_creacion_cancion !== null" class="text-xs mt-1 mb-0" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{formatDate(cancion.fecha_creacion_cancion) }} </p>
               
                    </div>
                </div>
                <div class="col-2 mt-2">
                    <favorite :cancion_id="cancion.cancion_id"/>
                </div>
            </div>
        </div>
    </div>
</div>

  <br /><br /><br /><br /><br /><br />
    </span>
</transition>

 <side-bar v-model:visible="visibleRight" :baseZIndex="10000" position="right" class="bg-primary-cus" :showCloseIcon="false">

            <h3 style="color: white !important;" v-if="canciones.length > 0 && visibleRight == true">Playlist {{canciones[0].artista.nombre}} {{canciones[0].artista.apellido}} 


                <ButtonPrime @click="toggleSortOrder">
                <i :class="sortOrder === 'asc' ? 'pi pi-sort-amount-up' : 'pi pi-sort-amount-down'"></i>
                </ButtonPrime>
            </h3>

             <div class="grid">

                <div class="col-12 md:col-12 lg:col-12"  v-for="(c, idx) in canciones" :key="idx">


                    <div class="grid">
                        <div class="col-10">

                            <span @click.prevent="playMenu(c)" class="text-white font-bold" style="color: white !important;"><img  class="z-index-0 custom-background-class" alt="no icon" src="@/assets/boton-play.svg" style="height: 1rem !important;">{{c.nombre}}
                                
                                <span style="font-size: 10px;" class="ml-3" v-if="c.fecha_creacion_cancion != undefined && c.fecha_creacion_cancion !== null">
                                    <br />{{formatDate(c.fecha_creacion_cancion)}}
                                </span>
                                
                                <span style="font-size: 10px;" class="ml-3"  v-if="c.tiempo_dia != undefined && c.tiempo_dia.nombre !== undefined && c.tiempo_dia.nombre !== null">
                                    <br />Momento: {{c.tiempo_dia.nombre}}
                                </span>

                            </span>
                    </div>


                    <div class="col-2" v-if="c.url_cancion != null || c.cancion != null">
                        
                    <span v-if="current_platform !== 'web' && c.url_cancion != null">
                            <descargar-componente :idCancion="c.cancion_id" :urlCancion="c.url_cancion" :nombreArchivo="obtenerNombreArchivoDesdeUrl(c.url_cancion)" :current_cancion="c"/>
                        </span>

                        <span v-else-if="current_platform !== 'web'">
                            <descargar-componente :idCancion="c.cancion_id" :urlCancion="c.cancion" :nombreArchivo="obtenerNombreArchivoDesdeUrl(c.cancion)" :current_cancion="c"/>
                        </span>

                        <SeleccionarLista :idCancion="c.cancion_id" :isSmall="true"></SeleccionarLista>
                        

                    </div>
</div>
                </div>
            </div>
 </side-bar>


        
    </span>     
</template>


<style>
.image-container {
    position: relative; /* Establece la referencia para la posición absoluta del botón */
}

.back-button {
    position: absolute; /* Posiciona el botón de manera absoluta dentro del contenedor */
    top: 10px; /* Espacio desde la parte superior del contenedor */
    left: 10px; /* Espacio desde la parte izquierda del contenedor */
    z-index: 10; /* Asegura que el botón esté sobre la imagen */
}
.full-height-image {
    width: 100%; /* Asegura que la imagen cubra completamente el ancho */
    height: 100%; /* Altura completa del contenedor */
    object-fit: cover; /* Mantiene la proporción de la imagen */
    position: absolute;
    top: 0;
    left: 0;
}
.z-index-10 {
    z-index: 10; /* Asegura que el ícono de compartir esté sobre otros elementos */
}
    .width-mobile{
        width: 50% !important;
    }

    .width-tablet{
        width: 55% !important;
    }

    .width-desktop{
        width: 35% !important;
    }

    .margin-top-mobile{
         top: 10% !important;
    }

    .margin-top-tablet{
         top: 30% !important;
         left: 35% !important;
    }

    .margin-top-desktop{
         top: 10% !important;
         left: 33% !important;
    }

    .margin-top-30-desktop{
         top: 30% !important;
         left: 40% !important;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
     color: black !important;
     margin-top: 10px !important;
}

.card-rounded {
  border-bottom: 1px solid #ddd;
  border-radius: 5px;

}

</style>


<script>
    import { InAppBrowser } from '@ionic-native/in-app-browser';
    
    import { Device } from '@capacitor/device';
    import Backgroundcustom from '@/components/Backgroundcustom';

    import Favorite from '@/components/Favorite';
    import {mapGetters} from 'vuex';
    import CancionesService from '@/service/CancionesService';
    import RadioService from '@/service/RadioService';

    import { Share } from '@capacitor/share';

    import DescargarComponente from '@/components/DescargaComponente.vue';
    import ImageFallback from '@/components/ImageFallback.vue'; 
    import { Capacitor } from '@capacitor/core';
    import SeleccionarLista from '@/components/SeleccionarLista.vue';
    import VimeoPlayer from '@/components/VimeoPlayer';
    
    import moment from 'moment';

    let ArtistSongs = {};
    (function () {


        var updateInterval = null;

        const options = {
                        location: 'yes',
                        closebuttoncaption: 'Close',
                        hidenavigationbuttons: 'yes',
                        hideurlbar: 'yes',
                    }

        this.name = 'home-page';
        this.created = function () {

           
            
           
        };

        this.unmounted = function () {

      

        };
    

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-duration-time', 0);
             this.current_platform = Capacitor.getPlatform();
            this.artist_id = this.$route.params.artist_id;
            this.artist_name = this.$route.params.artist_name;
            this.artist_type_name = this.$route.params.artist_type_name;
            this.getSongsArtist(this.$route.params.artist_id,0,5);

        };

        this.data = function () {
            return {
                canciones: [],
                visibleRight: false,
                selectedSong: null,
                st: process.env.VUE_APP_STATIC,
                artist_id: null,
                artist_type_name: null,
                portada: null,
                is_playing: false,
                auidio_radio: null,
                vimeo_id: null,
                totalDurationSong: null,
                fondo: 1,
                currentTimeSong: 0,
                openFromPlayer: false,
                //info: null
                unavez: false,
                playlist_horario: null,
                current_platform: null,
                sortOrder: 'asc',

            }
        };
        this.methods = {
             openArtistSongs(a){
                this.visibleRight = false;
                var at_name = '';
                  
                if(a.artista.apellido)
                    at_name = a.artista.nombre + ' ' + a.artista.apellido;
                else
                    at_name = a.nombre;

                var port = 0

                this.$router.push({name: 'artist-songs',
                   params: {
                        artist_id: a.artista_id,
                        artist_type_name: a.artista.tipo_arista.nombre,
                        img: port,
                        artist_name: at_name,
                        open_from_player: false,
                        daytimeid: -1,
                        daytime_nombre: -1,
                        select_song_prev: a.cancion_id,
                    }
               });

            },

            formatDate(fecha){
                   return moment(fecha).format('DD - MM - YYYY');
            },

            ordenarCanciones() {
                this.canciones.sort((a, b) => {
                    if (this.sortOrder === 'asc') {
                        return new Date(a.fecha_ingreso) - new Date(b.fecha_ingreso);
                    } else {
                        return new Date(b.fecha_ingreso) - new Date(a.fecha_ingreso);
                    }
                });
            },

            toggleSortOrder() {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            },


            playInVimeoPlayer(){

                this.$store.dispatch('song/set-current', this.selectedSong);
                 this.$store.dispatch('song/set-list-songs', this.canciones);

                this.$store.dispatch('video_player/setPlayerState', {
                    isActive: true,
                    isMinimized: false,
                    videoId: this.vimeo_id,
                    current_time: 0,
                    zIndex: 2000,
                });
            },

            isWeb() {
                return !this.$capacitor.isNative;
            },
            obtenerNombreArchivoDesdeUrl(url) {
              const posicion = url.lastIndexOf('/canciones/');
              const nombreArchivo = url.substring(posicion + '/canciones/'.length);
              return nombreArchivo;
            },
            async copyURL(mytext) {
                try {
                  await navigator.clipboard.writeText(mytext);
                  this.$store.dispatch('form/result', {isError: false, message: 'Comparte la url copiada'});
                } catch($e) {
                  this.$store.dispatch('form/result', {isError: true, message: 'No pude copiar'});
                }
            },
            share(){
       
                this.$store.dispatch('form/submit', true);
                       var url = {'estacion_id': null,
                            'genero_id': null,
                            'genero_nombre': null,
                            'portada_genero': null,
                            'artista_id':  this.artist_id,
                            'tipo_arista_nombre': this.artist_type_name,
                            'artista_nombre': this.artist_name,
                            'artista_portada':  null,
                            'daytimeid_q':  -1,
                            'horario_q': this.playlist_horario,
                        }

                       RadioService.createUrlCorta22(url).then((response) => {
               
                            var iddd = JSON.stringify(response.data.data);

                            var url_t = process.env.VUE_APP_SHARE_BASE +'?short=' + iddd;
                       
                              if (Capacitor.getPlatform() == 'web') {
                           
                                this.copyURL(url_t);
                            }else{
                             
                                Share.share({
                                      title: 'Radio ' + process.env.VUE_APP_RADIO,
                                      text: 'Te invito a descubrir la Playlist de ' + this.artist_name+'. '+url_t,
                                      url: url_t,
                                      dialogTitle: 'Invitacion' + process.env.VUE_APP_RADIO,
                                    }).then(response => {
                                            console.log(response)
                                        })
                                        .catch(err => {
                                           console.log(err)
                                            this.$store.dispatch('form/submit', false);
                                    });
                                }
                            this.$store.dispatch('form/submit', false);
                        }).catch((error) => {
                            console.log(error);
                            this.$store.dispatch('form/submit', false);
                        });
            },
            converDate(fecha){
                moment.locale('es');
                return moment(fecha).format('LL');
            },
            setVisibleModal(){
                this.visibleRight = true;
            },
            getArtistasSongsPost(){
                if(this.openFromPlayer == false || this.openFromPlayer == 'false'){
                                this.selectedSong = this.canciones[0];
                                this.vimeo_id = this.canciones[0].vimeo_id;
                                this.$store.dispatch('song/set-current-screen', this.selectedSong );
                            }else{

                                this.selectedSong = this.songPlaying
                                 this.vimeo_id = this.songPlaying.vimeo_id


           
                                 this.$store.dispatch('song/set-current-screen', this.songPlaying );
                            }
                            
                          
            },
            getSongsArtist(id, start, finish){
                this.$store.dispatch('form/submit', true);


                   CancionesService.geByArtist(id, start, finish).then((response) => {
                        this.canciones = response.data;
                        if(this.canciones.length > 0){

                             if(this.unavez == false){
                                this.getSongsArtist(this.$route.params.artist_id, null, null);
                                this.unavez = true;
                            }else{
                                var guardando_artista= { "artist_id" : this.$route.params.artist_id, "canci" : response.data, "daytime_id": null}
                                var temp_g = this.artistas_cargados;
                                temp_g.push(guardando_artista);
                                this.$store.dispatch('radio/set-artistas-cargados', temp_g );
                            }
                            this.getArtistasSongsPost();

                        }else{
                           this.$router.push({name: 'select-artist-type'});
                           this.$store.dispatch('form/result', {isError: true, message: 'Sin canciones'});
                        }
                        this.$store.dispatch('form/submit', false);
                            
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
                
            },
            clickpausa(){
                if(this.songPlaying != null){
                        this.$store.dispatch('song/set-is-playing', false);
                        this.$store.dispatch('song/set-pausa', true);
                    }
            },
            convertTime(seconds){
                const format = val => `0${Math.floor(val)}`.slice(-2);
                var hours = seconds / 3600;
                var minutes = (seconds % 3600) / 60;
                return [minutes, seconds % 60].map(format).join(":");
            },
            play(userpress){

                if(this.songPlaying == null || this.songPlaying == undefined || this.songPlaying == '[object]'){

                        this.$store.dispatch('song/set-list-songs', this.canciones);
                        this.$store.dispatch('song/set-current', this.selectedSong);
        
                        this.$store.dispatch('song/set-is-radio', false);
                        this.$store.dispatch('song/set-is-playing', true);
                }else{
                    if(this.songPlaying.cancion_id == this.selectedSong.cancion_id || this.pausa == true){
                        this.$store.dispatch('song/set-pausa', 0);   
                          this.$store.dispatch('song/set-is-playing', true);   
                    } else{
                        if(this.playing == true && userpress == true || (this.playing == false && userpress == true)){

                            this.$store.dispatch('song/set-list-songs', this.canciones);
                            this.$store.dispatch('song/set-current', this.selectedSong);
                            this.$store.dispatch('song/set-is-radio', false);
                            this.$store.dispatch('song/set-is-playing', true);

                        }
                        
                      }
                }
     
            },
            stop(){
                if(this.auidio_radio !== null){
                    this.auidio_radio.pause();
                    this.auidio_radio = null;
                }
            },
            playMenu(s) {
                this.vimeo_id = null;
                window.scrollTo(0, 0);

               
                    
                    this.selectedSong = s;
                    this.$store.dispatch('song/set-is-radio', false);
                    this.$store.dispatch('song/set-current-screen', s);

                    if(this.auidio_radio != null){
                            this.auidio_radio.pause();
                        }

                    if(this.selectedSong.vimeo_id === null){
                        
                        if(this.selectedSong != null){
                            this.play(false);
                        }
                    }

                    if(this.selectedSong != null){
                            this.$store.dispatch('song/set-current', this.selectedSong);
                            this.$store.dispatch('song/set-list-songs', this.canciones);
                        }

                    if(this.selectedSong.vimeo_id !== null){
                        this.vimeo_id = this.selectedSong.vimeo_id;
                        this.playInVimeoPlayer();
                        //this.createPlayer();
                    }

                    this.visibleRight = false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
            back(){
                 this.$router.go(-1);
            },
        };
        this.components = {Backgroundcustom, Favorite,ImageFallback, DescargarComponente, SeleccionarLista, VimeoPlayer};

        this.computed = {
            getCurrentSong(){
                return this.selectedSong;
            },
            getvimeo_id(){
                return this.vimeo_id;
            },
            getSelectedSong(){
                return this.selectedSong;
            },
            ...mapGetters({
                songPlaying: 'song/current',
                playing: 'song/is_playing',
                pausa: 'song/pausa',
                currentTime: 'song/current_time',
                durationTime: 'song/duration_time',
                artistas_cargados: 'radio/artistas_cargados',
            })
        };
        this.watch = {

            sortOrder(){
                this.ordenarCanciones();
            },

            songPlaying(newValue){
                if(newValue){
                    this.selectedSong = newValue;
                    this.currentTimeSong = 0;
                }
            },

            currentTime(newValue){
                if(newValue){
                    if(((newValue + 15 < this.currentTimeSong) || (newValue - 15 > this.currentTimeSong) ) && this.currentTimeSong>0){
                        this.$store.dispatch('song/set-new-time', this.currentTimeSong);
                    }else{
                        this.currentTimeSong = newValue;
                    }
                }
            },

            durationTime(newValue){
                if(newValue){
                    this.totalDurationSong = newValue;
                }
            }
        };

        this.props = ['device'];
    }).apply(ArtistSongs);

    export default ArtistSongs
</script>
