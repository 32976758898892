<template id="home">
    <span>
        <ProgressBar class="z-index-5" :showValue="false" style="height: 2px  !important; background-color: #0e0033; z-index: 4; top: -5px; left: 0;"/>
       
        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>


           <TituloPage :nombre="artist_name" @back="back()">
            <template v-slot:share>
             <img class="z-index-0" alt="no icon" src="@/assets/rojo/compartir.svg" style="width: 20px; height: 20px; color: whitesmoke;" @click.prevent="share()">
            </template>
        </TituloPage>


        <p v-if="playlist_horario !== '-1' && playlist_horario !== null && playlist_horario == undefined && playlist_horario == ''" class="text-1l font-bold text-white ">Recomendación: {{playlist_horario}}</p>

         <div  v-if="getvimeo_id !== null && selectedSong.portada" style=" height: 200px !important;">
            <span v-if="selectedSong.portada !== null">
                <img alt="no icon" :src="selectedSong.portada" style="height: 100%;">
            </span>
        </div>



        <div v-if="getvimeo_id === null" class="grid" style="margin-top: 5px !important; font-size: 3rem; height: 30vh !important;">
            <div class="col-12 md:col-12 lg:col-12 d-flex justify-content-center" style="height: 30vh !important;">
            
            <span v-if="getSelectedSong" style="height: 30vh !important;">
                    <span v-if="selectedSong.portada !== null">
                        <ImageFallback  style="height: 30vh !important;"
                                  :imageSource="selectedSong.portada"
                                  :fallbackSrc="'@/assets/logo-radio.png'"
                                  :alt="'noico'"
                                  :class="'z-index-0'"
                                  :width="null"
                                  :maxHeight="'50px'"

                                />
                    </span>
                    <span v-else>
              
                        <ImageFallback style="height: 30vh !important; width:250px !important; box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;"
                                  :imageSource="st+portada"
                                  :fallbackSrc="'@/assets/logo-radio.png'"
                                  :alt="'noico'"
                                  :class="'z-index-0'"
                                  :width="null"
                                  :maxHeight="'50px'"
                                />
                    </span>
             
            </span>
            </div>
        </div>

        <div v-if="selectedSong && selectedSong.artista">
            <div class="grid ml-4 mt-3" style="text-align: left !important;">

                <div class="col-9 col-xs-12" style="aling: left !important;">
                    <span @click="setVisibleModal()">
                    <span class="text-white font-bold text-xs">
                        {{ selectedSong.artista.nombre }} {{ selectedSong.artista.apellido }}
                    </span><br />
                    <span class="text-white font-bold text-xs">
                        {{ selectedSong.nombre }}
                    </span>
                    </span>
                </div>

                <div class="col-3 col-xs-12 flex mb-1">
                    <favorite v-if="getvimeo_id === null" :cancion_id="getCurrentSong.cancion_id" />
                </div>
            </div>
        </div>



         <transition  appear enter-active-class="animate__animated animate__fadeIn">
            <span>         

            <div class="card mt-2" v-if="getSelectedSong">

                <div class="grid">
                    <div class="col-3 flex align-items-center justify-content-center">
                         <SeleccionarLista v-if="getvimeo_id === null" :idCancion="getCurrentSong.cancion_id"></SeleccionarLista>
                    </div>

                    <div class="col-2 flex align-items-center justify-content-center">
                             <img @click.prevent="setVisibleModal()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/retroceder.svg" style="height: 30px !important;">       
                    </div>
                    <div class="col-2 flex align-items-center justify-content-center">
                        
                        <span v-if="songPlaying != null && getCurrentSong != null && getvimeo_id === null">
                            <img v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true"  @click.prevent="clickpausa()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/pausa.svg" style="height: 45px !important;">
                        </span>

                         <img v-if="getvimeo_id === null && playing == false " @click.prevent="play(true)" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/play.svg" style="height: 50px !important;">
                        <span v-if="songPlaying != null && getCurrentSong != null && getvimeo_id === null">
                            <img v-if="songPlaying.cancion_id != getCurrentSong.cancion_id && playing == true" @click.prevent="play(true)" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/play.svg" style="height: 50px !important;">
                        </span>

                        <img v-if="getvimeo_id !== null" @click.prevent="playInVimeoPlayer()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/play.svg" style="height: 50px !important;">

                    </div>
                    <div class="col-2 flex align-items-center justify-content-center">
                         <img @click.prevent="setVisibleModal()" class="z-index-0 custom-background-class" alt="no icon" src="@/assets/rojo/adelantar.svg" style="height: 30px !important;">
                    </div>
                    <div class="col-3 flex align-items-center justify-content-center">
                          <span v-if="current_platform !== 'web' && getvimeo_id === null && getCurrentSong.url_cancion != null">
                                 <descargar-componente :idCancion="getCurrentSong.cancion_id" :urlCancion="getCurrentSong.url_cancion" :nombreArchivo="obtenerNombreArchivoDesdeUrl(getCurrentSong.url_cancion)" :current_cancion="getCurrentSong"/>
                        </span>

                        <span v-else-if="current_platform !== 'web' && getvimeo_id === null">
                            <descargar-componente :idCancion="getCurrentSong.cancion_id" :urlCancion="getCurrentSong.cancion" :nombreArchivo="obtenerNombreArchivoDesdeUrl(getCurrentSong.cancion)" :current_cancion="getCurrentSong"/>
                        </span>
                     
                    </div>
                </div>
            </div>


            

             <div class="card" v-if="getvimeo_id === null && songPlaying != null && getCurrentSong != null" style="width: 100%">
                <span v-if="songPlaying.cancion_id == getCurrentSong.cancion_id">
                   

                    <div class="flex card-container yellow-container" style="width: 100%">
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>

                        <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-100 border-round" style="width: 90%">
                                <slider-prime v-model="currentTimeSong"  :min="0" :max="totalDurationSong" style="width: 100%; background-color: white !important "/>
                        </div>
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round"></div>
                    </div>

                     <div class="flex card-container yellow-container" style="width: 100%">
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round">
                                      <p class="text-grey text-xs ml-3">{{convertTime(currentTimeSong)}}</p>
                        </div>

                        <div class="flex-shrink-1 md:flex-shrink-0 flex align-items-center justify-content-center font-bold text-gray-900 border-round" style="width: 90%"></div>
                        <div class="hidden flex-grow-1 flex align-items-center justify-content-center font-bold text-gray-900 border-round">
                             <p class="text-grey text-xs mr-4">{{convertTime(totalDurationSong)}}</p>
                        </div>
                    </div>
                </span>
            </div>


        <p class="text-white font-bold text-xs mt-6">MAS PLAYLIST
            <ButtonPrime @click="toggleSortOrder" class="p-button-outlined p-button-primary ml-2"  style="color:white;">
                <i :class="sortOrder === 'asc' ? 'pi pi-sort-amount-up' : 'pi pi-sort-amount-down'"></i>
            </ButtonPrime>
        </p>

<div class="grid m-3" v-if="canciones.length > 0 && songPlaying && getCurrentSong">
    <div v-for="(cancion, index) in canciones" :key="index" class="col-12 md:col-6 lg:col-4">

    <div class="mt-5">
        <div class="grid card-rounded shadow-2">
            <div class="col-2">
                <img :src="cancion.portada" alt="Cancion portada" style="width:50px; height: 50px;" class="m-1" />
            </div>

        <div class="col-8">
            <div class="text-center overflow-ellipsis">
                <p class="font-sm mt-2 mb-0" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ cancion.nombre }}</p>
                <p class="text-xs mt-1 mb-0" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ cancion.artista.nombre }} {{ cancion.artista.apellido }}</p>
            </div>
            <div class="text-center overflow-ellipsis">
                <p v-if="cancion.fecha_creacion_cancion != undefined && cancion.fecha_creacion_cancion !== null" class="text-xs mt-1 mb-0" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{formatDate(cancion.fecha_creacion_cancion) }} </p>
                <p style=" color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" class="text-xs mt-1 mb-0 mb-0"  v-if="cancion.tiempo_dia !== undefined && cancion.tiempo_dia.nombre !== undefined && cancion.tiempo_dia.nombre !== null">
                    Momento: {{cancion.tiempo_dia.nombre}}
                    </p>
            </div>
        </div>

          <div class="col-2">
            <favorite :cancion_id="cancion.cancion_id"/>
            <div class="mt-2">

               <span v-if="songPlaying != null && getCurrentSong != null && cancion != null" >
                            <img v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true && songPlaying.cancion_id == cancion.cancion_id && cancion.vimeo_id == null"  @click.prevent="clickpausa()" class="z-index-0 custom-background-class ml-1 mt-2" alt="no icon" src="@/assets/white-pausa-icon.png" style="height: 1.5rem !important;">

                             <span v-else >
                            <img @click.prevent="playMenu(cancion)" class="z-index-0 custom-background-class ml-1 mt-2" alt="no icon" src="@/assets/rojo/play.svg" style="height: 1.5rem !important;">
                            </span>
                
                </span>
                <br /><br />
                    
            </div>
          </div>
          <div class="col-2">
           <p class="text-white text-xs ml-3" v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true && songPlaying.cancion_id == cancion.cancion_id && getvimeo_id == null">{{convertTime(currentTimeSong)}}</p>
            </div>
          <div class="col-8 mb-3">
           <slider-prime class="mt-3" v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true && songPlaying.cancion_id == cancion.cancion_id && getvimeo_id == null" v-model="currentTimeSong"  :min="0" :max="totalDurationSong" style="width: 100%; background-color: white !important;"/>
            </div>
            <div class="col-2">
          <p class="text-white text-xs mr-4 mb-3" v-if="songPlaying.cancion_id == getCurrentSong.cancion_id && playing == true && songPlaying.cancion_id == cancion.cancion_id && getvimeo_id == null">{{convertTime(totalDurationSong)}}</p>
            </div>

      </div>
      </div>
    </div>

  </div>
  <br /><br /><br /><br /><br /><br />
    </span>
</transition>

 <side-bar v-model:visible="visibleRight" :baseZIndex="10000" position="right" :showCloseIcon="false" style="background-color: #1f1f1f !important;">

            <h3 style="color: white !important;" v-if="canciones.length > 0 && visibleRight == true">Playlist {{canciones[0].artista.nombre}} {{canciones[0].artista.apellido}} 


                <ButtonPrime @click="toggleSortOrder">
                <i :class="sortOrder === 'asc' ? 'pi pi-sort-amount-up' : 'pi pi-sort-amount-down'"></i>
                </ButtonPrime>
            </h3>

             <div class="grid">

                <div class="col-12 md:col-12 lg:col-12"  v-for="(c, idx) in canciones" :key="idx">


                    <div class="grid">
                        <div class="col-10">

                            <span @click.prevent="playMenu(c)" class="text-white font-bold" style="color: white !important;"><img  class="z-index-0 custom-background-class" alt="no icon" src="@/assets/boton-play.svg" style="height: 1rem !important;">{{c.nombre}}
                                
                                <span style="font-size: 10px;" class="ml-3" v-if="c.fecha_creacion_cancion != undefined && c.fecha_creacion_cancion !== null">
                                    <br />{{formatDate(c.fecha_creacion_cancion)}}
                                </span>
                                
                                <span style="font-size: 10px;" class="ml-3"  v-if="c.tiempo_dia != undefined && c.tiempo_dia.nombre !== undefined && c.tiempo_dia.nombre !== null">
                                    <br />Momento: {{c.tiempo_dia.nombre}}
                                </span>

                            </span>
                    </div>


                    <div class="col-2" v-if="c.url_cancion != null || c.cancion != null">
                        
                    <span v-if="current_platform !== 'web' && c.url_cancion != null">
                            <descargar-componente :idCancion="c.cancion_id" :urlCancion="c.url_cancion" :nombreArchivo="obtenerNombreArchivoDesdeUrl(c.url_cancion)" :current_cancion="c"/>
                        </span>

                        <span v-else-if="current_platform !== 'web'">
                            <descargar-componente :idCancion="c.cancion_id" :urlCancion="c.cancion" :nombreArchivo="obtenerNombreArchivoDesdeUrl(c.cancion)" :current_cancion="c"/>
                        </span>

                        <SeleccionarLista :idCancion="c.cancion_id" :isSmall="true"></SeleccionarLista>
                        

                    </div>
</div>
                </div>
            </div>
 </side-bar>


        
    </span>     
</template>


<style>
    .width-mobile{
        width: 50% !important;
    }

    .width-tablet{
        width: 55% !important;
    }

    .width-desktop{
        width: 35% !important;
    }

    .margin-top-mobile{
         top: 10% !important;
    }

    .margin-top-tablet{
         top: 30% !important;
         left: 35% !important;
    }

    .margin-top-desktop{
         top: 10% !important;
         left: 33% !important;
    }

    .margin-top-30-desktop{
         top: 30% !important;
         left: 40% !important;
    }

    .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
     color: black !important;
     margin-top: 10px !important;
}

.card-rounded {
  border-bottom: 1px solid #ddd;
  border-radius: 5px;

}

</style>


<script>
    import { InAppBrowser } from '@ionic-native/in-app-browser';
    
    import { Device } from '@capacitor/device';
    import Backgroundcustom from '@/components/Backgroundcustom';

    import Favorite from '@/components/Favorite';
    import {mapGetters} from 'vuex';
    import CancionesService from '@/service/CancionesService';
    import RadioService from '@/service/RadioService';

    import { Share } from '@capacitor/share';

    import DescargarComponente from '@/components/DescargaComponente.vue';
    import ImageFallback from '@/components/ImageFallback.vue'; 
    import { Capacitor } from '@capacitor/core';
    import SeleccionarLista from '@/components/SeleccionarLista.vue';
    import VimeoPlayer from '@/components/VimeoPlayer';
       import TituloPage from '@/components/TituloPage'
    
    import moment from 'moment';

    let ArtistSongs = {};
    (function () {


        var updateInterval = null;

        const options = {
                        location: 'yes',
                        closebuttoncaption: 'Close',
                        hidenavigationbuttons: 'yes',
                        hideurlbar: 'yes',
                    }

        this.name = 'home-page';
        this.created = function () {
            this.current_platform = Capacitor.getPlatform();
            this.artist_id = this.$route.params.artist_id;
            this.artist_name = this.$route.params.artist_name;
            this.artist_type_name = this.$route.params.artist_type_name;
            this.portada = this.$route.params.img;
            this.openFromPlayer = this.$route.params.open_from_player;
            this.playlist_daytimeid = this.$route.params.daytimeid;
            this.playlist_horario = this.$route.params.daytime_nombre;
            this.from_prev_select_song = this.$route.params.select_song_prev;

            if(this.$route.params.favorite){
                this.getSongsPlaylist();
            }else{

                if(this.artistas_cargados.length > 0){
                    var encontr = this.artistas_cargados.find(item => (item.artist_id === this.$route.params.artist_id.toString() && item.daytime_id === this.$route.params.daytimeid.toString()));
                    if(!encontr){
                        this.getSongsArtist(this.$route.params.artist_id,0,5, this.playlist_daytimeid);
                    }else{
                        this.canciones = encontr.canci;
                        this.getArtistasSongsPost();
                    }
                }else{
                    this.getSongsArtist(this.$route.params.artist_id,0,5, this.playlist_daytimeid);
                }
            }

           
        };

        this.unmounted = function () {

            if (this.vimeo_id != null) {
                   this.$store.dispatch('video_player/togglePlayerMinimized', true);
                 
            }

        };
    

        this.mounted  = function () {
            window.scrollTo(0, 0);
            this.$store.dispatch('song/set-duration-time', 0);

        };

        this.data = function () {
            return {
                canciones: [],
                visibleRight: false,
                selectedSong: null,
                st: process.env.VUE_APP_STATIC,
                artist_id: null,
                artist_type_name: null,
                portada: null,
                is_playing: false,
                auidio_radio: null,
                vimeo_id: null,
                totalDurationSong: null,
                fondo: 1,
                currentTimeSong: 0,
                openFromPlayer: false,
                //info: null
                unavez: false,
                playlist_horario: null,
                current_platform: null,
                sortOrder: 'asc',
                from_prev_select_song: null,

            }
        };
        this.methods = {
            getSongsPlaylist(name){
                this.$store.dispatch('form/submit', true);
                   CancionesService.getCancionesByNamePLayList(name, this.user_id).then((response) => {
                        this.canciones = response.data;
                        this.$store.dispatch('radio/set-agrege-fav', false);
                        if(this.canciones.length > 0)
                            this.$store.dispatch('radio/set-favoritos', this.canciones);
                        this.reproducirCancionFav();
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            formatDate(fecha){
                   return moment(fecha).format('DD - MM - YYYY');
            },

            ordenarCanciones() {
                this.canciones.sort((a, b) => {
                    if (this.sortOrder === 'asc') {
                        return new Date(a.fecha_ingreso) - new Date(b.fecha_ingreso);
                    } else {
                        return new Date(b.fecha_ingreso) - new Date(a.fecha_ingreso);
                    }
                });
            },

            toggleSortOrder() {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            },


            playInVimeoPlayer(){

                this.$store.dispatch('song/set-current', this.selectedSong);
                 this.$store.dispatch('song/set-list-songs', this.canciones);

                this.$store.dispatch('video_player/setPlayerState', {
                    isActive: true,
                    isMinimized: false,
                    videoId: this.vimeo_id,
                    current_time: 0,
                    zIndex: 2000,
                });
            },

            isWeb() {
                return !this.$capacitor.isNative;
            },
            obtenerNombreArchivoDesdeUrl(url) {
              const posicion = url.lastIndexOf('/canciones/');
              const nombreArchivo = url.substring(posicion + '/canciones/'.length);
              return nombreArchivo;
            },
            async copyURL(mytext) {
                try {
                  await navigator.clipboard.writeText(mytext);
                  this.$store.dispatch('form/result', {isError: false, message: 'Comparte la url copiada'});
                } catch($e) {
                  this.$store.dispatch('form/result', {isError: true, message: 'No pude copiar'});
                }
            },
          share() {
    console.log("Iniciando proceso de compartir...");
    this.$store.dispatch('form/submit', true); 
    console.log("Datos antes de crear URL:", this.artist_id, this.artist_type_name, this.artist_name, this.portada, this.playlist_daytimeid, this.playlist_horario);

    var url = {
        'estacion_id': null,
        'genero_id': null,
        'genero_nombre': null,
        'portada_genero': null,
        'artista_id': this.artist_id,
        'tipo_arista_nombre': this.artist_type_name,
        'artista_nombre': this.artist_name,
        'artista_portada': this.portada,
        'daytimeid_q': this.playlist_daytimeid,
        'horario_q': this.playlist_horario,
    }

    RadioService.createUrlCorta22(url).then((response) => {
        var iddd = JSON.stringify(response.data.data);
        console.log("Respuesta de createUrlCorta22:", iddd);

        var url_t = process.env.VUE_APP_SHARE_BASE + '?short=' + iddd;
        console.log("URL para compartir:", url_t);

        if (Capacitor.getPlatform() == 'web') {
            console.log("Plataforma: web");
            this.copyURL(url_t);
        } else {
            console.log("Plataforma: móvil");
            Share.share({
                title: 'Radio ' + process.env.VUE_APP_RADIO,
                text: 'Te invito a descubrir la Playlist de ' + this.artist_name + '. ' + url_t,
                url: url_t,
                dialogTitle: 'Invitacion ' + process.env.VUE_APP_RADIO,
            }).then(response => {
                console.log("Respuesta de share:", response);
            }).catch(err => {
                console.log("Error al compartir:", err);
                this.$store.dispatch('form/submit', false);
            });
        }
        this.$store.dispatch('form/submit', false);
    }).catch((error) => {
        console.log("Error al crear URL corta:", error);
        this.$store.dispatch('form/submit', false);
    });
},

            converDate(fecha){
                moment.locale('es');
                return moment(fecha).format('LL');
            },
            setVisibleModal(){
                this.visibleRight = true;
            },
            getArtistasSongsPostXXX(){
                if(this.openFromPlayer == false || this.openFromPlayer == 'false'){
                                    this.selectedSong = this.canciones[0];
                                this.vimeo_id = this.canciones[0].vimeo_id;
                                
                            console.log(this.from_prev_select_song);
                            if(this.from_prev_select_song > 0){
                                     var enc_can = this.canciones.find(item => (item.cancion_id === this.from_prev_select_song ));
                                    console.log(enc_can)
                                    if(enc_can)
                                        this.selectedSong =  enc_can;
                                 }
                                 
                                 this.$store.dispatch('song/set-current-screen', this.selectedSong );
                            }else{
                                console.log(this.from_prev_select_song);
                                   this.selectedSong = this.songPlaying
                                this.vimeo_id = this.songPlaying.vimeo_id
                                if(this.from_prev_select_song > 0){

                                     var enc_can = this.canciones.find(item => (item.cancion_id === this.from_prev_select_song ));
                                    if(enc_can)
                                        this.selectedSong =  enc_can;
                                 }

                                this.$store.dispatch('song/set-current-screen', this.songPlaying );
                            
                            }
                            
                            if(this.vimeo_id  === null && this.playing == false &&(this.selectedSong.cancion !== null || this.selectedSong.url_cancion !== null)){
                                this.play(false);
                            }else{
                                if (this.vimeo_id !== null) {

                                    if(this.songPlaying){
                                         if(this.selectedSong.cancion_id === this.songPlaying.cancion_id){
                                          
                                          this.$store.dispatch('video_player/togglePlayerMinimized', false);

                                          this.$store.dispatch('video_player/togglePlayerActive', true);


                                      }else{
                                        if(this.from_prev_select_song > 0){

                                            var enc_can = this.canciones.find(item => (item.cancion_id === this.from_prev_select_song ));
                                            if(enc_can)
                                                this.selectedSong =  enc_can;
                                        }
                                      }
                                        
                                    }else{
                                        this.playInVimeoPlayer();
                                    }
                                   

                           

                               }
                            }
            },

            getArtistasSongsPost() {
                const findSongById = (id) => {
                    return this.canciones.find(item => item.cancion_id.toString() === id);
                };

                const setSelectedSong = (song) => {
                    this.selectedSong = song;
                    this.vimeo_id = song.vimeo_id;
                    this.$store.dispatch('song/set-current-screen', song);
                };

                let initialSong = this.openFromPlayer === false || this.openFromPlayer === 'false' ? this.canciones[0] : this.songPlaying;

                if (this.from_prev_select_song > 0) {
                    const foundSong = findSongById(this.from_prev_select_song.toString());
                    if (foundSong) {
                        initialSong = foundSong;
                    }
                }

                setSelectedSong(initialSong);

                if (this.vimeo_id === null && !this.playing && (this.selectedSong.cancion !== null || this.selectedSong.url_cancion !== null)) {
                    this.play(false);
                } else if (this.vimeo_id !== null) {
                    if (this.songPlaying && this.selectedSong.cancion_id === this.songPlaying.cancion_id) {
                        this.$store.dispatch('video_player/togglePlayerMinimized', false);
                        this.$store.dispatch('video_player/togglePlayerActive', true);
                    } else if (this.from_prev_select_song > 0) {
                        const foundSong = findSongById(this.from_prev_select_song.toString());
                        if (foundSong) {
                            setSelectedSong(foundSong);
                        }
                    } else {
                        this.playInVimeoPlayer();
                    }
                }
            },
            getSongsArtist(id, start, finish, daytimeid){
                this.$store.dispatch('form/submit', true);

                 if(daytimeid == '-1'){
                   CancionesService.geByArtist(id, null, null).then((response) => {
                        this.canciones = response.data;
                        if(this.canciones.length > 0){

                             if(this.unavez == false){
                                //this.getSongsArtist(this.$route.params.artist_id, null, null, daytimeid);
                                this.unavez = true;
                            }else{
                                var guardando_artista= { "artist_id" : this.$route.params.artist_id, "canci" : response.data, "daytime_id": daytimeid}
                                var temp_g = this.artistas_cargados;
                                temp_g.push(guardando_artista);
                                this.$store.dispatch('radio/set-artistas-cargados', temp_g );
                            }
                            this.getArtistasSongsPost();

                        }else{
                           this.$router.push({name: 'select-artist-type'});
                           this.$store.dispatch('form/result', {isError: true, message: 'Sin canciones'});
                        }
                        this.$store.dispatch('form/submit', false);
                            
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
                }else{
                    CancionesService.geByArtistDayTime(id, null, null, daytimeid).then((response) => {
                        this.canciones = response.data;
                        if(this.canciones.length > 0){

                             if(this.unavez == false){
                               // this.getSongsArtist(this.$route.params.artist_id, null, null, daytimeid);
                                this.unavez = true;
                            }else{
                                var guardando_artista= { "artist_id" : this.$route.params.artist_id, "canci" : response.data, "daytime_id": daytimeid}
                                var temp_g = this.artistas_cargados;
                                temp_g.push(guardando_artista);
                                this.$store.dispatch('radio/set-artistas-cargados', temp_g );
                            }
                            this.getArtistasSongsPost();
                        }else{
                           this.$router.push({name: 'redirect-select-moment', params: {moment: false, daytimeid: daytimeid, daytime_nombre: this.playlist_horario}});

                           this.$store.dispatch('form/result', {isError: true, message: 'Artista sin momentos, ' + this.playlist_horario});
                        }
                        this.$store.dispatch('form/submit', false);
                            
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
                }
            },

            clickpausa(){
                if(this.songPlaying != null){
                        this.$store.dispatch('song/set-is-playing', false);
                        this.$store.dispatch('song/set-pausa', true);
                    }
            },
            convertTime(seconds){
                const format = val => `0${Math.floor(val)}`.slice(-2);
                var hours = seconds / 3600;
                var minutes = (seconds % 3600) / 60;
                return [minutes, seconds % 60].map(format).join(":");
            },
            play(userpress){

                if(this.songPlaying == null || this.songPlaying == undefined || this.songPlaying == '[object]'){

                        this.$store.dispatch('song/set-list-songs', this.canciones);
                        this.$store.dispatch('song/set-current', this.selectedSong);
        
                        this.$store.dispatch('song/set-is-radio', false);
                        this.$store.dispatch('song/set-is-playing', true);
                }else{
                    if(this.songPlaying.cancion_id == this.selectedSong.cancion_id || this.pausa == true){
                        this.$store.dispatch('song/set-pausa', 0);   
                          this.$store.dispatch('song/set-is-playing', true);   
                    } else{
                        if(this.playing == true && userpress == true || (this.playing == false && userpress == true)){

                            this.$store.dispatch('song/set-list-songs', this.canciones);
                            this.$store.dispatch('song/set-current', this.selectedSong);
                            this.$store.dispatch('song/set-is-radio', false);
                            this.$store.dispatch('song/set-is-playing', true);

                        }
                        
                      }
                }
     
            },
            stop(){
                if(this.auidio_radio !== null){
                    this.auidio_radio.pause();
                    this.auidio_radio = null;
                }
            },
            playMenu(s) {
                this.vimeo_id = null;
                window.scrollTo(0, 0);

               
                    
                    this.selectedSong = s;
                    this.$store.dispatch('song/set-is-radio', false);
                    this.$store.dispatch('song/set-current-screen', s);

                    if(this.auidio_radio != null){
                            this.auidio_radio.pause();
                        }

                    if(this.selectedSong.vimeo_id === null){
                        
                        if(this.selectedSong != null){
                            this.play(false);
                        }
                    }

                    if(this.selectedSong != null){
                            this.$store.dispatch('song/set-current', this.selectedSong);
                            this.$store.dispatch('song/set-list-songs', this.canciones);
                        }

                    if(this.selectedSong.vimeo_id !== null){
                        this.vimeo_id = this.selectedSong.vimeo_id;
                        this.playInVimeoPlayer();
                        //this.createPlayer();
                    }

                    this.visibleRight = false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
            back() {
                this.$router.go(-2);
                },

        };
        this.components = {Backgroundcustom, Favorite,ImageFallback, DescargarComponente, SeleccionarLista, VimeoPlayer,   TituloPage};

        this.computed = {
            getCurrentSong(){
                return this.selectedSong;
            },
            getvimeo_id(){
                return this.vimeo_id;
            },
            getSelectedSong(){
                return this.selectedSong;
            },
            ...mapGetters({
                songPlaying: 'song/current',
                playing: 'song/is_playing',
                pausa: 'song/pausa',
                currentTime: 'song/current_time',
                durationTime: 'song/duration_time',
                artistas_cargados: 'radio/artistas_cargados',
            })
        };
        this.watch = {

            sortOrder(){
                this.ordenarCanciones();
            },

            songPlaying(newValue){
                if(newValue){
                    this.selectedSong = newValue;
                    this.currentTimeSong = 0;
                }
            },

            currentTime(newValue){
                if(newValue){
                    if(((newValue + 15 < this.currentTimeSong) || (newValue - 15 > this.currentTimeSong) ) && this.currentTimeSong>0){
                        this.$store.dispatch('song/set-new-time', this.currentTimeSong);
                    }else{
                        this.currentTimeSong = newValue;
                    }
                }
            },

            durationTime(newValue){
                if(newValue){
                    this.totalDurationSong = newValue;
                }
            }
        };

        this.props = ['device'];
    }).apply(ArtistSongs);

    export default ArtistSongs
</script>
