const state = () => ({
	step: null,
	isSubmiting: false,
	errorStates: {},
	result: {
		isError: false,
		message: null
	},
	isConnecting: false,
});
 
const getters = {
	'step'(state) {
		return state.step
	},
	'invalid-fields'(state) {
		return Object.keys(state.errorStates).filter((key) => state.errorStates[key])
	},
	'error-states'(state) {
		return state.errorStates
	},
	'result'(state) {
		return state.result
	},
	'is-submiting'(state) {
		return state.isSubmiting
	},
	'is-connecting'(state) {
		return state.isConnecting
	}
};
 
const actions = {
	submit(context, value) {
		context.commit('set-submiting', value)
	},
	connect(context, value) {
		context.commit('set-connecting', value)
	},
	step(context, value) {
		context.commit('set-step', value)
	},
	error(context, obj) {
		context.commit('add-error', obj)
	},
	reset (context){
		context.commit('set-submiting', false)
		context.commit('clear-errors')
	},
	result(context, value) {
    context.commit('clear-message');  // clear the message state
    setTimeout(() => {
        context.commit('set-result', value);  // set the new message after a small delay
    }, 100);
}

};
 
const mutations = {
	'clear-message' (state) {
        state.result.message = null;
    },
	'set-step'(state, value) {
		state.step = value
	},
	'set-submiting'(state, value) {
		state.isSubmiting = value
	},
	'set-connecting'(state, value) {
		state.isConnecting = value
	},
	'add-error'(state, obj) {
		state.errorStates = Object.assign({}, state.errorStates, obj)
	},
	'set-result'(state, value) {
		state.result = value
	},
	'clear-errors' (state) {
		state.errorStates = {};
	}
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}