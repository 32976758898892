import axios from "axios";

export default {
    
    allNewsType() {
        const url = `${process.env.VUE_APP_API_URL}/getnewstype`;
        let data = {'id': null}
        return axios.post(url, data)
    },

    getNewByType(id) {
        const url = `${process.env.VUE_APP_API_URL}/getnewsbytype`;
        let data = {'id': id}
        return axios.post(url, data)
    },


    getLastNews() {
        const url = `${process.env.VUE_APP_API_URL}/getlastnews`;
        let data = {'id': null}
        return axios.post(url, data)
    },


  



  


}
