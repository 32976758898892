import axios from "axios";

export default {
    
    searchCanciones(searchTerm, pageIndex, pageSize) {
        const url = `${process.env.VUE_APP_API_URL}/search-by-nombressssss/ `;
        let data = {
            search: searchTerm,
            pag_start: pageIndex,
            pag_finish: pageSize
        };
        return axios.post(url, data).then(response => {
            if (response.status === 200) {
                console.log(`Found ${response.data.Canciones.length} songs`);
                return response.data.Canciones; // Assuming the response structure includes a Canciones array
            } else {
                console.error(`API error: ${response.statusText}, Details: ${response.data}`);
                return []; // Return empty array on error
            }
        }).catch(error => {
            console.error(`Exception when searching for songs: ${error.message}`);
            throw error; // Rethrow the error for further handling
        });
    },
    
    geByGenero(id, start, finish) {
        const url = `${process.env.VUE_APP_API_URL}/songsbygenero`;
        let data = {"id":id, 'pag_start' : start, 'pag_finish': finish};
        return axios.post(url, data)
    },

    geByDayTime(id, start, finish) {
        const url = `${process.env.VUE_APP_API_URL}/songsbydaytime`;
        let data = {"id":id, 'pag_start' : start, 'pag_finish': finish};
        return axios.post(url, data)
    },

    
    geByGeneroDayTime(id, start, finish, daytimeid) {
        const url = `${process.env.VUE_APP_API_URL}/songsbygenerodaytime`;
        let data = {"id":id, 'pag_start' : start, 'pag_finish': finish, "daytimeid": daytimeid};
        return axios.post(url, data)
    },

    getCancionesByNamePLayList(name, id) {
        const url = `${process.env.VUE_APP_API_URL}/getfavoritesusersongs`;
        let data = {"name": name, "id":id}
        return axios.post(url, data)
    },

    geByArtist(id, start, finish) {
        const url = `${process.env.VUE_APP_API_URL}/songsbyartist`;
        let data = {"id":id, 'pag_start' : start, 'pag_finish': finish};
        return axios.post(url, data)
    },

    geByArtistDayTime(id, start, finish, daytimeid) {
        const url = `${process.env.VUE_APP_API_URL}/songsbyartistdaytime`;
        let data = {"id":id, 'pag_start' : start, 'pag_finish': finish, 'day_time_id': daytimeid};
        return axios.post(url, data)
    },

    getFavoriteUSer(id){
        const url = `${process.env.VUE_APP_API_URL}/getfavoritesUser`;
        let data = {"id":id}
        return axios.post(url, data)
    },

    getByUrl(u){
        const url = `${process.env.VUE_APP_API_URL}/getsongbyurl`;
        let data = {"url":u}
        return axios.post(url, data)
    },
    getSizeByUrl(u){
        const url = `${process.env.VUE_APP_API_URL}/getsizesongbyurl`;
        let data = {"url":u}
        return axios.post(url, data)
    }

}
