import { createWebHistory, createRouter } from "vue-router";
import Home from '../views/HomePage';
import RadioPage from '../views/RadioPage';
//import RadioNativePage from '../views/RadioNativePage';
import RedirectRadio from '../views/RedirectRadio';
import SelectGenero from '../views/SelectGenero';
import PlaylistGenero from '../views/PlaylistGenero';
import Login from '../views/Login';
import LoginEmail from '../views/LoginEmail';

import RecoveryPass from '../views/RecoveryPass';
import RegisterUser from '../views/RegisterUser';
import Store from '../store/index';
import UserProfile from '../views/UserProfile';
import SelectRadioStation from '../views/SelectRadioStation';
import SelectArtistType from '../views/SelectTypeArtist';
import ArtistSongs from '../views/ArtistSongs';
import ArtistPrevList from '../views/ArtistPrevList';

import RedirectArtistSongs from '../views/RedirectArtistSongs';
import RedirectPlaylistGenero from '../views/RedirectPlayListGenero';
import FavoritesSongs from '../views/FavoritesSongs';

import RedirectPlaylistDayTime from '../views/RedirectPlayListDayTime';
 import RedirectSelectMoment from '../views/RedirectSelectMoment';

import SelectedDayTime from '../views/SelectedDayTime';
import PlaylistDayTime from '../views/PlaylistDayTime';

import News from '../views/News';
import Programation from '../views/Programation';
import OfflineMusic from '../views/OfflineMusic';

import ListasUser from '../views/ListasUser';

import ListaSongs from '../views/ListasSongs';

import Podcast from '../views/Podcast';
import IntroPage from '../views/IntroPage';

import BuscarCanciones from '../views/BuscarCanciones';

import AllTimeArtist from '../views/AllTimeArtist';
import Sesiones from '../views/Sesiones';
import Djs from '../views/Djs';
import SelectMomentsArtist from '../views/SelectMomentsArtist';

import ArtistByGenero from '../views/ArtiststbyGenero';

const routes = [

 {
        path: '/',
        alias: ['/intro'],
        name: 'intro',
        component: IntroPage,
        meta: {
            requiresAuth: false,
            module: 'auth'
        }
    },
    {
        path: '/home',
        alias: ['/home'],
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: false,
            module: 'auth'
        }
    },

    
    {
        path: '/login',
        alias: ['/login'],
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
            module: 'auth'
        }
    },
    {
        path: '/login-email',
        alias: ['/login-email'],
        name: 'login-email',
        component: LoginEmail,
        meta: {
            requiresAuth: false,
            module: 'auth'
        }
    },
    {
        name: 'logout',
        path: '/logout',
        redirect: to => {
            console.log('to', to);
            Store.dispatch('auth/logout');
            return {name: 'login-email'}
        },
        meta: {
            module: 'authentication'
        }
    },
     {
        path: '/radio/:id',
        alias: ['/radio/:id'],
        name: 'radio',
        component: RadioPage,
        meta: {
            requiresAuth: false,
            module: 'radio'
        }
    },
   /* {
        path: '/radio-native/:id',
        alias: ['/radio-native/:id'],
        name: 'radio-native',
        component: RadioNativePage,
        meta: {
            requiresAuth: false,
            module: 'radio'
        }
    },*/
    {
        path: '/redirect-radio/:id',
        alias: ['/redirect-radio'],
        name: 'redirect-radio',
        component: RedirectRadio,
        meta: {
            requiresAuth: false,
            module: 'radio'
        }
    },
    {
        path: '/playlist/:id/:name/:img/:daytimeid/:dasytime_nombre?/:autoplay?',
        alias: ['/playlist'],
        name: 'playlist',
        component: PlaylistGenero,
        meta: {
            requiresAuth: false,
            module: 'playlist'
        }
    },
    {
        path: '/playlist-daytime/:id/:name/:img',
        alias: ['/playlist-daytime'],
        name: 'playlist-daytime',
        component: PlaylistDayTime,
        meta: {
            requiresAuth: true,
            module: 'playlist-daytime'
        }
    },
    {
        path: '/podcast/',
        alias: ['/podcast'],
        name: 'podcast',
        component: Podcast,
        meta: {
            requiresAuth: false,
            module: 'podcast'
        }
    },
    {
        path: '/favorite',
        alias: ['/favorite'],
        name: 'favorite',
        component: FavoritesSongs,
        meta: {
            requiresAuth: true,
            module: 'playlist'
        }
    },
     {
        path: '/listas/',
        alias: ['/listas'],
        name: 'listas',
        component: ListasUser,
        meta: {
            requiresAuth: true,
            module: 'playlist'
        }
    },

    {
        path: '/lista-songs/:id/:nombre',
        alias: ['/lista-songs'],
        name: 'lista-songs',
        component: ListaSongs,
        meta: {
            requiresAuth: true,
            module: 'playlist'
        }
    },

    
    {
        path: '/artist-by-genero/:id/:nombre',
        alias: ['/artist-by-genero'],
        name: 'artist-by-genero',
        component: ArtistByGenero,
        meta: {
            requiresAuth: true,
            module: 'playlist'
        }
    },

    {
        path: '/artist-songs/:artist_id?/:artist_type_name?/:img?/:artist_name?/:open_from_player?/:daytimeid?/:daytime_nombre?/:autoplay?/:select_song_prev?',
        alias: ['/artist-songs'],
        name: 'artist-songs',
        component: ArtistSongs,
        meta: {
            requiresAuth: false,
            module: 'artist-songs'
        }
    },

    {
        path: '/artist-prev-songs/:artist_id?/:artist_type_name?/:artist_name?',
        alias: ['/artist-prev-songs'],
        name: 'artist-prev-songs',
        component: ArtistPrevList,
        meta: {
            requiresAuth: false,
            module: 'artist-prev-songs'
        }
    },

    {
        path: '/redirct-artist-sogs?/:artist_id?/:artist_type_name?/:img?/:artist_name?/:open_from_player?/:daytimeid?/:daytime_nombre?',
        alias: ['/redirct-artist-sogs'],
        name: 'redirct-artist-sogs',
        component: RedirectArtistSongs,
        meta: {
            requiresAuth: true,
            module: 'artist-songs'
        }
    },
    {
        path: '/redirect-playlist-genero/:id/:name/:img/:open_from_player/:daytimeid/:daytime_nombre?',
        alias: ['/redirect-playlist-genero'],
        name: 'redirect-playlist-genero',
        component: RedirectPlaylistGenero,
        meta: {
            requiresAuth: true,
            module: 'playlist-genero'
        }
    },
    {
        path: '/redirect-playlist-daytime/:id/:name/:img/:open_from_player',
        alias: ['/redirect-playlist-daytime'],
        name: 'redirect-playlist-daytime',
        component: RedirectPlaylistDayTime,
        meta: {
            requiresAuth: true,
            module: 'playlist-daytime'
        }
    },
    {
        path: '/select-genero',
        alias: ['/select-genero'],
        name: 'select-genero',
        component: SelectGenero,
        meta: {
            requiresAuth: true,
            module: 'playlist'
        }
    },
    {
        path: '/select-daytime',
        alias: ['/select-daytime'],
        name: 'select-daytime',
        component: SelectedDayTime,
        meta: {
            requiresAuth: true,
            module: 'playlist'
        }
    },
    {
        path: '/register-user',
        alias: ['/register-user'],
        name: 'register-user',
        component: RegisterUser,
        meta: {
            requiresAuth: false,
            module: 'auth'
        }
    },
    {
        path: '/recovery-pass',
        alias: ['/recovery-pass'],
        name: 'recovery-pass',
        component: RecoveryPass,
        meta: {
            requiresAuth: false,
            module: 'auth'
        }
    },

    {
        path: '/user-profile',
        alias: ['/user-profile'],
        name: 'user-profile',
        component: UserProfile,
        meta: {
            requiresAuth: true,
            module: 'radio'
        }
    },

    {
        path: '/programacion',
        alias: ['/programacion'],
        name: 'programacion',
        component: Programation,
        meta: {
            requiresAuth: false,
            module: 'radio'
        }
    },
    {
        path: '/noticias',
        alias: ['/noticias'],
        name: 'noticias',
        component: News,
        meta: {
            requiresAuth: false,
            module: 'news'
        }
    },
    {
        path: '/selected-radiostation',
        alias: ['/selected-radiostation'],
        name: 'selected-radiostation',
        component: SelectRadioStation,
        meta: {
            requiresAuth: false,
            module: 'radio'
        }
    },
    {
        path: '/buscar-cancion',
        alias: ['/buscar-cancion'],
        name: 'buscar-cancion',
        component: BuscarCanciones,
        meta: {
            requiresAuth: true,
            module: 'music'
        }
    },
    {
        path: '/select-artist-type',
        alias: ['/select-artist-type'],
        name: 'select-artist-type',
        component: SelectArtistType,
        meta: {
            requiresAuth: true,
            module: 'artist'
        }
    },
    {
        path: '/all-time-artist',
        alias: ['/all-time-artist'],
        name: 'all-time-artist',
        component: AllTimeArtist,
        meta: {
            requiresAuth: true,
            module: 'artist'
        }
    },
    {
        path: '/sesiones',
        alias: ['/sesiones'],
        name: 'sesiones',
        component: Sesiones,
        meta: {
            requiresAuth: true,
            module: 'artist'
        }
    },
    {
        path: '/djs',
        alias: ['/djs'],
        name: 'djs',
        component: Djs,
        meta: {
            requiresAuth: true,
            module: 'artist'
        }
    },
    {
        path: '/select-moment/:moment/:daytimeid?/:daytime_nombre?',
        alias: ['/select-moment'],
        name: 'select-moment',
        component: SelectMomentsArtist,
        meta: {
            requiresAuth: true,
            module: 'artist'
        }
    },
    {
        path: '/redirect-select-moment/:moment?/:daytimeid?/:daytime_nombre?',
        alias: ['/redirect-select-moment'],
        name: 'redirect-select-moment',
        component: RedirectSelectMoment,
        meta: {
            requiresAuth: true,
            module: 'artist'
        }
    },
    {
        path: '/offline-music/:id?',
        alias: ['/offline-music'],
        name: 'offline-music',
        component: OfflineMusic,
        meta: {
            requiresAuth: true,
            module: 'offline'
        }
    },


]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

routes.map((route) => {
    if (!route.meta) route.meta = {};
    if (route.meta.requiresAuth !== false)
        route.meta.requiresAuth = true;
});

export default router
