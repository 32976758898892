<template id="home">
    <span>
        <backgroundcustom :fondo="1" :device="device"></backgroundcustom>
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-12">
                <img class="mt-4 z-index-0 custom-background-class" alt="no icon" src="@/assets/portada.jpg" style="height: 7rem !important;">
            </div>
        </div>
    </span>     
</template>


<script>
    import Backgroundcustom from '@/components/Backgroundcustom'
    import {mapGetters} from 'vuex';
    


    let RedirectSelectMoment = {};
    (function () {
        this.name = 'redirect-page';
        // let _self = null;

        this.created = function () {
            this.goTo('select-moment', this.$route.params.moment, this.$route.params.daytimeid, this.$route.params.daytime_nombre)
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               
            }
        };
        this.methods = {
            goTo: function (route, m, i, n) {
                this.$router.push({name: route,
                   params: {
                        moment: m,
                        daytimeid: i,
                        daytime_nombre: n,
                    }
               });
            }
        };
        this.components = {Backgroundcustom};
        this.computed = {
             ...mapGetters({
               // groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        

        this.props = ['device'];
    }).apply(RedirectSelectMoment);

    export default RedirectSelectMoment
</script>
