const state = () => ({
  downloaded: []
});

const getters = {
  downloaded(state) {
    return state.downloaded;
  },
};

const actions = {
  updateProgress({ commit, state }, { id, progreso }) {
    commit('UPDATE_DOWNLOAD', { id, progreso });

    // Resto del código...
  },
async addDownloadedSong({ commit, dispatch, state, rootState }, cancion) {
 
    //const queryCheck = 'SELECT * FROM canciones WHERE id = ?';
    //const [existingSong] = await dispatch('db/query', [queryCheck, [cancion.id]]);

    const existingSong = state.downloaded.find((d) => d.id === cancion.id);


    if (existingSong) {
      commit('REMOVE_DOWNLOAD', cancion.id);
    } 

      // La canción no existe en la base de datos, realizar inserción con el progreso
      //const queryInsert = 'INSERT INTO canciones (nombre, url, ruta_archivo, progreso) VALUES (?, ?, ?, ?)';
      //const values = [cancion.nombre, cancion.url, cancion.ruta_archivo, cancion.progreso];
      //await dispatch('db/query', [queryInsert, values]);
      //const result = await state.database.run(queryInsert, values);

      // Obtener el ID del nuevo registro
      //const newSongId = result.lastID;
      const newSongId = cancion.id;
      commit('ADD_DOWNLOAD',  cancion);
    
    // Agregar la canción a la lista de canciones descargadas en el estado
    
  },
  async removeDownloadedSong({ commit, state }, cancion) {
    //const queryDelete = 'DELETE FROM canciones WHERE id = ?';
    //await state.database.run(queryDelete, [cancion.id]);
    // Eliminar la canción de la lista de canciones descargadas en el estado
    commit('REMOVE_DOWNLOAD', cancion.id);
  },
  // Resto del código...
};

const mutations = {
  ADD_DOWNLOAD(state, cancion ) {
    cancion.progreso = 0;
    state.downloaded.push(cancion);
  },
  UPDATE_DOWNLOAD(state, { id, progreso }) {
    let downloadIndex = state.downloaded.findIndex((d) => d.id === id);
    if (downloadIndex !== -1) {
      state.downloaded[downloadIndex].progreso = progreso;
    }
  },
  REMOVE_DOWNLOAD(state, id) {
        console.log('en mutation REMOVE_DOWNLOAD', id);

    state.downloaded = state.downloaded.filter((d) => d.id !== id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
