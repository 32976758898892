<template>
  <div v-show="isActive" ref="playerContainerDos" :class="{'z-index-menus-100': '-100', 'z-index-2000': zIndex == '2000' && zIndex != '-100' , 'player-container-mini':isMinimized, 'player-container': !isMinimized}">
     <button-prime  v-show="isMinimized && isActive && player" @click="closePlayer2" class="close-button ">x</button-prime>
  </div>
</template>

<script>
import { onMounted, ref, computed, nextTick, watchEffect } from 'vue';
import Player from '@vimeo/player';
import { useStore } from 'vuex';
import {mapGetters} from 'vuex';


export default {
  props: ['videoId', 'isMinimized', 'isActive', 'videoCurrentTime', 'device'],

  computed: {
      ...mapGetters({
        zIndex: 'video_player/zIndex'
      })
  },

  data() {
    return {
      player: null // Define la propiedad player aquí
    };
  },

  mounted(){
    this.loadNewVideo(this.videoId);
  },

  watch: {
    async isActive(newVal){
      if(newVal!= null){
        if(newVal){
        await this.loadNewVideo(this.videoId);
      }else if (this.player) {
            try {
              await this.player.destroy();
              this.player = null; // Asegúrate de resetear la variable player
            } catch (error) {
              console.error("Error destroying the player:", error);
            }
          }
      }
      
    },

    videoId(newVal) {
      console.log(this.player)
      if (this.player) {
        this.player.destroy().then(() => {
          this.player = null;
          this.loadNewVideo(newVal);
        }).catch(error => {
          console.error("Error destroying the player:", error);
        });
      } else {
        this.loadNewVideo(newVal);
      }
    }
  },
  methods: {
    async loadNewVideo(newVideoId) {
      const store = useStore();
      const playerContainerDos = this.$refs.playerContainerDos; // Accede al 

      await this.closePlayer2();

      if (playerContainerDos && newVideoId) {

        var playerWidth = window.innerWidth;

        if(!this.device.isMobile)
          playerWidth = 400;


        var player33 = new Player(playerContainerDos, {
          id: newVideoId,
          width: playerWidth,
          autoplay: true,
        });

        player33.on('enterpictureinpicture', () => {
          console.log('El reproductor ha entrado en el modo PiP');
          store.dispatch('video_player/setZIndex', -100);
        });

        player33.on('leavepictureinpicture', () => {
          console.log('El reproductor ha salido del modo PiP');
            store.dispatch('video_player/setZIndex', 2000);
        });

        player33.ready().then(() => {

          this.player = player33;
 

            player33.play().then(() => {
              player33.requestPictureInPicture().catch(error => {
                console.error('Error al activar PiP:', error);
              });
            });
         
        });
      }
    },

    async closePlayer2(){

        if (this.player) {
          try {
            await this.player.destroy();
            this.player = null;
            this.$store.dispatch('video_player/setPlayerState', {
              isActive: false,
              isMinimized: false,
              videoId: null,
              videoCurrentTime: 0,
            });

            this.$store.dispatch('song/set-current', -1);
                    this.$store.dispatch('song/set-current', {  activo: null,
                                artista:null,
                                artista_id:null,
                                cancion:null,
                                cancion_id:null,
                                fecha_creacion_cancion:null,
                                fecha_ingreso:null,
                                genero:null,
                                genero_id:null,
                                nombre:null,
                                portada:null,
                                vimeo_id:null,
                                url_cancion:null,
                            });
                    this.$store.dispatch('song/set-is-playing', false);
                    this.$store.dispatch('song/set-is-radio', false);

                    
          } catch (error) {
            console.error("Error destroying the player:", error);
          }
        }
      }
    },

}
</script>

<style scoped>


.z-index-menus-100{
  z-index: -100;
}


.z-index-2000{
  z-index: 2000;
}

.player-container {
  position: absolute;
  top: 137px;
  right: 0px;
  left: 0px;
  width: auto;
  max-width: 100%;

}

.player-container-mini {
 position: fixed;
  bottom: 45px;
  right: 5px;
  width: auto; 
  max-width: 10vw; 
  display: flex; 
  justify-content: flex-end; 
}


@media (max-width: 768px) {
  /* En dispositivos móviles, el ancho será del 100% */
  .player-container {
    width: 100%;
    max-width: none;
  }

}


.close-button {
  position: absolute;
  top: 25px; 
  right: 5px; 
}


</style>



