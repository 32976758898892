import axios from "axios";

export default {
    
    getRadioByKey() {
        const url = `${process.env.VUE_APP_API_URL}/getradiokey`;
        let data = {'key': process.env.VUE_APP_KEY_RADIO}
        return axios.post(url, data)
    },

    getStationsByRadioKey() {
        const url = `${process.env.VUE_APP_API_URL}/getstationsbyradiokey`;
        let data = {'key': process.env.VUE_APP_KEY_RADIO}
        return axios.post(url, data)
    },


    getStationsByRadioKeyNEW() {
        const url = `${process.env.VUE_APP_API_URL}/getstationsbyradiokeynew`;
        let data = {'key': process.env.VUE_APP_KEY_RADIO}
        return axios.post(url, data)
    },

    marcarNotificacionLeida(id) {
        const url = `${process.env.VUE_APP_API_URL}/marcarnotileida`;
        let data = {'id': id}
        return axios.post(url, data)
    },
    
    tengoNotificacion(id) {
        const url = `${process.env.VUE_APP_API_URL}/tengonoti`;
        let data = {'id': id}
        return axios.post(url, data)
    },

    getTodasNotificacionesUsuario(id) {
        const url = `${process.env.VUE_APP_API_URL}/todasnotificacionusuario`;
        let data = {'id': id}
        return axios.post(url, data)
    },
    createUrlCorta(short){
        const url = `${process.env.VUE_APP_API_URL}/createshorturl`;
        let data = short;
        return axios.post(url, data);
    },

    createUrlCorta22(short){
        const url = `${process.env.VUE_APP_API_URL}/createshorturl22`;
        let data = short;
        return axios.post(url, data);
    },


    getShortUrlByid(id){
        const url = `${process.env.VUE_APP_API_URL}/getshorturl`;
        let data = {'id': id}
        return axios.post(url, data)
    }



}
