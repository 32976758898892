<template>
  <div class="image-container" :class="imageClass" style="max-width: {{ maxWidth }}; max-height: {{ maxHeight }};">
    <img :src="imageSource" @error="handleImageError" @load="handleImageLoad" />
  </div>
</template>

<script>
export default {
  name: "ImageWithFallback",
  props: {
    imageSource: {
      type: String,
      required: true
    },
    imageClass: {
      type: String,
      default: ""
    },
    maxWidth: {
      type: String,
      default: "100%"
    },
    maxHeight: {
      type: String,
      default: "100%"
    }
  },
  methods: {
    handleImageLoad() {
      this.$emit("loaded");
    },
    handleImageError() {
      this.$emit("error");
    }
  }
};
</script>

<style scoped>
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}

</style>
