<template id="home">
    <span>
        <backgroundcustom :fondo="0" :device="device"></backgroundcustom>
        <p class="text-white text-3xl font-bold">ESTACIONES DE RADIO</p>
        <div class="card">
            <div class="flex card-container indigo-container">
                <div class="flex-1 h-10rem text-white font-bold text-center p-2 border-round" style="box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;">
                   
                    <!--img class="z-index-0" alt="no icon" src="@/assets/infantil-bg-mobile.jpg" style="width:100%;" @click.prevent="goTo(1)"-->
                     <p class="text-1xl font-bold text-white">Infantil</p>
                </div>
                <div class="flex-1 h-10rem text-white font-bold text-center p-2 border-round" style="box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;"> 
                    
                    <!--img class="z-index-0" alt="no icon" src="@/assets/juvenil-bg-mobile.jpg" style="width: 100%;  " @click.prevent="goTo(2)"-->
                    <p class=" text-1xl font-bold text-white">Juvenil</p>
                </div>
                <div class="flex-1 h-10rem text-white font-bold text-center p-2 border-round" style="box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;">
                    
                    <!--img class="z-index-0" alt="no icon" src="@/assets/adultos-m.jpg" style="width:100% !important;" @click.prevent="goTo(3)"-->
                    <p class=" text-1xl font-bold text-white">Adulto</p>
                </div>
            </div>
        </div>
    </span>     
</template>

<script>

    import Backgroundcustom from '@/components/Backgroundcustom';
    import GenerosService from '@/service/GenerosService';
    import {mapGetters} from 'vuex';
    
    let SelectRadioStation = {};
    (function () {
        this.name = 'selectstation-page';
        // let _self = null;

        this.created = function () {
    
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               generos: [],
               st: process.env.VUE_APP_STATIC
            }
        };
        this.methods = {
            goTo: function (id) {
                this.$router.push({name: 'radio',
                   params: {
                        id: id
                    }
               });
            }
        };
        this.components = {Backgroundcustom};
        this.computed = {
             ...mapGetters({
               // groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        

        this.props = ['device'];
    }).apply(SelectRadioStation);

    export default SelectRadioStation
</script>
