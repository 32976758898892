<template>
    <div class="background-custom z-3">
        <span v-if="fondo_ === 1">
            <span class="mt-4 z-index-0 dergade-back custom-background-class"></span>
        </span>
    </div>
</template>


<style scoped>

    .custom-background-class{
        z-index:-1;position: fixed; top: -20px; left: 0; right: 0; height: 100%; bottom: 0; overflow: hidden; background-repeat: cover;
        background-position: 50% 50%;

    }

    .background-custom {
  background-color: #dd0338;
}


    .dergade-back{
        background: rgb(249,1,1); background: -moz-linear-gradient(178deg, rgba(249,1,1,1) 15%, rgba(48,2,16,1) 64%); background: -webkit-linear-gradient(178deg, rgba(249,1,1,1) 9%, rgba(48,2,16,1) 64%); background: linear-gradient(178deg, rgba(249,1,1,1) 9%, rgb(48,2,16,1) 64%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f90101',endColorstr='#800429',GradientType=1);
    }


 .green-background-gradient{
background: rgb(122,212,147);
background: linear-gradient(180deg, rgba(122,212,147,1) 57%, rgba(135,235,255,1) 85%, rgba(143,237,255,1) 89%, rgba(205,247,255,1) 95%, rgba(255,255,255,1) 100%);

}


 .black-background-gradient{
background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(0,0,0,1) 1%, rgba(43,41,149,1) 50%, rgba(177,79,162,1) 98%);
}



.animated-gradient {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 7s ease infinite;
    height: 100vh;
}

.animated-gradient-2 {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 1s ease infinite;
    height: 100vh;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


</style>


<script>
   // import {mapGetters} from 'vuex';

    let Backgroundcustom = {};
    (function () {
        this.data = function () {
            return {
                fondo_: this.fondo
            }
        };

        this.methods = {
         
        },

        this.computed = {
             
        };

        this.props = ['device', 'fondo'];
        
    }).apply(Backgroundcustom);
    export default Backgroundcustom;
</script>
